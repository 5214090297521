import React, { Component } from 'react';
import Icon from 'src/common/Icon';
import classNames from 'classnames';

import { availableTravelkinds } from 'src/sections/TravelSearchForm';
import { inject, observer } from 'mobx-react';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { TravelkindDataStore } from 'src/stores/TravelkindDataStore';
import { FormDataStore } from 'src/stores/FormDataStore';
import track from 'src/helpers/tracking';

interface IInjectedProps {
    interactiveFormStore: InteractiveFormStore;
    travelkindDataStore: TravelkindDataStore;
    formDataStore: FormDataStore;
}

const shouldRenderPackageTab = (availalableTravelkinds: availableTravelkinds = []) => {
    return availalableTravelkinds.includes('Package') && availalableTravelkinds.length !== 1;
};
const shouldRenderHotelTab = (availalableTravelkinds: availableTravelkinds = []) => {
    return availalableTravelkinds.includes('Hotel') && availalableTravelkinds.length !== 1;
};

@inject('interactiveFormStore', 'travelkindDataStore', 'formDataStore')
@observer
class TravelkindTabs extends Component {
    public static defaultProps = {
        availalableTravelkinds: ['Package', 'Hotel'],
        selectedTravelkind: 'package',
        tabPanes: { hotel: 'Nur Hotel', package: 'Pauschalreisen' }
    };

    get injected() {
        return this.props as IInjectedProps;
    }

    public select = (travelkind: ITravelkind) => {
        this.injected.interactiveFormStore.setTravelkind(travelkind);
        this.injected.formDataStore.setTravelkind(travelkind);
    };

    public selectHotel = () => this.injected.interactiveFormStore.travelkind !== 'hotel' && this.select('hotel');
    public selectPackage = () => this.injected.interactiveFormStore.travelkind !== 'package' && this.select('package');

    public handleClick(event: any){
        const tab = event.target.closest('.tab');
        if (!tab || String(tab.className).includes('active')) return;
        track({
            eventCategory: `search_${this.injected.interactiveFormStore.travelkind}_topic`,
            eventAction: 'travelkindSelected',
            eventLabel: `travelkind; ${tab.dataset.value || ''}`
        });
    }

    public render() {
        const selectedTravelkind = this.injected.interactiveFormStore.travelkind;
        const availalableTravelkinds = this.injected.travelkindDataStore.availableTravelkinds;
        const tabPanes = this.injected.travelkindDataStore.tabPanes;
        const packageSelected = selectedTravelkind === 'package';
        const hotelSelected = selectedTravelkind === 'hotel';

        const packageTabClassname = classNames('tab', {
            active: packageSelected
        });
        const hotelTabClassname = classNames('tab', {
            active: hotelSelected
        });

        const shouldRenderPackage = shouldRenderPackageTab(availalableTravelkinds);
        const shouldRenderHotel = shouldRenderHotelTab(availalableTravelkinds);

        if (!shouldRenderPackage && !shouldRenderHotel) {
            return null;
        }

        return (
            <div className="tabs" role="tablist" aria-orientation="horizontal" onClick={this.handleClick}>
                {shouldRenderPackage && (
                    <div
                        role="tab"
                        aria-selected={packageSelected}
                        className={packageTabClassname}
                        onClick={this.selectPackage}
                        data-value="package"
                    >
                        <span>
                            <Icon icon="vacation-package" />
                            {tabPanes.package}
                        </span>
                    </div>
                )}

                {shouldRenderHotel && (
                    <div
                        role="tab"
                        aria-selected={hotelSelected}
                        className={hotelTabClassname}
                        onClick={this.selectHotel}
                        data-value="hotel"
                    >
                        <span>
                            <Icon icon="bed" />
                            {tabPanes.hotel}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

export default TravelkindTabs;
