import * as React from 'react';
import { find, is, propEq } from 'ramda';
import classNames from 'classnames';

import { ISelectOptionType } from 'src/common/Select';

import './multiSelect.scss';
import MultiSelectChoice from 'src/common/MultiSelect/MultiSelectChoice';

interface IProps {
    value: string[];
    options: ISelectOptionType[];
    placeholder?: string;
    onChange: (transfer: string) => void;
    className?: string;
}

interface IState {
    isDropDownOpen: boolean;
}

export default class MultiSelect extends React.Component<IProps, IState> {
    public state = {
        isDropDownOpen: false
    };

    private readonly wrapperRef: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public handleClickOutside = (event: Event) => {
        if (this.state.isDropDownOpen && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.closeDropDown();
        }
    };

    public render() {
        const { placeholder, value, options, className } = this.props;
        const dropDownClasses = classNames('multi-select-dropdown');
        const wrapperClassName = classNames('select-wrap multi-select', {
            [className || '']: className,
            'multi-select-dropdown-visible': this.state.isDropDownOpen
        });

        return (
            <div className={wrapperClassName} onClick={this.toggleDropDown} ref={this.wrapperRef}>
                <input
                    className="multi-select-input"
                    value={this.getSelectedValues()}
                    placeholder={placeholder}
                    readOnly={true}
                />
                <div className={dropDownClasses}>
                    <div className="dropdown-wrapper">
                        {options.map(opt => {
                            return (
                                <MultiSelectChoice
                                    key={opt.value}
                                    onChange={this.props.onChange}
                                    option={opt}
                                    checked={value.includes(opt.value)}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    private toggleDropDown = () => {
        this.setState({ isDropDownOpen: !this.state.isDropDownOpen });
    };

    private closeDropDown = () => {
        if (this.state.isDropDownOpen) this.setState({ isDropDownOpen: false });
    };

    private getSelectedValues = () => {
        const { value, options } = this.props;
        const values = is(Array, value) ? value : [value];

        return (values as string[])
            .map((transferValue: string, key: number) => {
                if (key === 0) {
                    const fn: any = find(propEq('value', transferValue));
                    return fn ? fn(options).text : undefined;
                }

                return ` ( + ${key})`;
            })
            .join(' ');
    };
}
