import gql from 'graphql-tag';

export default gql`
    type DateArgs {
        departureDate: String
        returnDate: String
    }
    type ApplyTravelDateArgs {
        dates: DateArgs
    }

    type PaxDataValues {
        adults: Int
        children: [Int]
        numberOfRooms: Int
    }

    type PaxData {
        paxData: PaxDataValues
    }
`;
