import React from 'react';

import ChildAge from './ChildAge';

interface IProps {
    children: number[];
    changePaxData: (childIndex: number, offset: Offset) => void;
}

export type Offset = 1 | -1;

export default class Children extends React.Component<IProps> {
    public propagateChildAgeChange = (childIndex: number, offset: Offset) => {
        this.props.changePaxData(childIndex, offset);
    };

    public render() {
        return (
            <div className="child-age-selectors">
                {Boolean(this.props.children.length) && (
                    <p className="child-age-hint">Bitte geben Sie das Alter Ihrer Kinder bei Rückreise an.</p>
                )}
                {this.props.children.map((age: number, key: number) => (
                    <ChildAge
                        keyValue={key}
                        key={key + '/' + age}
                        no={key + 1}
                        age={age}
                        onChange={this.propagateChildAgeChange}
                    />
                ))}
            </div>
        );
    }
}
