import dateformat from 'dateformat';
// @ts-ignore
import * as dateMath from 'date-arithmetic';

import { getDate } from 'src/helpers/dates';
import Device from 'src/helpers/device';
import { ICalendarState } from './index';
import { format } from 'src/sections/TravelSearchForm/formFields/DatePicker/reactDayPickerLocaleUtils';
import {
    earlybirdDepartureDate,
    earlybirdReturnDate,
    lastMinuteDepartureDate,
    lastMinuteReturnDate,
    earlybirdSlug,
    lastMinutSlug
} from '../../action/storeInitialisation/overrideFormDataWithContentfulSettings';

// @ts-ignore
dateformat.i18n = format;

export function getModifiers(topic: IContentfulTopic, travelkind: ITravelkind, state: ICalendarState) {
    const {
        packageRangeDaysUntilDeparture,
        packageRangeDaysUntilReturn,
        hotelRangeDaysUntilDeparture,
        hotelRangeDaysUntilReturn
    } = topic.topicConfiguration;

    const { from, to } = state;

    const isTravelkindPackage = travelkind === 'package';

    const hasHighlightedRange =
        ((packageRangeDaysUntilDeparture || hotelRangeDaysUntilDeparture) &&
            (packageRangeDaysUntilReturn || hotelRangeDaysUntilReturn)) ||
        topic.slug === earlybirdSlug ||
        topic.slug === lastMinutSlug;

    const getHighlightedRange = () => {
        if (topic.slug === earlybirdSlug) {
            return {
                from: earlybirdDepartureDate,
                to: earlybirdReturnDate
            };
        }

        if (topic.slug === lastMinutSlug) {
            return {
                from: lastMinuteDepartureDate,
                to: lastMinuteReturnDate
            };
        }

        const contentfulAvailableRangeDepartureDate = isTravelkindPackage
            ? dateMath.add(getDate(), packageRangeDaysUntilDeparture, 'day')
            : dateMath.add(getDate(), hotelRangeDaysUntilDeparture, 'day');

        const contentfulAvailableRangeReturnDate = isTravelkindPackage
            ? dateMath.add(getDate(), packageRangeDaysUntilReturn, 'day')
            : dateMath.add(getDate(), hotelRangeDaysUntilReturn, 'day');
        return {
            from: contentfulAvailableRangeDepartureDate,
            to: contentfulAvailableRangeReturnDate
        };
    };

    return {
        start: from,
        end: to,
        highlighted: hasHighlightedRange ? getHighlightedRange() : undefined
    };
}

export const isSelectingFirstDay = (from?: Date, to?: Date) => !from || (from && to);

export function isMobileOrTablet() {
    const widthClass = Device.getWidthClass();
    return widthClass === 'smartphone' || widthClass === 'tablet';
}

export function getFormat() {
    return Device.isTablet() ? 'dd.mm.yy' : 'ddd dd.mm.yy';
}

export function getInputValue(departureDate: Date, returnDate: Date) {
    const dateFormat = getFormat();

    return `${dateformat(departureDate, dateFormat)} - ${dateformat(returnDate, dateFormat)}`;
}

export function getCaption(topic: IContentfulTopic) {
    const { topicConfiguration } = topic;

    const {
        packageRangeDaysUntilDeparture,
        packageRangeDaysUntilReturn,
        hotelRangeDaysUntilDeparture,
        hotelRangeDaysUntilReturn
    } = topicConfiguration;

    const doesContainDayLimit: boolean = !!(
        packageRangeDaysUntilDeparture ||
        packageRangeDaysUntilReturn ||
        hotelRangeDaysUntilDeparture ||
        hotelRangeDaysUntilReturn
    );

    return topicConfiguration && doesContainDayLimit ? topicConfiguration.topDateCaption : '';
}

const packageDaysUntilDeparture: number = 2;
const packageDaysUntilReturn: number = 360;
const hotelDaysUntilDeparture: number = 0;
const hotelDaysUntilReturn: number = 360;

export function getDisabledDays(travelkind: ITravelkind) {
    const isTravelkindPackage = travelkind === 'package';

    const contentfulAvailableDepartureDate = isTravelkindPackage
        ? dateMath.add(getDate(), packageDaysUntilDeparture, 'day')
        : dateMath.add(getDate(), hotelDaysUntilDeparture, 'day');
    const contentfulAvailableReturnDate = isTravelkindPackage
        ? dateMath.add(getDate(), packageDaysUntilReturn, 'day')
        : dateMath.add(getDate(), hotelDaysUntilReturn, 'day');

    return { before: contentfulAvailableDepartureDate, after: contentfulAvailableReturnDate };
}
