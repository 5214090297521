import * as React from 'react';

import { trackPageView } from 'src/helpers/tracking';
import isServerside from 'src/helpers/isServerside';
import { RouteComponentProps, withRouter } from 'react-router';

import { getCurrentSlug } from 'src/helpers/getCurrentSlug';
import getTrackingEnabled from 'src/helpers/getTrackingEnabled';

const getTrackingData = (props: RouteComponentProps) => {
    const page = getCurrentSlug(props);
    const isIndexPage = page === '/';

    return {
        geTopicLevel: isIndexPage ? '0' : '1',
        gePageName: isIndexPage ? 'urlaub/index' : `urlaub${page}`
    };
};

class GTM extends React.Component<RouteComponentProps> {
    public render() {
        if (!getTrackingEnabled(this.props)) {
            return null;
        }

        const scriptSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${CFG.GTM_ID}');`;

        return <script dangerouslySetInnerHTML={{ __html: scriptSnippet }} />;
    }

    public componentDidMount(): void {
        if (getTrackingEnabled(this.props)) {
            trackPageView(getTrackingData(this.props));
        }
    }

    public componentWillReceiveProps(nextProps: Readonly<RouteComponentProps>): void {
        if (
            !isServerside() &&
            getCurrentSlug(this.props) !== getCurrentSlug(nextProps) &&
            getTrackingEnabled(this.props)
        ) {
            trackPageView(getTrackingData(nextProps));
        }
    }
}

export default withRouter(GTM);
