import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import Icon from 'src/common/Icon';
import Button from 'src/common/Button';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { FormDataStore } from 'src/stores/FormDataStore';
import { setNormalizedOfferFiltersToStorage } from 'src/helpers/localStorage';
import buildFacetsLocalStorage from 'src/helpers/buildFacetsLocalStorage';
import { ContentfulStore } from 'src/stores/ContentfulStore';
import { createTravelKindPath, ISuggestionType, pathFromSelection } from 'src/helpers/autoSuggestUris';
import convertFormToQuery from 'src/helpers/convertFormToQuery';

interface IInjectedProps extends RouteComponentProps {
    interactiveFormStore: InteractiveFormStore;
    formDataStore: FormDataStore;
    contentfulStore: ContentfulStore;
}

const SubmitButton = (props: RouteComponentProps) => {
    const handleSubmitClick = () => {
        const {
            interactiveFormStore,
            contentfulStore,
            formDataStore,
            location: { pathname }
        } = props as IInjectedProps;

        const interactiveFormData = interactiveFormStore.formData;
        const travelkind = formDataStore.travelkind;
        const currentSuggestion = interactiveFormData[travelkind].suggestion as ISuggestionType;

        setNormalizedOfferFiltersToStorage(localStorage, interactiveFormData);
        buildFacetsLocalStorage(contentfulStore.topic.topicConfiguration.hotelFilter);

        formDataStore.setFormData(interactiveFormData);

        if (!currentSuggestion) {
            const queryParams = convertFormToQuery(interactiveFormStore.travelkindForm, travelkind);
            const targetRoute = createTravelKindPath(travelkind, pathname);
            const targetUrl = targetRoute + '?' + queryParams;
            return (window.location.href = targetUrl);
        }

        window.location.href = CFG.WWW + pathFromSelection(travelkind, currentSuggestion, pathname);
    };

    return (
        <Button type="secondary" onClick={handleSubmitClick} className="form-submit">
            <Icon icon="search" />
            Angebote suchen
        </Button>
    );
};

export default inject('interactiveFormStore', 'formDataStore', 'contentfulStore')(observer(withRouter(SubmitButton)));
