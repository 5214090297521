import isServerside from 'src/helpers/isServerside';

export default () => {
    if (isServerside()) {
        return false;
    }

    const url = window.location.href;

    if (CFG.STAGE === 'production' || url.indexOf('ENABLE_ADS=') !== -1) {
        return url.indexOf('ENABLE_ADS=0') === -1;
    }

    return false;
};
