import React from 'react';
import { Link } from 'react-router-dom';

interface IBreadcrumbProps {
    route: IRoute;
}

export interface IRoute {
    breadcrumbName: string;
    path: string;
    isLast: boolean;
}

export default ({ route: { breadcrumbName, path, isLast } }: IBreadcrumbProps) => {
    return isLast ? (
        <span>{breadcrumbName}</span>
    ) : (
        <>
            <Link to={path}>{breadcrumbName}</Link>
            <span className="separator">/</span>
        </>
    );
};
