import * as React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { AllTopicStore } from 'src/stores/AllTopicStore';

export interface ILinkCheckProps {
    children: string | React.ReactElement<any>;
    route: string;
    className?: string;
    onClick?: () => void;
    rel?: string;
    target?: string;
    title?: string;
    allTopicStore: AllTopicStore;
}

export const isAbsoluteLink = (url: string): boolean => url.startsWith('http') || url.startsWith('www');
export const removeUrlaub = (url: string): string => url.replace(/\/urlaub|\/ferien/, '');

export const isSPALink = (slug: string, regexString: string): boolean => {
    const slugSPARegex = new RegExp(regexString, 'g');

    return slugSPARegex.test(slug);
};

export const getSlugFromURL = (url: string): string => {
    const regexLastSlug: RegExp = /\/[\w-]+\/?$/g;
    const searchSlugResult: string[] | null = regexLastSlug.exec(url);
    const slugResult: string = searchSlugResult && searchSlugResult[0] ? searchSlugResult[0] : '/';

    return isAbsoluteLink(url) ? slugResult : url;
};

export const generatePartialRoute = (rawRoute: string, publicPath: string, regexString: string): string => {
    const lastSlug: string = getSlugFromURL(rawRoute);

    if (/\Wurlaub\/?$/.test(lastSlug) || rawRoute === '/') {
        return publicPath + '/';
    }
    const replacedUrlaub: string = removeUrlaub(lastSlug);
    const domain = `https://www.holidaycheck.${CFG.TLD}`;

    const refinedRoute = isAbsoluteLink(rawRoute)
        ? rawRoute
        : rawRoute.startsWith('/')
        ? domain + rawRoute
        : domain + '/' + rawRoute;

    return isSPALink(lastSlug, regexString)
        ? publicPath + (replacedUrlaub.startsWith('/') ? replacedUrlaub : '/' + replacedUrlaub)
        : refinedRoute;
};

export const generateNavigationElement = (refinedRoute: string, props: ILinkCheckProps): React.ReactElement<any> => {
    const { className, target, rel, title, onClick, children } = props;

    return isAbsoluteLink(refinedRoute) ? (
        <a href={refinedRoute} className={className} target={target} rel={rel} title={title} onClick={onClick}>
            {children}
        </a>
    ) : (
        <Link to={refinedRoute} className={className} target={target} rel={rel} title={title} onClick={onClick}>
            {children}
        </Link>
    );
};

export const formatSlugsList = (slugs: string[]): string => {
    return slugs
        .map((slug, index, slugsArray) => {
            const isLast = slugsArray.length - 1 === index;
            const formattedSlug = '(^|\\/)' + slug.replace(/-/g, '\\-').replace('/', '');

            return isLast ? formattedSlug : formattedSlug + '|';
        })
        .reduce((acc, slug) => {
            return acc + slug;
        });
};

@inject('allTopicStore')
@observer
class LinkCheck extends React.Component<ILinkCheckProps, {}> {
    public render() {
        const slugs = this.props.allTopicStore.getSlugs;
        const regexString = formatSlugsList(slugs);

        const refinedRoute = generatePartialRoute(this.props.route, CFG.PUBLIC_PATH, regexString);

        return generateNavigationElement(refinedRoute, this.props);
    }
}

export default LinkCheck;
