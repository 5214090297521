import React from 'react';
import className from 'classnames';

import Radio from 'src/common/Radio';

interface IProps {
    children: number[];
    moreThanSixAdults: boolean;
    changePaxData: (value: string) => void;
}

export default ({ children, moreThanSixAdults, changePaxData }: IProps) => {
    return (
        <div className={className('children-selector', { disabled: moreThanSixAdults })}>
            <label className="top-label">Kinder</label>
            <div className="selectors">
                <Radio
                    disabled={moreThanSixAdults}
                    value="0"
                    name="children"
                    checked={children.length === 0}
                    label="0"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    disabled={moreThanSixAdults}
                    value="1"
                    name="children"
                    checked={children.length === 1}
                    label="1"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    disabled={moreThanSixAdults}
                    value="2"
                    name="children"
                    checked={children.length === 2}
                    label="2"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    disabled={moreThanSixAdults}
                    value="3"
                    name="children"
                    checked={children.length === 3}
                    label="3"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    disabled={moreThanSixAdults}
                    value="4"
                    name="children"
                    checked={children.length === 4}
                    label="4"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
            </div>
        </div>
    );
};
