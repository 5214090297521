import { evolve, flatten, map, pathOr, pipe, pluck, tail, toPairs } from 'ramda';

import { ensureNumbersArray, ensureNumber, ensureStringArray } from 'src/helpers/ensureType';
import airportList from 'src/sections/TravelSearchForm/formFields/AirportDialog/airports.json';
import { normalizeDate, isDate, getDate } from 'src/helpers/dates';

const validAirports = pipe(
    toPairs,
    map(tail),
    flatten,
    pluck('iata')
)(airportList);

const validateAdults = (adults: string | number, defaultFormData: IFormData, travelkind: ITravelkind) => {
    const typedAdults = ensureNumber(adults);

    return 0 < typedAdults && typedAdults < 6 ? typedAdults : defaultFormData[travelkind].adults;
};

const validateHotelCategory = (h: string) => {
    const category = ensureNumber(h);
    if (category <= 0 || category > 5) {
        return '';
    }

    return h;
};

const validateChildren = (children: string[] | number[] = []): number[] => {
    const typedChildren = ensureNumbersArray(children);

    const validatedChildren = typedChildren.filter(c => c > 0 && c < 18);

    if (validatedChildren.length <= 4) {
        return validatedChildren;
    }

    return [];
};

const validateDate = (d: string | Date, fallback: string | Date): Date => {
    if (!isDate(d) || getDate() > normalizeDate(d)) {
        return normalizeDate(fallback);
    }

    return normalizeDate(d);
};

const validateNumberOfRooms = (n: string | number) => {
    const typedNumberOfRooms = ensureNumber(n);

    if (typedNumberOfRooms > 6 || typedNumberOfRooms < 1) {
        return 1;
    }

    return typedNumberOfRooms;
};

const validateTransfer = (transfer: string | string[]) => {
    const typedtransfer = ensureStringArray(transfer);

    return typedtransfer.filter(t => t === 'GT14-TR' || t === 'aee96b00-f396-4637-a7b5-e8a5b73044de');
};

const validateMaxP = (p: string | undefined) => {
    if (p) {
        const typedMxP = ensureNumber(p);
        if (typedMxP === 300 || typedMxP === 500 || typedMxP === 750 || typedMxP === 1000) {
            return p;
        }
    }

    return '';
};

export const ensureDatesOrder = (form: IPackage | IHotel) => {
    if (form.departureDate > form.returnDate) {
        return {
            ...form,
            departureDate: form.returnDate,
            returnDate: form.departureDate
        };
    }

    return form;
};

const validatePackage = (packageForm: IPackage, defaultFormData: IFormData): IPackage => {
    const transformation = {
        departureDate: (d: string) => validateDate(d, defaultFormData.package.departureDate),
        returnDate: (d: string) => validateDate(d, defaultFormData.package.returnDate),
        hotelCategory: validateHotelCategory,
        adults: (a: string | number) => validateAdults(a, defaultFormData, 'package'),
        children: validateChildren,
        numberOfRooms: validateNumberOfRooms,
        airport: (airport: string[]) => airport.filter(a => validAirports.includes(a)),
        transfer: validateTransfer,
        maxP: validateMaxP
    };

    return evolve(transformation, packageForm);
};

const validateHotel = (hotelForm: IHotel, defaultFormData: IFormData): IHotel => {
    const transformation = {
        departureDate: (d: string): Date => validateDate(d, defaultFormData.hotel.departureDate),
        returnDate: (d: string): Date => validateDate(d, defaultFormData.hotel.returnDate),
        adults: (a: string | number): number => validateAdults(a, defaultFormData, 'hotel'),
        children: validateChildren,
        numberOfRooms: validateNumberOfRooms,
        hotelCategory: validateHotelCategory
    };

    return evolve(transformation, hotelForm);
};

const validateTravelkind = (travelkind: string, topic: ITopicConfiguration): ITravelkind => {
    if (travelkind !== 'package' && travelkind !== 'hotel') {
        return pathOr('package', ['topicConfiguration', 'travelkind'], topic);
    }

    return travelkind;
};

export default (formData: IFormData, defaultFormData: IFormData, topic: ITopicConfiguration): IFormData => {
    const transformation = {
        package: (p: IPackage) => ensureDatesOrder(validatePackage(p, defaultFormData)),
        hotel: (h: IHotel) => ensureDatesOrder(validateHotel(h, defaultFormData)),
        travelkind: (tr: string) => validateTravelkind(tr, topic)
    };

    return evolve(transformation, formData);
};
