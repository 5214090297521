import * as React from 'react';
import classNames from 'classnames';

import { extractDescription, ISuggestionType } from 'src/helpers/autoSuggestUris';

import { ISuggestItem } from '../utils/types';

interface IProps {
    isHighlighted?: boolean;
    item: ISuggestItem;
    onClick: (item: ISuggestionType) => void;
}

class SuggestItem extends React.Component<IProps> {
    public render() {
        const { isHighlighted, item } = this.props;
        const className = classNames('suggestion-search-element', { highlighted: isHighlighted });

        return (
            <div className={className} onClick={this.handleSelect}>
                <div
                    className="title"
                    // @ts-ignore: next-line
                    dangerouslySetInnerHTML={{ __html: item.highlightedName || item.highlighted || item.name }}
                />
                <div
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: extractDescription(item)
                    }}
                />
            </div>
        );
    }

    private handleSelect = () => {
        const { item, onClick } = this.props;
        onClick(item as ISuggestionType);
    };
}

export default SuggestItem;
