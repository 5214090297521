import React from 'react';

import SuggestionSearch from 'src/common/SuggestionSearch';

import AirportDialog from '../formFields/AirportDialog';
import HotelCategory from '../formFields/HotelCategory';
import DatePicker from '../formFields/DatePicker';
import BoardType from '../formFields/BoardType';
import Duration from '../formFields/Duration';
import Transfer from '../formFields/Transfer';
import PriceSlider from '../formFields/PriceSlider';
import SubmitButton from '../formFields/SubmitButton';
import PaxDialog from '../formFields/PaxDialog';

const Package = () => (
    <div className="package-form travel-search-form">
        <div className="main-form-fields">
            <SuggestionSearch />
            <AirportDialog />
            <DatePicker />
            <Duration />
            <PaxDialog />
            <BoardType />
            <Transfer />
            <HotelCategory />
        </div>
        <div className="form-footer">
            <PriceSlider />
            <SubmitButton />
        </div>
    </div>
);
export default Package;
