import { ContentfulStore } from 'src/stores/ContentfulStore';
import { GenericTextStore } from 'src/stores/GenericTextStore';
import { AllTopicStore } from 'src/stores/AllTopicStore';
import { DefaultDatesStore } from 'src/stores/DefaultDatesStore';
import { TravelkindDataStore } from 'src/stores/TravelkindDataStore';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { FormDataStore } from 'src/stores/FormDataStore';

export interface IStores {
    [storeName: string]: any;
    contentfulStore: ContentfulStore;
    genericTextStore: GenericTextStore;
    defaultDatesStore: DefaultDatesStore;
    travelkindDataStore: TravelkindDataStore;
    interactiveFormStore: InteractiveFormStore;
    formDataStore: FormDataStore;
    allTopicStore: AllTopicStore;
}

export const createStores = (): IStores => {
    return {
        contentfulStore: new ContentfulStore(),
        genericTextStore: new GenericTextStore(),
        defaultDatesStore: new DefaultDatesStore(),
        travelkindDataStore: new TravelkindDataStore(),
        interactiveFormStore: new InteractiveFormStore(),
        formDataStore: new FormDataStore(),
        allTopicStore: new AllTopicStore()
    };
};

const init = (storeData: any, store: any) => {
    if (typeof storeData === 'object') {
        Object.keys(storeData).forEach(key => ((store as any)[key] = storeData[key]));
    }
};

export const rehydrateStores = (data: IStores) => {
    const stores: IStores = createStores();

    Object.keys(data).forEach(storeKey => {
        const store = stores[storeKey];
        const storeData = data[storeKey];

        init(storeData, store);
    });

    return stores;
};
