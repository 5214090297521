import { action, computed, observable } from 'mobx';
import { availableTravelkinds } from 'src/sections/TravelSearchForm';

interface ITravelkindTabPanels {
    package: string;
    hotel: string;
}

interface ITravelkindData {
    tabPanes: ITravelkindTabPanels;
    availableTravelkinds: availableTravelkinds;
}

const inititialState = {
    tabPanes: {
        package: CFG.TLD === 'ch' ? 'Badeferien' : 'Pauschalreisen',
        hotel: 'Nur Hotel'
    },
    availableTravelkinds: ['Package', 'Hotel']
};

export class TravelkindDataStore {
    // @ts-ignore
    @observable public travelkindData: ITravelkindData = inititialState;

    @action public setData(travelkindData: ITravelkindData) {
        this.travelkindData = travelkindData;
    }

    @action public setAvailableTravelkind(travelkinds: availableTravelkinds) {
        this.travelkindData.availableTravelkinds = travelkinds;
    }

    @computed get availableTravelkinds(): availableTravelkinds {
        return this.travelkindData.availableTravelkinds;
    }

    @computed get tabPanes() {
        return this.travelkindData.tabPanes;
    }
}
