import { pipe } from 'ramda';

interface IPayload {
    location: {
        search: string;
        pathname: string;
    };
}

export const getSlugGoogleCache = (pathname: string): string => {
    const regex: RegExp = /https:\/\/(www\.)?holidaycheck\.(de|at|ch)\/(urlaub|ferien)(\/[\w-]+)\+&/;
    const results: RegExpExecArray | null = regex.exec(pathname);

    if (!results) return '/';

    return results.slice(-1)[0];
};

const removePublicPath = (pathname: string) =>
    pathname.replace(/^(\/holidaycheck\/topicpage-(de|ch|at)|\/urlaub|\/ferien)/, '');

const removeTrailingSlash = (pathname: string) =>
    pathname.endsWith('/') ? pathname.slice(0, pathname.length - 1) : pathname;

export const extractSlugFromPath = (pathname: string) => {
    return pipe(removePublicPath, removeTrailingSlash, path => path || '/')(pathname);
};

export const getCurrentSlug = ({ location: { search, pathname } }: IPayload): string => {
    const isRenderedOnGoogleCache: boolean = search.includes('https://www.holidaycheck.');
    if (pathname.indexOf('veranstalteragb') > -1) return '/info-veranstalteragb';
    return isRenderedOnGoogleCache ? getSlugGoogleCache(search) : extractSlugFromPath(pathname);
};
