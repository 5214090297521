import { flatten, has, toPairs } from 'ramda';

import { getHotelFiltersFromStorage, setOfferFiltersToStorage, setHotelFiltersToStorage } from './localStorage';

import hotelFilterMapping from './configs/hotelFiltersMapping.json';

const buildFacetsLocalStorage = (facets: string[]): void => {
    const { stars } = getHotelFiltersFromStorage(localStorage);

    if (!facets) {
        setHotelFiltersToStorage(localStorage, { availability: [true], stars });
        return;
    }

    if (facets.includes('ALL_INCLUSIVE')) {
        setOfferFiltersToStorage(localStorage, { package: { boardType: 'GT06-AI' } });
    }

    const formattedFacets = facets.filter(facet => facet !== 'ALL_INCLUSIVE');
    const mappedFacets = formattedFacets.map(facet => {
        const facetKey = Object.keys(hotelFilterMapping).filter(categoryKey => {
            return searchValueInObject(hotelFilterMapping[categoryKey], facet);
        })[0];

        return { [facetKey]: facet };
    });

    const result = generateLocalStorageObject(mappedFacets);

    setHotelFiltersToStorage(localStorage, { availability: [true], ...result, stars });
};

export const generateLocalStorageObject = (mappedFacets: object[]) => {
    const result: { [key: string]: any } = {};

    mappedFacets.forEach(facet => {
        const map = flatten(toPairs(facet));
        const key = String(map[0] || '');
        const value = String(map[1] || '');
        // @ts-ignore
        result[key] = !has(key, result) ? [value] : [...result[key], value];
    });

    return result;
};

const searchValueInObject = (targetObject: { [key: string]: any }, value: string | number): boolean => {
    let result = false;

    Object.keys(targetObject).forEach(property => {
        if (targetObject[property] === value) {
            result = true;
        }
    });

    return result;
};

export default buildFacetsLocalStorage;
