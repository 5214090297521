import * as React from 'react';

const footerURL = '/fragment/hc-header/footer?version=full&tenant=ce2';
const failSafe = `<script>
    if (document.querySelector('#unified-footer').children.length === 1) {
        var http = new XMLHttpRequest();
            http.onreadystatechange = function() {
                if (this.readyState !== 4 || this.status !== 200) return;
                document.querySelector('#unified-footer').innerHTML = this.responseText;
                };
            http.open('GET', '${CFG.WWW + footerURL}');
            http.send();
        }
        </script>`;

const FooterWrapper = () => (
    <footer
        id="unified-footer"
        dangerouslySetInnerHTML={{ __html: `<!--#include virtual="${footerURL}" -->${failSafe}` }}
    />
);

export default FooterWrapper;
