import React, { Component, ReactNode } from 'react';

import Header from 'src/sections/Header';
import Footer from 'src/sections/Footer';
import User from 'src/helpers/user';
import shouldDisplayAds from 'src/helpers/shouldDisplayAds';

interface IProps {
    children: ReactNode;
    slug: string;
}

export default class Layout extends Component<IProps> {
    public render() {
        return (
            <React.Fragment>
                <aside id="ads">
                    <div className="adslot adslot-banner adslot-topmobile"><div /></div>
                    <div className="adslot adslot-sky"><div /></div>
                </aside>
                <Header />
                <div id="main">{this.props.children}</div>
                <div className="adslot adslot-pubperform adslot-topmobile5"><div /></div>
                <Footer />
            </React.Fragment>
        );
    }

    public componentDidMount(): void {
        if (!shouldDisplayAds()) return;

        // @ts-ignore
        (window.hcAds = window.hcAds || []).push({
            config: {
                zone: 'topic_pages',
                keyword: [
                    'urlaub',
                    'hotels',
                    'hotel',
                    'urlaubsangebote',
                    'reise',
                    'reisen',
                    'reiseangebote',
                    'online buchen',
                    'urlaub buchen',
                    this.props.slug.replace(/\//g, ' ').trim(),
                    'topicpage'
                ],
                personalizedAds: User.hasAllowedTracking() && User.hasReducedPrivacy(),
            }
        });
    }
}
