import { isDefined } from 'src/helpers/predicates';

const validateAdultsCount = (adultsCount: number, roomCount: number): boolean => {
    return isDefined(adultsCount) && adultsCount !== 0 && adultsCount % roomCount === 0;
};

const validateChildrenSelection = (childrenAges: number[], roomCount: number): boolean => {
    if (childrenAges.filter(age => age < 2).length % roomCount !== 0) {
        return false;
    }
    return childrenAges.filter(age => age >= 2).length % roomCount === 0;
};

const validateTravelerCount = (travelerCount: number, roomCount: number): boolean => {
    if (travelerCount > 8) {
        return false;
    }
    return !(roomCount > 1 && travelerCount > 6);
};

const validateTravellerSelection = (adultsCount: number, childrenAges: number[], roomCount: number): boolean => {
    return (
        validateAdultsCount(adultsCount, roomCount) &&
        validateChildrenSelection(childrenAges, roomCount) &&
        validateTravelerCount(adultsCount + childrenAges.length, roomCount)
    );
};

export default validateTravellerSelection;
