import * as React from 'react';
import classNames from 'classnames';

interface IProps {
    season: string;
    onSeasonChange: (season: string) => void;
}

export default ({ season, onSeasonChange }: IProps) => {
    const selectWinter = () => onSeasonChange('winter');
    const selectSummer = () => onSeasonChange('sommer');

    const winterClassname = classNames('tab', {
        active: season === 'winter'
    });

    const summerClassname = classNames('tab', {
        active: season === 'sommer'
    });

    return (
        <div className="tabs">
            <div role="tab" onClick={selectWinter} className={winterClassname}>
                Winter
            </div>
            <div role="tab" onClick={selectSummer} className={summerClassname}>
                Sommer
            </div>
        </div>
    );
};
