import * as React from 'react';
import Checkbox from 'src/common/Checkbox';

interface IProps {
    option: {
        text: string;
        value: string;
    };
    onChange: (transfer: string) => void;
    checked: boolean;
}

export default class MultiSelectChoice extends React.Component<IProps> {
    public render() {
        const { option, onChange, checked } = this.props;

        return (
            <div className="multi-select-choice" onClick={this.handleClick}>
                <Checkbox checked={checked} value={option.value} onClick={onChange} />
                <span className="multi-select-label">{option.text}</span>
            </div>
        );
    }

    private handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.onChange(this.props.option.value);
    };
}
