import * as React from 'react';
import { inject } from 'mobx-react';
import Device from 'src/helpers/device';
import { GenericTextStore } from 'src/stores/GenericTextStore';

export default inject('genericTextStore')(({ genericTextStore }) => (
    <>
        <span className="hidden-at-sm">{genericTextStore.phoneNumber}</span>
        <span className="visible-at-sm">{genericTextStore.mobilePhoneNumber}</span>
    </>
));

export const getCurrentPhoneNumber = (genericTextStore: GenericTextStore) =>
    Device.getWidthClass() === 'smartphone' ? genericTextStore.mobilePhoneNumber : genericTextStore.phoneNumber

