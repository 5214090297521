import { action, set } from 'mobx';
import { evolve } from 'ramda';
import track from 'src/helpers/tracking';

import { FormDataStore } from 'src/stores/FormDataStore';

interface ISuggestion {
    id: string;
    name: string;
    title: string;
    description: string;
    type: string;
}

export class InteractiveFormStore extends FormDataStore {
    @action public setBoardType = (boardType: string) => {
        set(this.getTravelkindForm(), 'boardType', boardType);
    };

    @action public toggleAirport = (name: string) => {
        const airports = this.formData.package.airport;

        set(
            this.getTravelkindForm(),
            'airport',
            airports.includes(name) ? airports.filter(a => name !== a) : [...airports, name]
        );
    };

    @action public toggleTransfer = (value: string) => {
        const transfer = this.formData.package.transfer || [];
        const newTransfer = transfer.includes(value) ? transfer.filter(a => value !== a) : [...transfer, value];

        track({
            eventCategory: `search_${this.travelkind}_topic`,
            eventAction: 'transfer',
            eventLabel: `transfer; ${newTransfer}`
        });

        set(this.getTravelkindForm(), 'transfer', newTransfer);
    };

    @action public resetAirport = () => {
        set(this.getTravelkindForm(), 'airport', []);
    };

    @action public setMaxP = (maxP: string) => {
        set(this.getTravelkindForm(), 'maxP', maxP);
    };

    @action public setCalendarDates = (dates: { departureDate?: Date; returnDate?: Date }) => {
        const currentForm = this.getTravelkindForm();

        const datesToSave = evolve(
            {
                departureDate: (d: Date) => d.toISOString(),
                returnDate: (d: Date) => d.toISOString()
            },
            dates
        );

        if (datesToSave.departureDate) {
            set(currentForm, 'departureDate', datesToSave.departureDate);
        }

        if (datesToSave.returnDate) {
            set(currentForm, 'returnDate', datesToSave.returnDate);
        }
    };

    @action public setSuggestionData = (suggestionData: ISuggestion) => {
        set(this.getTravelkindForm(), 'suggestion', suggestionData);
    };

    @action public resetSuggestions = () => {
        set(this.getTravelkindForm(), 'suggestion', undefined);
    };

    @action public setDuration = (duration: string) => {
        set(this.getTravelkindForm(), 'duration', duration);
    };

    @action public setHotelCategory = (category: string) => {
        set(this.getTravelkindForm(), 'hotelCategory', category);
    };

    @action public setPaxData = (adults: number, children: number[], numberOfRooms: number) => {
        const formData = this.getTravelkindForm();

        set(formData, 'adults', adults);
        set(formData, 'children', children);
        set(formData, 'numberOfRooms', numberOfRooms);
    };
}
