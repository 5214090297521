import * as React from 'react';
import { graphql, NamedProps, QueryResult } from 'react-apollo';
import CarouselSlider from 'src/common/CarouselSlider';
import { path } from 'ramda';
import { inject } from 'mobx-react';

import Image from 'src/common/Image';

import externalGql from './externalQuery.gql';
import SectionHeadline from 'src/common/SectionHeadline';

import slugify from 'src/helpers/slugify';

import './TourOperatorSection.scss';
import { ContentfulStore } from 'src/stores/ContentfulStore';

interface ITourOperator {
    name: string;
    id: string;
}

interface ITourOperators {
    tourOperators?: ITourOperator[];
    sectionHeadline?: string;
}

interface IClientQuery {
    contentfulTopic: {
        headlines: {
            tourOperator: string;
        };
    };
}

interface IWhiteList {
    [key: string]: boolean;
}

// @see TM-611 for whitelist definition
const whiteList: IWhiteList = require('./TourOperatorWhitelist.json');

const carouselSettings = {
    itemWidth: 150,
    offset: 20
};

type QueryProps = NamedProps<
    { clientQuery: IClientQuery; externalQuery: ITourOperators & QueryResult },
    ITourOperators
>;
interface IProps {
    contentfulStore: ContentfulStore;
}

export const TourOperatorSection = ({ externalQuery, contentfulStore }: QueryProps & IProps) => {
    const tourOperatorLogoBaseHref = 'https://media.holidaycheck.com/data/common/organizerlogo/by-uuid/';
    const linkBase = `${CFG.WWW}/od/`;

    if (!externalQuery || externalQuery.error || externalQuery.loading || !externalQuery.tourOperators) return null;

    const sectionHeadline: string | undefined = path(['headlines', 'tourOperator'], contentfulStore.topic);
    const tourOperatorsPRE = externalQuery.tourOperators;

    const tourOperators = tourOperatorsPRE.filter((op: ITourOperator) => {
        return whiteList[op.id];
    });

    const slides = tourOperators.map(({ name, id }: ITourOperator) => (
        <a key={id} href={linkBase + slugify(name) + '/' + id}>
            <div className="slide-tile">
                <Image
                    offset={50}
                    height={70}
                    src={`${tourOperatorLogoBaseHref}${id}/240x100`}
                    title={name}
                    alt={name}
                />
            </div>
        </a>
    ));

    return (
        <div className="tourOperator-section">
            <SectionHeadline cssClasses="dash" headline={sectionHeadline} />
            <CarouselSlider carouselSettings={carouselSettings}>{slides}</CarouselSlider>
            <a className="show-all-link" href="/oi/reiseveranstalter">
                Alle anzeigen&nbsp;&gt;
            </a>
        </div>
    );
};

const Composer = graphql<QueryProps & IProps>(externalGql, { name: 'externalQuery' })(TourOperatorSection);

export default inject('contentfulStore')(Composer);
