import * as React from 'react';
import { Route, RouteComponentProps, StaticContext } from 'react-router';

interface IStaticContext extends StaticContext {
    status?: number;
}

class Redirect extends React.Component<IStaticContext> {
    public render() {
        return <Route render={this.setStatusInContext} />;
    }

    private setStatusInContext = ({ staticContext }: RouteComponentProps<any, IStaticContext, any>) => {
        if (staticContext) {
            staticContext.status = this.props.status;
        }
        return null;
    };
}

export default Redirect;
