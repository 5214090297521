import * as React from 'react';

import Image from 'src/common/Image';
import track from 'src/helpers/tracking';
import LinkCheck from 'src/common/LinkCheck';

import { ITeaserType } from '../index';

interface IProps {
    teaser: ITeaserType;
    rank: string;
    index: number;
}

const defaultImage = {
    title: '',
    description: '',
    url: 'about:blank'
};

export default class Teaser extends React.Component<IProps> {
    public handleClick = () => {
        const { rank, teaser, index } = this.props;

        track({
            eventCategory: 'topicTeaser',
            eventAction: rank === '1' ? 'top' : 'bottom',
            eventLabel: `${teaser.slug.replace(/^\/+/g, '')}; ${index}`
        });
    };

    public render() {
        const { teaser } = this.props;
        const { url, slug } = teaser;
        const info = teaser.image || defaultImage;

        return (
            <li className="teaser-list-item">
                <LinkCheck onClick={this.handleClick} route={url || slug} title={info.description}>
                    <React.Fragment>
                        <div className="teaser-image-wrapper">
                            <Image
                                useFadeIn={true}
                                offset={50}
                                height={145}
                                className="teaser-image"
                                src={info.url + '?w=145&h=145&fm=jpg&fl=progressive'}
                                alt={info.title}
                            />
                        </div>
                        <span className="teaser-title">{info.title}</span>
                    </React.Fragment>
                </LinkCheck>
            </li>
        );
    }
}
