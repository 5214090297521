export const DEFAULT_LOCALE: string = 'de';

interface ILocaleType {
    de: any[];
    [key: string]: any;
}

export const MONTHS: ILocaleType = {
    de: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ]
};

export const DAYS: ILocaleType = {
    de: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
};

export const DAYSLONG: ILocaleType = {
    de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
};

export const format = {
    dayNames: [...DAYS.de, ...DAYSLONG.de],
    monthNames: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez', ...MONTHS.de],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM']
};
