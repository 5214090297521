import * as React from 'react';
import { inject, observer } from 'mobx-react';

import MultiSelect from 'src/common/MultiSelect';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

import transferData from './data.json';
import { IStores } from 'src/helpers/store';

interface IInjectedProps {
    toggleTransfer: InteractiveFormStore['toggleTransfer'];
    transfer: string[];
}

@inject(({ interactiveFormStore }: IStores) => {
    return {
        toggleTransfer: interactiveFormStore.toggleTransfer,
        transfer: interactiveFormStore.travelkindForm.transfer || []
    };
})
@observer
class Transfer extends React.Component {
    get injected() {
        return this.props as IInjectedProps;
    }

    public saveTransfer = (selection: string) => {
        this.injected.toggleTransfer(selection);
    };

    public render() {
        return (
            <MultiSelect
                placeholder="Transfer beliebig"
                className="transfer-select"
                onChange={this.saveTransfer}
                value={this.injected.transfer}
                options={transferData}
            />
        );
    }
}

export default Transfer;
