// @ts-ignore
import * as dateMath from 'date-arithmetic';

export const getDate = (pm: boolean = true): Date => new Date(new Date().setHours(pm ? 12 : 0, 0, 0, 0));

const isInvalidDate = (currentDate: Date | undefined, minimumValidDate: Date): boolean =>
    Boolean(currentDate) && dateMath.lt(currentDate, minimumValidDate);

export const getStartOfToday = () => dateMath.startOf(new Date(), 'day');
export const getMinimumPackageDeparture = () => dateMath.add(getStartOfToday(), 2, 'day');
export const isInValidPackageDate = (currentDate: ITravelkindDate) =>
    isInvalidDate(new Date(currentDate), getMinimumPackageDeparture());
export const isInValidHotelDate = (currentDate: ITravelkindDate) =>
    isInvalidDate(new Date(currentDate), getStartOfToday());

export const getDateDiff = (fromDate: Date, toDate: Date): number =>
    Math.round(dateMath.diff(fromDate, toDate, 'day', true));

export const isSameDay = (fromDate: Date, toDate: Date): boolean =>
    Math.round(dateMath.diff(fromDate, toDate, 'day', true)) === 0;

export const isDateInRange = (currentDate: Date, minDate: Date, maxDate: Date): boolean => {
    return dateMath.inRange(currentDate, minDate, maxDate, 'day');
};

export const isDate = (d: any): boolean => {
    try {
        return d instanceof Date || Boolean(new Date(d).toISOString());
    } catch (e) {
        return false;
    }
};

export const normalizeDate = (v: string | Date, PM: boolean = true): Date =>
    new Date(new Date(v).setHours(PM ? 12 : 0, 0, 0, 0));
