import * as React from 'react';

import Icon from '../Icon';

import './Input.scss';

interface IProps {
    icon?: string;
    appendIcon?: boolean;
    prependIcon?: boolean;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    inputLegend?: any;
    value: string;
    onClick?: () => void;
    onChange?: () => void;
}

export default ({
    icon,
    appendIcon,
    prependIcon,
    className,
    readOnly,
    value,
    onClick,
    onChange,
    disabled,
    inputLegend
}: IProps) => {
    return (
        <span className="input-wrapper" onClick={onClick}>
            {icon && prependIcon && <Icon icon={icon} className="prepend-icon" />}
            {inputLegend}
            <input className={className} readOnly={readOnly} value={value} onChange={onChange} disabled={disabled} />
            {icon && appendIcon && <Icon icon={icon} className="append-icon" />}
        </span>
    );
};
