import React, { ReactNode } from 'react';

import hash from 'src/helpers/hash';
// load ALL available sections
import HeadlineSection from 'src/sections/HeadlineSection';
import TeaserSection from 'src/sections/TeaserSection';
import TravelSearchForm from 'src/sections/TravelSearchForm';
import TourOperatorSection from 'src/sections/TourOperatorSection';
import TourOperatorABGSection from 'src/sections/TourOperatorABGSection';
import OfferListing from 'src/sections/OfferListing';
import BreadCrumbBar from 'src/sections/BreadCrumbBar';
import BrandedTeaser from 'src/sections/BrandedTeaser';
import HeroImage from 'src/sections/HeroImage';
import HeroSection from 'src/sections/HeroSection';
import CampaignTeaser from 'src/sections/CampaignTeaser';
import SeoCustomContent from 'src/sections/SeoCustomContent';
import SmartphoneBanner from 'src/sections/SmartphoneBanner';
import DestinationInformation from 'src/sections/DestinationInformation';
import PageConfig from 'src/common/PageConfig';
import Kayak from 'src/sections/Kayak';
import Section from 'src/Section';
import Fragment from 'src/sections/Fragment';

interface ISectionsConfig {
    [key: string]: ReactNode;
}

const availableSectionComponent: ISectionsConfig = {
    HeadlineSection,
    TeaserSection,
    CampaignTeaser,
    TravelSearchForm,
    TourOperatorSection,
    TourOperatorABGSection,
    OfferListing,
    BrandedTeaser,
    HeroImage,
    HeroSection,
    BreadCrumbBar,
    SeoCustomContent,
    SmartphoneBanner,
    DestinationInformation,
    Kayak,
    Fragment
};

interface IPropsType {
    topic: IContentfulTopic;
    error?: any;
}

const SectionResolver = ({ topic, error }: IPropsType) => {
    const slug = topic.slug || '';
    const seoConfiguration = topic.seoConfiguration || null;
    const sections = topic.sections || [];

    return (
        <React.Fragment>
            <PageConfig seoConfiguration={seoConfiguration} slug={slug} error={error} />
            {sections.map((contentful: ISection) => {
                const { sectionProps, sectionId } = contentful;
                const sectionKey = hash(sectionId + sectionProps);

                if (sectionId in availableSectionComponent) {
                    const SectionComponent = availableSectionComponent[sectionId];
                    const sectionPayload = {
                        key: sectionKey,
                        ...JSON.parse(sectionProps || '{}')
                    };

                    return <Section Component={SectionComponent} componentPayload={sectionPayload} key={sectionKey} />;
                }

                return null;
            })}
        </React.Fragment>
    );
};

export default SectionResolver;
