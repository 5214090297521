/**
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 */
export interface ITrackingInformationType {
    event?: string; // ga hitType
    eventCategory: string;
    eventAction: string;
    eventLabel?: string | number;
    eventValue?: number;
    nonInteraction?: 1;
}

const track = (trackingData: ITrackingInformationType, nonInteraction = false): void => {
    if (typeof window === 'undefined') return;
    if (typeof window.dataLayer === 'undefined') window.dataLayer = [];

    const data = { event: 'event', ...trackingData, clientTimestamp: Date.now() };
    if (nonInteraction) data.nonInteraction = 1;
    window.dataLayer.push(data);
    if (window.location.port === '3000') console.log('GTM TRACK', data);
};

const resetDataLayer = () => {
    if (typeof window === 'undefined') return;
    if (typeof window.google_tag_manager === 'object') window.google_tag_manager[CFG.GTM_ID].dataLayer.reset();
};

export const trackPageView = (payload: object) => {
    if (typeof window === 'undefined') return;
    if (typeof window.dataLayer === 'undefined') window.dataLayer = [];

    resetDataLayer();
    window.dataLayer.push({
        event: 'page',
        clientTimestamp: Date.now(),
        gePageType: 'tm-urlaub',
        ...payload
    });
};

export default track;
