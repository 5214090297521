import { always, evolve, isEmpty, path } from 'ramda';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';

import { getNormalizedOfferFiltersFromStorage } from 'src/helpers/localStorage';
import { matchInArray, pathExists } from 'src/helpers/predicates';
import { isInValidHotelDate, isInValidPackageDate } from 'src/helpers/dates';
import isServerside from 'src/helpers/isServerside';
import generateDefaultFormData, { getPackageDefaults } from 'src/graphql/defaultState/formData';
import { TravelkindDataStore } from 'src/stores/TravelkindDataStore';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { FormDataStore } from 'src/stores/FormDataStore';

import overrideFormDataWithContentfulSettings from './storeInitialisation/overrideFormDataWithContentfulSettings';
import overrideWithUrlParams from './storeInitialisation/overrideWithUrlParams';
import validateFormData from 'src/helpers/validateFormData';

export const overWriteWithDefaultTravelDates = (
    storeSettings: IFormData,
    defaultTravelDates: IDefaultTravelDatesType
) => {
    const hasDefaultValues = (travelkind: string) => pathExists([travelkind, 'departureDate'], defaultTravelDates);
    const hotelValue: string = hasDefaultValues('hotelonly') ? 'hotelonly' : 'hotel';

    if ((hasDefaultValues('hotelonly') || hasDefaultValues('hotel')) && hasDefaultValues('package')) {
        return evolve(
            {
                package: {
                    departureDate: always(new Date(defaultTravelDates.package.departureDate).toISOString()),
                    returnDate: always(new Date(defaultTravelDates.package.returnDate).toISOString()),
                    duration: always(getPackageDefaults().duration)
                },
                hotel: {
                    departureDate: always(new Date(defaultTravelDates[hotelValue].departureDate).toISOString()),
                    returnDate: always(new Date(defaultTravelDates[hotelValue].returnDate).toISOString())
                }
            },
            storeSettings
        );
    }

    return storeSettings;
};

const overWriteWithLocalStorage = (defaultFormData: IFormData): IFormData => {
    const localStorageData = getNormalizedOfferFiltersFromStorage(localStorage);

    if (!isEmpty(localStorageData)) {
        const isHotelDateInvalid = isInValidHotelDate(path(['hotel', 'departureDate'], localStorageData) as string);
        const isPackageDateInvalid = isInValidPackageDate(path(
            ['package', 'departureDate'],
            localStorageData
        ) as string);

        if (isHotelDateInvalid || isPackageDateInvalid) {
            return overWriteWithDefaultTravelDates(localStorageData, defaultFormData);
        }

        return localStorageData;
    }

    return defaultFormData;
};

interface IStores {
    travelkindDataStore: TravelkindDataStore;
    interactiveFormStore: InteractiveFormStore;
    formDataStore: FormDataStore;
}

export const possibleKeys = [
    'departureDate',
    'returnDate',
    'duration',
    'boardType',
    'transfer',
    'airport',
    'maxP',
    'hotelCategory',
    'adults',
    'children',
    'numberOfRooms',
    'travelkind'
];

const hasUrlSearchParams = (search: string) => {
    return matchInArray(search, possibleKeys);
};

export const mergeFormSettings = (
    topicConfiguration: ITopicConfiguration,
    defaultTravelDates: IDefaultTravelDatesType,
    stores: IStores,
    router: RouteComponentProps
): void => {
    let defaultFormData: IFormData = overWriteWithDefaultTravelDates(generateDefaultFormData(), defaultTravelDates);
    const backUpFormData = { ...defaultFormData };
    const search = router.location.search || '';

    const hasUrlParams = hasUrlSearchParams(search);

    if (!isServerside()) {
        defaultFormData = overWriteWithLocalStorage(defaultFormData);
    }

    if (hasUrlParams) {
        defaultFormData = overrideWithUrlParams(defaultFormData, queryString.parse(search));
    }

    defaultFormData = validateFormData(defaultFormData, backUpFormData, topicConfiguration);

    if (topicConfiguration) {
        defaultFormData = overrideFormDataWithContentfulSettings(defaultFormData, topicConfiguration, router);
    }

    const availableTravelkinds = defaultFormData.availableTravelkinds;

    delete defaultFormData.availableTravelkinds;

    stores.travelkindDataStore.setAvailableTravelkind(availableTravelkinds);
    stores.interactiveFormStore.setFormData(defaultFormData);
    stores.formDataStore.setFormData(defaultFormData);
};
