import React, { Component } from 'react';
import classnames from 'classnames';

import track from 'src/helpers/tracking';

type SelectDateValueType = string;

export interface ISelectOptionType {
    text: string;
    value: SelectDateValueType;
}

interface IOnChange {
    name: string;
    value: string;
}

interface IEvent {
    target: {
        value: string;
    };
}

interface ISelectProps {
    options: ISelectOptionType[];
    value: string;
    placeholder?: string;
    name: string;
    className?: string;
    onChange?: (onChangeParam: IOnChange) => void;
    eventCategory?: string;
    eventAction?: string;
}

class Select extends Component<ISelectProps> {

    public handleChange = (event: IEvent): void => {
        const value = event.target.value;
        const { eventCategory = '', onChange, name } = this.props;

        if (onChange) {
            onChange({ name, value });
        }

        const eventAction = `${String(this.props.name || '').toLowerCase()}_picker_changed`;

        track({
            eventCategory,
            eventAction,
            eventLabel: value
        });
    };

    public renderOption = ({ value, text }: ISelectOptionType) => (
        <option value={value} key={value}>
            {text}
        </option>
    );

    public render() {
        const { options, placeholder, value, className } = this.props;

        return (
            <div className={classnames('select-wrap', { [className || '']: className })}>
                <select value={value} placeholder={placeholder} onChange={this.handleChange}>
                    {options.map(this.renderOption)}
                </select>
            </div>
        );
    }
}

export default Select;
