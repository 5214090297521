import React from 'react';

const H1CODE = /({{h1}})|{{H1}}/;

interface ISectionHeadline {
    headline?: string;
    cssClasses?: string;
}
export default ({ headline, cssClasses }: ISectionHeadline) => {
    return !headline ? null : H1CODE.test(headline) ? (
        <h1 className={cssClasses}>{headline.replace(H1CODE, '')}</h1>
    ) : (
        <h2 className={cssClasses}>{headline}</h2>
    );
};
