import React  from 'react';

import SuggestionSearch from 'src/common/SuggestionSearch';

import HotelCategory from '../formFields/HotelCategory';
import DatePicker from '../formFields/DatePicker';
import SubmitButton from '../formFields/SubmitButton';
import PaxDialog from '../formFields/PaxDialog';

const Hotel = () => (
    <div className="hotel-form travel-search-form">
        <div className="main-form-fields">
            <SuggestionSearch />
            <DatePicker />
            <PaxDialog />
            <HotelCategory />
        </div>
        <div className="form-footer">
            <SubmitButton />
        </div>
    </div>
);
export default Hotel;
