import * as React from 'react';
import { pathOr } from 'ramda';
import { inject } from 'mobx-react';

import Icon from 'src/common/Icon';
import Popover from 'src/common/Popover';
import SectionHeadline from 'src/common/SectionHeadline';
import { ContentfulStore } from 'src/stores/ContentfulStore';

import VoucherWrapper from './VoucherWrapper';
import SourcePictureRenderer from './SourcePictureRenderer';
import './heroSection.scss';

export interface IIMediaType {
    title: string;
    url: string;
}

interface IProps {
    contentfulStore: ContentfulStore;
}

export interface IPictureParam {
    mediaQuery: string;
    srcParams: string;
}

export const pictureParamsBackground: IPictureParam[] = [
    { mediaQuery: '(max-width: 320px)', srcParams: '?w=320&h=270&fit=fill&f=' },
    { mediaQuery: '(max-width: 570px)', srcParams: '?w=570&h=270&fit=fill&f=' },
    { mediaQuery: '(max-width: 749px)', srcParams: '?w=749&h=270&fit=fill&f=' },
    { mediaQuery: '(max-width: 999px)', srcParams: '?w=988&h=330&fit=fill&f=' },
    { mediaQuery: '(min-width: 1000px)', srcParams: '?w=988&h=330&fit=fill&f=' }
];

export const pictureParamsVoucher: IPictureParam[] = [
    { mediaQuery: '(max-width: 749px)', srcParams: '?w=42&h=42&fit=fill&f=' },
    { mediaQuery: '(max-width: 999px)', srcParams: '?w=42&h=42&fit=fill&f=' },
    { mediaQuery: '(min-width: 1000px)', srcParams: '?w=42&h=42&fit=fill&f=' }
];

const commonPictureParamsBackground = 'fm=jpg&fl=progressive';

const formatPictureParams = (pictureParams: IPictureParam[], imageFocusArea: string = ''): IPictureParam[] => {
    return pictureParams.map(param => {
        return imageFocusArea
            ? { ...param, srcParams: param.srcParams + imageFocusArea + '&' }
            : { ...param, srcParams: param.srcParams + 'center&' };
    });
};

export const HeroSection = ({ contentfulStore }: IProps) => {
    const contentfulTopic: IContentfulTopic = contentfulStore.topic;

    const {
        headlines: { subHeadline, voucherDescription, heroImage },
        generalText,
        heroImage: { backgroundImage, backgroundImageFocusArea, voucherImage, voucherUrl }
    } = contentfulTopic;

    const pictureParamsBackgroundWithCustomFocus = formatPictureParams(
        pictureParamsBackground,
        backgroundImageFocusArea
    );

    const pictureParamsVoucherWithCustomFocus = formatPictureParams(pictureParamsVoucher);

    const subHeadlineInfo = pathOr('', ['subHeadlineInfo'], generalText);
    const headlineInfoRaw = <p dangerouslySetInnerHTML={{ __html: subHeadlineInfo }} />;
    const altTextHeroImage = backgroundImage.description || 'Hero Image';

    return (
        <div className="hero-section">
            {voucherImage && (
                <VoucherWrapper voucherUrl={voucherUrl}>
                    <SourcePictureRenderer
                        baseURL={voucherImage.url}
                        className="badge"
                        pictureParams={pictureParamsVoucherWithCustomFocus}
                        altText={voucherImage.description || 'Gutschein'}
                    />
                    <div className="description">{voucherDescription}</div>
                    <div className="arrow">
                        <Icon icon="right-arrow-line" />
                    </div>
                </VoucherWrapper>
            )}

            <SourcePictureRenderer
                baseURL={backgroundImage.url}
                className="hero-image"
                pictureParams={pictureParamsBackgroundWithCustomFocus}
                altText={altTextHeroImage}
                commonPictureParams={commonPictureParamsBackground}
            />

            <div className="text-wrapper">
                <div className="title">{heroImage && <SectionHeadline headline={heroImage} />}</div>
                <div>
                    <h2 className="sub-title">
                        {subHeadline}
                        {subHeadlineInfo && <Popover body={headlineInfoRaw} />}
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default inject('contentfulStore')(HeroSection);
