import React from 'react';
import { ChildProps, graphql } from 'react-apollo';
import TourOperatorABGList from './TourOperatorAGBList';

import query from './query.gql';

export interface ITourOperator {
    name: string;
    id: string;
}
export interface IProps {
    tourOperators?: ITourOperator[];
}

interface IVariables {
    siteId: string;
}

const TourOperatorABGSection = ({ data }: ChildProps<{}, IProps, IVariables>) => {
    if (!data || data.error || data.loading || !data.tourOperators) {
        return null;
    }

    return (
        <div className="tour-operator-section">
            <TourOperatorABGList tourOperatorsList={data.tourOperators} />
        </div>
    );
};

export default graphql(query, {
    options: {
        variables: {
            siteId: CFG.TLD
        }
    }
})(TourOperatorABGSection);
