import * as React from 'react';

// @ts-ignore
import ToolTip from 'react-portal-tooltip';
import Icon from '../Icon';

import './tooltip.scss';

interface IProps {
    children?: any;
    body: any;
    placement?: string;
    className?: string;
}

const style = {
    style: {
        color: '#ffffff',
        background: '#525c66'
    },
    arrowStyle: {
        color: '#525c66',
        borderColor: false
    }
};

export default class Popover extends React.Component<IProps> {
    public static defaultProps = {
        placement: 'bottom'
    };

    public state = { visible: false };

    private tooltip: HTMLSpanElement | null | undefined;

    public hide = () => this.setState({ visible: false });

    public show = () => this.setState({ visible: true });

    public toggle = () => this.setState({ visible: !this.state.visible });

    public render() {
        const { children, body } = this.props;

        return (
            <span
                className="tooltip"
                onMouseEnter={this.show}
                onMouseLeave={this.hide}
                ref={tooltip => (this.tooltip = tooltip)}
            >
                {children || (
                    <span>
                        {' '}
                        <Icon icon="hint" onClick={this.toggle} />{' '}
                    </span>
                )}
                <ToolTip
                    tooltipTimeout={200}
                    active={this.state.visible}
                    position="bottom"
                    arrow="center"
                    parent={this.tooltip || this}
                    style={style}
                >
                    {body}
                </ToolTip>
            </span>
        );
    }
}
