// Apollo GraphQL client

import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { withClientState } from 'apollo-link-state';
import unfetch from 'isomorphic-unfetch';

import typeDefs from 'src/graphql/typeDefs';

const DEFAULT_GRAPHQL_PATH = 'http://proxy.k8s.dev.hcg.cloud/svc/content-query-v2';
const PROD_GRAPHQL_PATH = 'http://proxy.k8s.prod.hcg.cloud/svc/content-query-v2';
const WWW_GRAPHQL_PATH = `${CFG.WWW}/svc/content-query-v2`;

export function createClient(isServer: boolean): ApolloClient<NormalizedCacheObject> {
    // Create the cache first, which we'll share across Apollo tooling.
    // This is an in-memory cache. Since we'll be calling `createClient` on
    // universally, the cache will survive until the HTTP request is
    // responded to (on the server) or for the whole of the user's visit (in
    // the browser)
    const cache = new InMemoryCache({
        freezeResults: true,
        addTypename: false
    });

    const stateLink = withClientState({
        cache,
        resolvers: {},
        defaults: {},
        typeDefs
    });

    // If we're in the browser, we'd have received initial state from the
    // server. Restore it, so the client app can continue with the same data.
    if (!isServer) {
        cache.restore((window as any).__APOLLO_STATE__);
    }

    const getGraphQLPath = () => {
        if (CFG.LIVE) return isServer ? PROD_GRAPHQL_PATH : WWW_GRAPHQL_PATH;
        return DEFAULT_GRAPHQL_PATH;
    };

    // Return a new Apollo Client back, with the cache we've just created,
    // and an array of 'links' (Apollo parlance for GraphQL middleware)
    // to tell Apollo how to handle GraphQL requests
    return new ApolloClient({
        cache,
        assumeImmutableResults: true,
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError, operation, forward }) => {
                if (graphQLErrors) {
                    for (const error of graphQLErrors) {
                        console.warn(
                            JSON.stringify({
                                id: '[TPE006]',
                                code: 'APOLLO_GRAPHQL',
                                error
                                // context: operation.getContext()
                            })
                        );
                        if (error.message.indexOf('forced Content Type ID') > 0) {
                            // retry the request, returning the new observable
                            return forward(operation);
                        }
                    }
                }
                if (networkError) {
                    console.warn(
                        JSON.stringify({
                            id: '[TPE007]',
                            api: getGraphQLPath(),
                            error: `${networkError.message || ''}${'reason' in networkError ? ` (${networkError['reason']})` : ''}`
                        }))
                    }
                }
            ),

            stateLink,

            new BatchHttpLink({
                fetch: unfetch,
                credentials: 'same-origin',
                uri: getGraphQLPath(),
                headers: {
                    // TODO dont forget to adapt to proper usage on new api
                    origin: `topicpage.${CFG.TLD}`
                }
            })
        ]),

        ssrMode: isServer
    });
}
