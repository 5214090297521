import React, { Component } from 'react';
import { ChildProps, graphql } from 'react-apollo';

import LoadingSpinner from 'src/common/LoadingSpinner';
import createFacetsURL from 'src/helpers/createFacetsURL';

import RegionPrice from './RegionPrice';
import HotelSearchQ from '../../queries/hotelsQ.gql';
import { IHotelOfferType } from '../types';
import { inject, observer } from 'mobx-react';
import { FormDataStore } from 'src/stores/FormDataStore';
import { ContentfulStore } from 'src/stores/ContentfulStore';

interface IInheritedProps {
    regionUUID: string;
}

interface IInjectedProps extends IInheritedProps {
    formDataStore: FormDataStore;
    contentfulStore: ContentfulStore;
}

interface IQueryProps {
    hotelOfferSearch: { items: IHotelOfferType[] };
}

type IProps = ChildProps<IInjectedProps, IQueryProps>;

class HotelonlyRegionPrice extends Component<IProps> {
    public render() {
        const { data } = this.props;

        if (!data || data.error) {
            return null;
        }

        if (data.loading) {
            return (
                <div>
                    <LoadingSpinner />
                </div>
            );
        }

        if (data.hotelOfferSearch) {
            return <RegionPrice hotelOfferSearch={data.hotelOfferSearch} />;
        }

        return null;
    }
}

const gqlHotelWrapper = graphql<IInjectedProps, IQueryProps>(HotelSearchQ, {
    options: ({ regionUUID, formDataStore, contentfulStore }: IInjectedProps) => {
        const hotelFilter = contentfulStore.topic.topicConfiguration.hotelFilter;
        const hotelonlySettings = formDataStore.hotelForm;
        const departureDate = new Date(hotelonlySettings.departureDate);
        const returnDate = new Date(hotelonlySettings.returnDate);
        const { adults, children, hotelCategory } = hotelonlySettings;
        const formattedHotelFilter: string | undefined = hotelFilter ? createFacetsURL(hotelFilter) : undefined;

        return {
            variables: {
                adults,
                children,
                regionUUID,
                depDateYear: departureDate.getFullYear(),
                depDateMonth: departureDate.getMonth() + 1,
                depDateDay: departureDate.getDate(),
                retDateYear: returnDate.getFullYear(),
                retDateMonth: returnDate.getMonth() + 1,
                retDateDay: returnDate.getDate(),
                stars: hotelCategory ? 'gte:'+ hotelCategory : undefined,
                hotelFilter: formattedHotelFilter
            }
        };
    }
});

export default inject('formDataStore', 'contentfulStore')(observer(gqlHotelWrapper(HotelonlyRegionPrice)));
