import React from 'react';
import { always, cond, groupBy, includes, T } from 'ramda';

import Image from 'src/common/Image';

interface IImageType {
    title: string;
    url: string;
    description?: string;
}

interface IPropsType {
    images: IImageType[];
    customClass?: string;
    usePNG?: boolean;
}

export const pictureMinWidth = {
    xs: '(max-width: 749px)',
    sm: '(max-width: 749px)',
    md: '(max-width: 999px)',
    lg: '(min-width: 1000px)'
};

export const isRetina = (imgTitle: string): boolean => imgTitle.toUpperCase().includes('RETINA');

export const buildMediaQuery = (size: string): string => pictureMinWidth[size.toLowerCase()];

const defaultImgParams = '?fl=progressive';

export const buildSrcSet = (images: IImageType[], usePNG: boolean): string => {
    if (images.length === 1) {
        return images[0].url;
    }

    const pictureParams = !usePNG ? defaultImgParams + '&fm=jpg' : defaultImgParams;

    return isRetina(images[1].title)
        ? `${images[0].url + pictureParams} 1x, ${images[1].url + pictureParams} 2x`
        : `${images[1].url + pictureParams} 1x, ${images[0].url + pictureParams} 2x`;
};

const GroupBySize = groupBy((image: IImageType) =>
    cond([
        [includes('xs'), always('xs')],
        [includes('sm'), always('sm')],
        [includes('md'), always('md')],
        [T, always('lg')]
    ])(image.title.toLowerCase())
);

export default (props: IPropsType) => {
    const { images, customClass } = props;
    const imagesBySize = GroupBySize(images);
    const altText = imagesBySize.lg[0].description || (customClass === 'badge' ? 'Badge' : 'Hero Image');

    return (
        <picture>
            {Object.keys(imagesBySize).map(key => {
                const mediaQuery: string = buildMediaQuery(key);
                const srcSet: string = buildSrcSet(imagesBySize[key], props.usePNG);

                return <source media={mediaQuery} srcSet={srcSet} key={key} className={customClass} />;
            })}
            <img src={imagesBySize.lg[0].url} className={customClass} alt={altText} />
        </picture>
    );
};
