import React, { useState, useCallback } from 'react';

import Image from 'src/common/Image';

interface IProps {
    tourOperatorName: string;
    tourOperatorLogo: string;
}

const TourOperatorLogo = ({ tourOperatorName, tourOperatorLogo }: IProps) => {
    const [shouldRenderImage, setShouldRenderImage] = useState(true);

    const disableImage = useCallback(() => {
        setShouldRenderImage(false);
    }, []);

    if (shouldRenderImage) {
        return (
            <Image
                alt={tourOperatorName}
                offset={50}
                height={26}
                className="touroperator-logo"
                src={tourOperatorLogo}
                title={tourOperatorName}
                onError={disableImage}
            />
        );
    }
    return null;
};

export default TourOperatorLogo;
