import React, { useEffect, memo } from 'react';
import { path } from 'ramda';
import { inject } from 'mobx-react';

import { ContentfulStore } from 'src/stores/ContentfulStore';

import './SeoCustomContent.scss';

interface IProps {
    content?: 'customContentOne' | 'customContentTwo' | 'customContentThree';
    contentfulStore: ContentfulStore;
}

export const SeoCustomContent = memo(({ content, contentfulStore }: IProps) => {
    const { topic = null } = contentfulStore;
    if (!topic || !content) return null;
    let __html = String(path(['seoConfiguration', content], topic));
    if (!__html) return null;

    // extract and eval scripts within the content
    useEffect(() => {
        const regex = /<script[\s\S]*?>([\s\S]*?)<\/script>/gi;
        let match;
        while ((match = regex.exec(__html)) !== null) {
            if (match.index === regex.lastIndex) ++regex.lastIndex;
            if (match[1]) eval(match[1]);
            __html = __html.replace(match[0], '');
        }
    });

    if (!__html) return null;

    return (
        <section className="seo-section white-bg" dangerouslySetInnerHTML={{ __html }} />
    );
});

export default inject('contentfulStore')(SeoCustomContent);
