import { find, propEq } from 'ramda';

import { getDateDiff } from './dates';
import durationOptions from 'src/sections/TravelSearchForm/formFields/Duration/data.json';

interface IDurationType {
    minDays?: number;
    maxDays?: number;
}

export function getMinAndMax(startDate: Date, endDate: Date, duration?: string) {
    // for the holiday destinations where we might not have duration
    let durationData: IDurationType = { minDays: undefined, maxDays: undefined };

    if (duration === 'exactly') {
        const dateDiff = getDateDiff(startDate, endDate);
        durationData = {
            minDays: dateDiff,
            maxDays: dateDiff
        };
    } else if (duration) {
        const { minDays, maxDays }: IDurationType = find(propEq('valueFormattedForOfferService', duration.toString()))(
            durationOptions
        );
        durationData = {
            minDays,
            maxDays
        };
    }

    return durationData;
}

export function findFormattedDuration(value: string) {
    return find(propEq('value', value))(durationOptions).valueFormattedForOfferService;
}

export function getValueFromFormatted(value: string) {
    return find(propEq('valueFormattedForOfferService', value))(durationOptions).value;
}
