import React, { Component } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';

import { ISuggestionType } from 'src/helpers/autoSuggestUris';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import track from 'src/helpers/tracking';

import { IEvent, ISuggestions, ISuggestionSearch, ISuggestItem } from './utils/types';
import SuggestionMenu from './SuggestionMenu';
import query from './query.gql';

import './SuggestionSearch.scss';

const searchTypeLimited = [
    'DESTINATION_CITY',
    'DESTINATION_CONTINENT',
    'DESTINATION_COUNTRY',
    'DESTINATION_REGION',
    'HOTEL'
];

const hasSuggestions = ({ destinations, hotels }: ISuggestionSearch): boolean => {
    return Boolean(destinations) || Boolean(hotels)
};

interface IInjectedProps {
    interactiveFormStore: InteractiveFormStore;
}

interface IState {
    currentSearchValue: string;
    isListOpen: boolean;
    suggestions: ISuggestionSearch | {};
}

type IProps = WithApolloClient<{}>;

@inject('interactiveFormStore')
@observer
class SuggestionSearch extends Component<IProps, IState> {
    public state = {
        currentSearchValue: '',
        isListOpen: false,
        suggestions: {}
    };

    private readonly wrapperRef: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);

        // @ts-ignore
        this.refetchSearchResult = debounce(this.refetchSearchResult, 500);
        this.wrapperRef = React.createRef();
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public handleClickOutside = (event: Event) => {
        if (this.state.isListOpen && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.handleBlur();
        }
    };

    get injected() {
        return (this.props as unknown) as IInjectedProps;
    }

    public render() {
        return (
            <div className="autosuggest-wrapper" ref={this.wrapperRef}>
                <input
                    onChange={this.handleChange}
                    className="auto-suggest-input"
                    onFocus={this.handleFocus}
                    placeholder="Alle Reiseziele"
                    role="combobox"
                    aria-autocomplete="list"
                    aria-expanded={this.state.isListOpen}
                    autoComplete="off"
                    value={this.state.currentSearchValue}
                />
                <SuggestionMenu
                    handleSelect={this.handleSelect}
                    isOpen={this.state.isListOpen}
                    items={this.extractSuggestionsFromEntities()}
                />
            </div>
        );
    }

    private handleChange = (e: IEvent) => {
        this.setState({ currentSearchValue: e.target.value, isListOpen: Boolean(e.target.value) }, async () => {
            this.injected.interactiveFormStore.resetSuggestions();
            await this.refetchSearchResult();
        });
    };

    private handleSelect = (item: ISuggestionType) => {
        track({
            eventCategory: this.getTrackingCategory(),
            eventAction: 'searchterm_changed',
            eventLabel: item.name
        });

        this.setState({ currentSearchValue: item.name, isListOpen: false });

        const { id, name, highlightedName, classifier, placeDetailString } = item as ISuggestItem;

        this.injected.interactiveFormStore.setSuggestionData({
            id,
            name,
            description: highlightedName,
            title: placeDetailString,
            type: classifier
        });
    };

    private getTrackingCategory = () =>
        `search_${this.injected.interactiveFormStore.travelkind === 'package' ? 'package' : 'hotel'}_topic`; // todo we should add the topic itself `/wellness`

    private handleBlur = () => {
        this.setState({ isListOpen: false });

        track({
            eventCategory: this.getTrackingCategory(),
            eventAction: 'input_blurred',
            eventLabel: this.state.currentSearchValue
        });
    };

    private handleFocus = () => {
        if (this.state.currentSearchValue) {
            this.setState({ isListOpen: true });
        }
    };

    private readonly refetchSearchResult = async () => {
        if (this.state.currentSearchValue) {
            const { data, errors } = await this.props.client.query({
                query,
                variables: {
                    query: this.state.currentSearchValue,
                    limit: 3,
                    type: searchTypeLimited
                }
            });

            if (!errors && data) {
                this.setState({ suggestions: data.suggestionSearch });
            }
        }
    };

    private extractSuggestionsFromEntities = () => {
        const items = this.state.suggestions;

        if (hasSuggestions(items as ISuggestionSearch)) {
            const { destinations, hotels } = items as ISuggestionSearch;

            if (destinations && destinations.position === 1) {
                return [...destinations.entities, ...hotels.entities].slice(0, 7);
            }

            return [...hotels.entities, ...destinations.entities].slice(0, 7);
        }
        return [];
    };
}

export default withApollo<{}, ISuggestions>(SuggestionSearch);
