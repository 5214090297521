import React from 'react';

import { isNilOrEmpty } from 'src/helpers/predicates';
import Icon from 'src/common/Icon';

import { IIMediaType } from '../';
import BackgroundImageRenderer from '../backgroundImageRenderer';

interface IPropsType {
    badgeImages: IIMediaType[];
    description?: string;
    customClass: string;
}

const Voucher = (props: IPropsType) => {
    const { badgeImages, description, customClass } = props;

    if (isNilOrEmpty(badgeImages) || isNilOrEmpty(description)) {
        return null;
    }

    return (
        <a target="_blank" href={`${CFG.WWW}/gutschein`}>
            <div className="voucher">
                <BackgroundImageRenderer images={badgeImages} customClass={customClass} usePNG={true} />
                <div className="description">{description}</div>
                <div className="arrow">
                    <Icon icon="right-arrow-line" />
                </div>
            </div>
        </a>
    );
};

export default Voucher;
