import React from 'react';
import Image from 'src/common/Image';

import track from 'src/helpers/tracking';
import Icon from 'src/common/Icon';
import { IBrandedTeaser } from 'src/sections/BrandedTeaser/types';

import TourOperatorLogo from './TourOperatorLogo';

export interface IProps {
    brandedTeaser: IBrandedTeaser;
    position: number;
}

interface ITourOperatorType {
    name: string;
    id: string;
}

export default class BrandedTeaserTile extends React.Component<IProps> {
    public trackBrandedTeaserClick = () => {
        const { brandedTeaser, position } = this.props;

        track({
            eventCategory: 'brandedTeaser',
            eventAction: brandedTeaser.travelkind || 'package',
            eventLabel: [brandedTeaser.destination, position].join('; ')
        });
    };

    public render() {
        const { brandedTeaser } = this.props;
        const tourOperatorData: ITourOperatorType = JSON.parse(brandedTeaser.tourOperator);
        const tourOperatorLogo = `https://media.holidaycheck.com/data/common/organizerlogo/by-uuid/${tourOperatorData.id}/75x26`;
        const { url, urlTarget, destination, price, image } = brandedTeaser;

        return (
            <div className="branded-teaser">
                <a
                    className="image-link"
                    href={url}
                    target={brandedTeaser.urlTarget}
                    onClick={this.trackBrandedTeaserClick}
                >
                    <Image
                        offset={50}
                        height={230}
                        src={image.url + '?w=305&h=230&fit=fill&fm=jpg&fl=progressive'}
                        alt={destination || ''}
                        title={image.title}
                        imgClassName="mainImage"
                    />
                </a>
                <div className="bottom">
                    <a href={url} target={urlTarget} onClick={this.trackBrandedTeaserClick}>
                        <span className="arrow">
                            <Icon icon="fancy-arrow" />
                        </span>
                        <div className="title">{destination}</div>
                        <div className="bottomTile">
                            <span className="teaser-price">{price}</span>
                            <TourOperatorLogo
                                tourOperatorLogo={tourOperatorLogo}
                                tourOperatorName={tourOperatorData.name}
                            />
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}
