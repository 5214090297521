// @ts-ignore
import * as dateMath from 'date-arithmetic';

import { getDate } from 'src/helpers/dates';

export const getPackageDefaults = (): IPackage => ({
    departureDate: dateMath.add(getDate(), 4, 'day').toISOString(),
    returnDate: dateMath.subtract(dateMath.add(getDate(), 6, 'month'), 2, 'day').toISOString(),
    adults: 2,
    children: [],
    numberOfRooms: 1,
    airport: [],
    maxP: '',
    duration: '7',
    boardType: '',
    transfer: [],
    hotelCategory: '',
    suggestion: null
});

export const getHotelDefaults = (): IHotel => ({
    departureDate: dateMath.add(getDate(), 13, 'day').toISOString(),
    returnDate: dateMath.add(getDate(), 14, 'day').toISOString(),
    adults: 2,
    children: [],
    numberOfRooms: 1,
    hotelCategory: '',
    suggestion: null
});

export default (): IFormData => ({
    travelkind: 'package',
    package: getPackageDefaults(),
    hotel: getHotelDefaults()
});
