import React, { Children, Component } from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import classnames from 'classnames';

import inViewport from './utils/inViewport';

export interface ILazyLoadProps {
    className?: string;
    debounce?: boolean;
    elementType?: string;
    height?: string | number;
    offset?: number;
    throttle?: number;
    width?: string | number;
    onContentVisible?: () => void;
}

interface IState {
    visible: boolean;
}

export default class Index extends Component<ILazyLoadProps, IState> {
    public static defaultProps = {
        elementType: 'div',
        debounce: false,
        offset: 0,
        throttle: 100,
        isComponent: false
    };

    private _mounted: boolean;
    private readonly ref: React.RefObject<any>;

    constructor(props: ILazyLoadProps) {
        super(props);

        if (props.throttle > 0) {
            if (props.debounce) {
                this.lazyLoadHandler = debounce(this.lazyLoadHandler, props.throttle);
            } else {
                this.lazyLoadHandler = throttle(this.lazyLoadHandler, props.throttle);
            }
        }

        this._mounted = false;
        this.state = { visible: false };
        this.ref = React.createRef();
    }

    public componentDidMount() {
        this._mounted = true;

        this.lazyLoadHandler();

        if (this.lazyLoadHandler.flush) {
            this.lazyLoadHandler.flush();
        }

        window.addEventListener('scroll', this.lazyLoadHandler, { passive: true});
        window.addEventListener('resize', this.lazyLoadHandler, { passive: true});
    }

    public componentWillReceiveProps() {
        if (!this.state.visible) {
            this.lazyLoadHandler();
        }
    }

    public shouldComponentUpdate(_nextProps: ILazyLoadProps, nextState: IState) {
        return nextState.visible;
    }

    public componentWillUnmount() {
        this._mounted = false;

        if (this.lazyLoadHandler.cancel) {
            this.lazyLoadHandler.cancel();
        }

        this.detachListeners();
    }

    public lazyLoadHandler = () => {
        if (!this._mounted) {
            return;
        }

        const offset = this.props.offset;
        const node = this.ref.current;

        if (inViewport(node, offset)) {
            const { onContentVisible } = this.props;

            this.setState({ visible: true }, () => {
                if (onContentVisible) {
                    onContentVisible();
                }
            });
            this.detachListeners();
        }
    };

    public detachListeners = () => {
        window.removeEventListener('scroll', this.lazyLoadHandler);
        window.removeEventListener('resize', this.lazyLoadHandler);
    };

    public render() {
        const { children, className, height, width } = this.props;
        const { visible } = this.state;

        const elStyles = { height, width };

        return React.createElement(
            this.props.elementType,
            {
                className: classnames('LazyLoad', { 'is-visible': visible }, className),
                style: elStyles,
                ref: this.ref
            },
            visible && Children.only(children)
        );
    }
}
