import * as React from 'react';
import Popover from 'src/common/Popover';
import { path } from 'ramda';
import { inject } from 'mobx-react';

import './HeadlineSection.scss';
import { ContentfulStore } from 'src/stores/ContentfulStore';

interface IHeadlines {
    headline: string;
    subHeadline: string;
    subHeadlineInfo: string;
}

interface IProps {
    contentfulStore: ContentfulStore;
}

export function HeadlineSection({ contentfulStore }: IProps) {
    const contentfulTopic = contentfulStore.topic;

    const headlines = path(['headlines'], contentfulTopic);

    if (!headlines) {
        return null;
    }

    const { headline, subHeadline, subHeadlineInfo } = headlines as IHeadlines;
    const headlineInfoRaw = <p dangerouslySetInnerHTML={{ __html: subHeadlineInfo }} />;
    const showPopOver = subHeadlineInfo && subHeadlineInfo.length > 0;

    return (
        <div className="headline-section white-bg">
            <h1 className="headline">{headline}</h1>
            <h2 className="subheadline">
                <span className="subheadline-text">{subHeadline}</span>
                {showPopOver && <Popover body={headlineInfoRaw} />}
            </h2>
        </div>
    );
}

export default inject('contentfulStore')(HeadlineSection);
