import React from 'react';

import Icon from 'src/common/Icon';
import Image from 'src/common/Image';

import CarouselSlider from 'src/common/CarouselSlider';

interface IProps {
    items: IDestinationInformationConfiguration[];
}

const sliderSettings = {
    itemWidth: 270,
    offset: 10
};

export default function DestinationInformationContent(props: IProps) {
    const { items } = props;
    return (
        <div className="destination-information-content">
            <CarouselSlider carouselSettings={sliderSettings}>
                {items.map(item => (
                    <a className="item-spacer" key={item.name} href={item.url}>
                        <div className="information-item">
                            <Image
                                offset={50}
                                height={200}
                                width={268}
                                imgClassName="item-picture"
                                src={item.picture.url}
                                alt={item.name}
                            />
                            <div className="item-header">
                                <div className="item-name">{item.name}</div>
                                {item.location && (
                                    <div className="item-location">
                                        <Icon icon="location" />
                                        {item.location}
                                    </div>
                                )}
                            </div>
                            <div className="item-description" dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                    </a>
                ))}
            </CarouselSlider>
        </div>
    );
}
