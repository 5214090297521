import * as React from 'react';
import { inject, observer } from 'mobx-react';

import buildFacetsLocalStorage from 'src/helpers/buildFacetsLocalStorage';
import track from 'src/helpers/tracking';
import SectionHeadline from 'src/common/SectionHeadline';
import Icon from 'src/common/Icon';
import Button from 'src/common/Button';
import { ContentfulStore } from 'src/stores/ContentfulStore';
import { setOfferFiltersToStorage, setNormalizedOfferFiltersToStorage } from 'src/helpers/localStorage';
import { FormDataStore } from 'src/stores/FormDataStore';

import CountryTile from './CountryTile';

import './styles/OfferListing.scss';

export interface ITopCountry extends IRegion {
    children: IRegion[];
}

export interface IRegion {
    id: string;
    name: string;
    uuid: string;
    url?: string;
    imageURL?: string;
}

interface IInjectedProps {
    contentfulStore: ContentfulStore;
    formDataStore: FormDataStore;
}

interface IState {
    page: number;
}

function filterCurrentPage(pageSize: number, page: number) {
    return (_: any, i: number) => i < pageSize * page;
}

const PAGE_SIZE = 6;

@inject('contentfulStore', 'formDataStore')
@observer
export default class OfferListing extends React.Component<{}, IState> {
    public state = {
        page: 1
    };

    get injected() {
        return this.props as IInjectedProps;
    }

    public getButtonRow = (records: number, page: number, pageSize: number) => {
        if (records > page * pageSize) {
            return (
                <div className="show-more-regions">
                    <Button onClick={this.loadMore} type="primary">
                        Weitere Regionen anzeigen
                        <Icon icon="down-arrow-line" className="standard-icon-size" />
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    };

    public loadMore = () => {
        track({
            eventCategory: 'topicDestinationTeaser',
            eventAction: 'expandListing',
            eventLabel: this.state.page
        });

        this.setState({
            page: this.state.page + 1
        });
    };

    public handleOnTileClick = () => {
        setOfferFiltersToStorage(localStorage, { travelkind: this.injected.formDataStore.travelkind });
        setNormalizedOfferFiltersToStorage(localStorage, this.injected.formDataStore.formData);
        buildFacetsLocalStorage(this.injected.contentfulStore.topic.topicConfiguration.hotelFilter);
    };

    public renderCountryTile = () => {
        const { topRegions } = this.injected.contentfulStore.topic;
        const regions: ITopCountry[] = JSON.parse(topRegions || '');
        const buttonRow = this.getButtonRow(regions.length, this.state.page, PAGE_SIZE);
        const { contentfulStore: { slug }} = this.injected;
        const isRootPage = slug === '/';
        const adPositionIndex = isRootPage ? 1 : 2;

        return (
            <React.Fragment>
                {regions
                    .filter(filterCurrentPage(PAGE_SIZE, this.state.page))
                    .map((country: ITopCountry, i: number) => {
                        return (
                            <React.Fragment key={country.id}>
                                {!isRootPage && i === adPositionIndex && (
                                    <div className="adslot adslot-banner2 adslot-topmobile2" data-slug={slug}><div /></div>
                                )}
                                <CountryTile
                                    onClickHandler={this.handleOnTileClick}
                                    key={country.id}
                                    position={i}
                                    country={country}
                                />
                            </React.Fragment>
                        );
                    })}
                {buttonRow}
                <div className="adslot adslot-topmobile3"><div /></div>
            </React.Fragment>
        );
    };

    public render() {
        const contentfulTopic = this.injected.contentfulStore.topic;

        if (!contentfulTopic.topRegions) {
            return null;
        }

        const {
            headlines: { offeringList }
        } = contentfulTopic;

        return (
            <section className="OfferListing">
                <SectionHeadline cssClasses="dash center" headline={offeringList} />
                {this.renderCountryTile()}
            </section>
        );
    }
}
