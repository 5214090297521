import React, { Component } from 'react';
import { path, pathOr } from 'ramda';
import { ChildProps, graphql } from 'react-apollo';
import { inject, observer } from 'mobx-react';

import weatherServiceQuery from '../../queries/weatherService.gql';
import Temperature from './Temperature';
import { FormDataStore } from 'src/stores/FormDataStore';

interface IMonths {
    temperatureMin: number;
    temperatureMax: number;
    precipitationSum: number;
    temperatureSeawater: number;
}

interface IGenerateMinMaxTemperature {
    maxTemperature: number | null;
    minTemperature: number | null;
    minSeaTemperature: number | null;
    maxSeaTemperature: number | null;
}

interface IWeatherService {
    months: IMonths[];
}

interface IResult {
    weatherService: IWeatherService;
}

interface IInheritedPropsType {
    regionUUID: string;
}

interface IInjectedProps extends IInheritedPropsType {
    formDataStore: FormDataStore;
}

const generateMinMaxTemperature = (
    weather: IMonths[],
    departureMonth: number,
    returnMonth: number
): IGenerateMinMaxTemperature => {
    let maxTemperature: number | null = null;
    let minTemperature: number | null = null;
    let minSeaTemperature: number | null = null;
    let maxSeaTemperature: number | null = null;

    const departureMonthData: IMonths = weather[departureMonth];
    const returnMonthData: IMonths = weather[returnMonth];

    if (!departureMonthData || !returnMonthData) {
        return {
            minTemperature,
            maxTemperature,
            minSeaTemperature,
            maxSeaTemperature
        };
    }
    const departureMonthMinTemperature: number = departureMonthData.temperatureMin;
    const departureMonthMaxTemperature: number = departureMonthData.temperatureMax;

    const returnMonthMinTemperature: number = returnMonthData.temperatureMin;
    const returnMonthMaxTemperature: number = returnMonthData.temperatureMax;

    if (departureMonthData.temperatureSeawater && returnMonthData.temperatureSeawater) {
        const departureWaterTemperature: number = departureMonthData.temperatureSeawater;
        const returnWaterTemperature: number = returnMonthData.temperatureSeawater;
        maxSeaTemperature = Math.round(Math.max(departureWaterTemperature, returnWaterTemperature));
        minSeaTemperature = Math.round(Math.min(departureWaterTemperature, returnWaterTemperature));
    }

    maxTemperature = Math.round(Math.max(returnMonthMaxTemperature, departureMonthMaxTemperature));
    minTemperature = Math.round(Math.min(departureMonthMinTemperature, returnMonthMinTemperature));

    return {
        minTemperature,
        maxTemperature,
        minSeaTemperature,
        maxSeaTemperature
    };
};

@inject('formDataStore')
@observer
class Temperatures extends Component<ChildProps<IInheritedPropsType, IResult>> {
    get injected() {
        return this.props as IInjectedProps;
    }
    public calculateTemperatures() {
        const formData = this.injected.formDataStore.formData;
        const { travelkind } = formData;
        const departureDate = path([travelkind, 'departureDate'], formData);
        const returnDate = path([travelkind, 'returnDate'], formData);

        const departureMonth: number = new Date(departureDate as ITravelkindDate).getMonth();
        const returnMonth: number = new Date(returnDate as ITravelkindDate).getMonth();

        return generateMinMaxTemperature(
            pathOr([], ['weatherService', 'months'], this.props.data),
            departureMonth,
            returnMonth
        );
    }

    public render() {
        const { data = null } = this.props;

        if (!data || data.error || data.loading) return null;

        const { maxTemperature, minTemperature, minSeaTemperature, maxSeaTemperature } = this.calculateTemperatures();

        return (
            <div className="temperatures">
                <Temperature minTemperature={minTemperature} maxTemperature={maxTemperature} iconType="sun" />
                <Temperature
                    minTemperature={minSeaTemperature}
                    maxTemperature={maxSeaTemperature}
                    iconType="water-temperature"
                    className="thermometer"
                />
            </div>
        );
    }
}

export default graphql<IInheritedPropsType, IResult>(weatherServiceQuery, {
    options: ({ regionUUID }: IInheritedPropsType) => {
        return {
            variables: {
                destinationId: regionUUID
            }
        };
    }
})(Temperatures);
