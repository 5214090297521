import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import Icon from 'src/common/Icon';

import './styles/modal.scss';

interface IModalProps {
    isOpen?: boolean;
    className?: string;
    title?: string;
    closeModal: () => void;
    footer?: any;
}

export default class Modal extends React.Component<IModalProps> {
    private readonly wrapperRef: React.RefObject<any>;

    constructor(props: IModalProps) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public handleClickOutside = (event: Event) => {
        if (this.props.isOpen && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.closeModal();
        }
    };

    public render() {
        const { isOpen, footer, title, children, closeModal } = this.props;

        return isOpen
            ? ReactDOM.createPortal(
                  <div className={classNames('modal', { footer })}>
                      <div className="modal-box" ref={this.wrapperRef}>
                          <div className="modal-header">
                              {title && <div className="modal-title">{title}</div>}
                              <Icon icon="close" className="close" onClick={closeModal} />
                          </div>
                          <div className="modal-content">{children}</div>
                          {footer}
                      </div>
                  </div>,
                  document.querySelector('#modal') as Element
              )
            : null;
    }
}
