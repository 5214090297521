import * as React from 'react';
import { inject, observer } from 'mobx-react';

import track from 'src/helpers/tracking';
import { createDestinationPath, createOldTopicPagePath, createDestinationIndexPath } from 'src/helpers/autoSuggestUris';
import Icon from 'src/common/Icon';
import { IRegion, ITopCountry } from 'src/sections/OfferListing';
import { ContentfulStore } from 'src/stores/ContentfulStore';
import { FormDataStore } from 'src/stores/FormDataStore';

import RegionTile from '../RegionTile';
import CountryImage from './CountryImage';

import '../styles/countryTile.scss';

interface IInheritedPropsType {
    onClickHandler: () => void;
    position: number;
    country: ITopCountry;
}

interface IInjectedProps extends IInheritedPropsType {
    contentfulStore: ContentfulStore;
    formDataStore: FormDataStore;
}

@inject('contentfulStore', 'formDataStore')
@observer
export class CountryTile extends React.Component<IInheritedPropsType> {
    public state = {
        moreRegion: false
    };

    get injected() {
        return this.props as IInjectedProps;
    }

    public render() {
        const {
            country: { uuid, name, url, children, imageURL },
            onClickHandler
        } = this.props;
        const href = this.buildHref();
        const targetUrl = url || href;

        const numberOfRegion: number = children.length;
        const childrenToRender: IRegion[] = this.state.moreRegion ? children : children.slice(0, 3);

        return (
            <div className="country-tile" onClick={onClickHandler}>
                <a className="country-image" href={targetUrl} onClick={this.trackClick}>
                    <CountryImage uuid={uuid} name={name} imageURL={imageURL} />
                </a>
                <div className="country-tile-content">
                    <div className="country-main-content">
                        <a className="country-title-link" href={targetUrl}>
                            <h3 className="country-title">{name} </h3>
                        </a>

                        <div className="description">
                            {childrenToRender &&
                                childrenToRender.map((region: IRegion, i: number) => (
                                    <RegionTile position={i} key={region.id} region={region} />
                                ))}
                        </div>
                    </div>
                    <div className="more-region">
                        {this.state.moreRegion ? (
                            <a onClick={this.toggleMoreRegions} className="more-region-link">
                                Weniger Regionen anzeigen <Icon icon="up-arrow-line" />
                            </a>
                        ) : numberOfRegion > 3 ? (
                            <a onClick={this.toggleMoreRegions} className="more-region-link">
                                Weitere Regionen anzeigen <Icon icon="down-arrow-line" />
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    private trackClick = () => {
        track({
            eventCategory: 'topicDestinationTeaser',
            eventAction: 'listing',
            eventLabel: `${this.props.country.name}; ${this.props.position}`
        });
    };

    private buildHref = () => {
        const {
            country: { uuid, name },
            contentfulStore,
            formDataStore
        } = this.props as IInjectedProps;

        const { slug } = contentfulStore.topic;

        if (['/fruehbucher', '/last-minute', '/pauschalreisen'].includes(slug)) {
            return slug;
        }

        const defaultDestinationRoute = createDestinationPath({ id: uuid, name, classifier: 'DESTINATION_COUNTRY' });

        const travelKind = formDataStore.travelkind;
        
        if (slug === '/reisebuero') {
            return createDestinationIndexPath({ id: uuid, name, classifier: 'DESTINATION_COUNTRY' })
        }
        
        if (slug === '/hotels' || slug === '/' || travelKind === 'hotel') {
            return defaultDestinationRoute;
        }

        return defaultDestinationRoute.replace('/dh/hotels', '/tp' + slug);
    };

    private toggleMoreRegions = () => this.setState({ moreRegion: !this.state.moreRegion });
}

export default CountryTile;
