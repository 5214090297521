import { always, cond, isNil, partial, propEq, T } from 'ramda';

import { routeSlug } from 'src/helpers/routeSlug';
import { isDefined } from 'src/helpers/predicates';
import {
    ISuggestItem,
    ISuggestItemCruise,
    ISuggestItemDestination,
    ISuggestItemHotel,
    ISuggestItemPassion
} from 'src/common/SuggestionSearch/utils/types';
import slugify from 'src/helpers/slugify';

type ITravelKindType = 'package' | 'hotel';

export type SuggestionClassifierType =
    | 'CRUISE'
    | 'DESTINATION_CITY'
    | 'DESTINATION_CONTINENT'
    | 'DESTINATION_COUNTRY'
    | 'DESTINATION_REGION'
    | 'GENERAL_SEARCH'
    | 'HOTEL'
    | 'PASSION'
    | 'PASSION_DEST'
    | 'SHIP';

export interface ISuggestionType {
    id?: string;
    classifier?: SuggestionClassifierType;
    type?: SuggestionClassifierType;
    name: string;
    title?: string;
    description?: string;
    highlightedName?: string;
    placeDetailString?: string;
}

export interface ISuggestionPassionType {
    passion: string;
    highlighted: string;
    completeMatch: boolean;
}

export const isHotel = (t: any) =>
    (isDefined(t.classifier) && t.classifier === 'HOTEL') || (isDefined(t.type) && t.type === 'HOTEL');
export const isPassion = (t: any) => isDefined(t.passion);
export const isDestination = (t: any) =>
    (isDefined(t.classifier) && t.classifier.startsWith('DESTINATION')) ||
    (isDefined(t.type) && t.type.startsWith('DESTINATION'));
export const isCruise = (t: any) => isDefined(t.cruiseType);
export const isGeneralSearch = propEq('classifier', 'GENERAL_SEARCH');

export function createOldTopicPagePath(slug: string, name: string, id: string): string {
    return `${routeSlug(`/tp${slug}-`, name)}/${id}`;
}

export function createDestinationPath(suggestion: ISuggestionType): string {
    return `${routeSlug('/dh/', suggestion.name)}/${suggestion.id}`;
}

export function createDestinationIndexPath(suggestion: ISuggestionType): string {
    return `/urlaub/${slugify(suggestion.name)}`;
}

function createOfferListPath(travelkind: ITravelKindType, suggestion: ISuggestionType): string {
    const redirectURL = routeSlug('/ho/', suggestion.name);
    const pTravelkind = travelkind === 'hotel' ? 'hotelonly' : travelkind;

    return `${redirectURL}/${suggestion.id}/${pTravelkind}`;
}

function createPassionPath(suggestion: ISuggestionPassionType): string {
    return `/vorlieben/${slugify(suggestion.passion)}`;
}

function createCruisePath(suggestion: ISuggestItemCruise): string {
    return `/kreuzfahrten/${suggestion.path}`;
}

function createGeneralPath(suggestion: ISuggestionType): string {
    return `/search-result/?q=${suggestion.title}`;
}

export function createTravelKindPath(travelkind: ITravelKindType, pathname: string): string {
    const topicHome = CFG.PUBLIC_PATH + '/';
    const currentPath = pathname.endsWith('/') ? pathname : pathname + '/';
    const isTopicHome = currentPath === topicHome;

    if (isTopicHome && travelkind === 'package') {
        return `/pauschalreisen`;
    }

    if (isTopicHome && travelkind === 'hotel') {
        return `${topicHome}hotels`;
    }

    return pathname;
}

export function pathFromSelection(
    travelkind: ITravelKindType,
    suggestion: ISuggestionType | undefined,
    pathname: string
): string {
    return cond([
        [isNil, partial(createTravelKindPath, [travelkind, pathname])],
        [isHotel, partial(createOfferListPath, [travelkind])],
        [isDestination, createDestinationPath],
        [isPassion, createPassionPath],
        [isCruise, createCruisePath],
        [isGeneralSearch, createGeneralPath]
    ])(suggestion);
}

export function extractDescription(item: ISuggestItem): string {
    return cond([
        [
            isHotel,
            (currentItem: ISuggestItemHotel) =>
                currentItem.highlightedPlaceDetailString || currentItem.placeDetailString
        ],
        [
            isDestination,
            (currentItem: ISuggestItemDestination) =>
                currentItem.highlightedPlaceDetailString || currentItem.placeDetailString
        ],
        [
            isPassion,
            (currentItem: ISuggestItemPassion) => `Hotels, in deren Bewertungen "${currentItem.passion}" erwähnt wird.`
        ],
        [isCruise, always('Kreuzfahrten und Schiffe auf HolidayCheck')],
        [T, always('')]
    ])(item);
}
