import React, { Component } from 'react';
import { ChildProps, graphql } from 'react-apollo';
import { inject, observer } from 'mobx-react';

import createFacetsURL from 'src/helpers/createFacetsURL';
import LoadingSpinner from 'src/common/LoadingSpinner';
import { getMinAndMax } from 'src/helpers/duration';
import { FormDataStore } from 'src/stores/FormDataStore';
import { ContentfulStore } from 'src/stores/ContentfulStore';

import PackageSearchQ from '../../queries/packagesQ.gql';
import { IHotelOfferType } from '../types';
import RegionPrice from './RegionPrice';

interface IQueryProps {
    hotelOfferSearch: {
        items: IHotelOfferType[];
    };
}

interface IInjectedProps extends IInheritedProps {
    formDataStore: FormDataStore;
    contentfulStore: ContentfulStore;
}

interface IInheritedProps {
    regionUUID: string;
    topicSettings: IFormData;
    hotelFilter: IHotelFilter;
}

type IProps = ChildProps<IInjectedProps, IQueryProps>;

class PackageRegionPrice extends Component<IProps> {
    public render() {
        const { data } = this.props;

        if (!data || data.error) {
            return null;
        }

        if (data.loading) {
            return (
                <div>
                    <LoadingSpinner />
                </div>
            );
        }

        if (data.hotelOfferSearch) {
            return <RegionPrice hotelOfferSearch={data.hotelOfferSearch} />;
        }

        return null;
    }
}

const gqlPackageWrapper = graphql<IInjectedProps, IQueryProps>(PackageSearchQ, {
    options: ({ formDataStore, regionUUID, contentfulStore }: IInjectedProps) => {
        const hotelFilter = contentfulStore.topic.topicConfiguration.hotelFilter;
        const packageSettings = formDataStore.packageForm;
        const departureDate = new Date(packageSettings.departureDate);
        const returnDate = new Date(packageSettings.returnDate);
        const duration = packageSettings.duration;
        const { minDays, maxDays } = getMinAndMax(departureDate, returnDate, duration);
        const { adults, children, boardType, transfer, maxP, hotelCategory, airport } = packageSettings;
        const formattedHotelFilter: string | undefined = hotelFilter ? createFacetsURL(hotelFilter) : undefined;
        const isAllInclusive = hotelFilter && hotelFilter.includes('ALL_INCLUSIVE');

        const variables = {
            regionUUID,
            children,
            adults,
            minDays,
            maxDays,
            depDateYear: departureDate.getFullYear(),
            depDateMonth: departureDate.getMonth() + 1,
            depDateDay: departureDate.getDate(),
            retDateYear: returnDate.getFullYear(),
            retDateMonth: returnDate.getMonth() + 1,
            retDateDay: returnDate.getDate(),
            priceRangeFrom: maxP ? 0 : undefined,
            priceRangeTo: maxP ? parseInt(maxP, 10) : undefined,
            transfer: transfer ? transfer : undefined,
            meals: isAllInclusive ? ['GT06-AI'] : boardType ? [boardType] : undefined,
            departureAirPorts: airport && airport.length ? airport : undefined,
            stars: hotelCategory ? 'gte:'+ hotelCategory : undefined,
            hotelFilter: formattedHotelFilter
        };

        return {
            variables
        };
    }
});

export default inject('formDataStore', 'contentfulStore')(observer(gqlPackageWrapper(PackageRegionPrice)));
