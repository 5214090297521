import React from 'react';
import { pathOr } from 'ramda';

interface IProps {
    isRoomSelectionActive: boolean;
    isValidSelection: boolean;
    numberOfRooms: number;
    adults: number;
    children: number[];
}
const hintForAdultsChildrenAndRoomCount = require('./textHint.json');

function getRoomSelectorHint(isValidSelection: boolean, numberOfRooms: number, adults: number, children: number[]) {
    if (isValidSelection) {
        const roomCount: number = numberOfRooms || 0;
        const adultsCount: number = adults || 0;
        const childCount: number = children ? children.length : 0;

        if (roomCount === 1 && adultsCount + childCount > 6) {
            return `1 Zimmer: Mit ${adultsCount + childCount} Personen, z.B. 1 Apartment`;
        }
        return pathOr('', [adultsCount, childCount, roomCount], hintForAdultsChildrenAndRoomCount);
    }
    return '';
}

export default ({ isRoomSelectionActive, isValidSelection, numberOfRooms, adults, children }: IProps) => {
    if (!isRoomSelectionActive) {
        return null;
    }

    return (
        <div className="room-selector-hint small">
            {getRoomSelectorHint(isValidSelection, numberOfRooms, adults, children)}
        </div>
    );
};
