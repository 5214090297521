import gql from 'graphql-tag';

import formData from './formData';
import formFields from './formFields';
import travelkindData from './travelkindData';
import interactiveFormData from './interactiveFormData';
import contentfulTopic from './contentfulTopic';

export default gql`
    ${formData}
    ${formFields}
    ${travelkindData}
    ${interactiveFormData}
    ${contentfulTopic}

    type GenericTexts {
        key: String
        value: String
    }

    type AllTopics {
        slug: String
    }

    type TravelDates {
        departureDate: String
        returnDate: String
    }

    type DefaultTravelDates {
        package: TravelDates
        hotelonly: TravelDates
    }

    type Query {
        formData: FormData
        interactiveFormData: FormData
        formFields: FormFields
        travelkindData: TravelkindData
        contentfulTopic: ContentfulTopic
        genericTexts: GenericTexts
        defaultTravelDates: DefaultTravelDates
        allTopics: AllTopics
    }
`;
