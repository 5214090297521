export default class User {
    public static COOKIENAME_OPTOUT_TRACKING = 'hc_tracking_opt_out';
    public static COOKIENAME_OPTIN_REDUCED_PRIVACY = 'cookieconsent_status';

    public static hasAllowedTracking(): boolean {
        return `${this.getCookie(User.COOKIENAME_OPTOUT_TRACKING)}` !== '1';
    }

    public static getCookie(cname: string): string {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        // tslint:disable-next-line prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    public static hasReducedPrivacy(): boolean {
        return `${this.getCookie(User.COOKIENAME_OPTIN_REDUCED_PRIVACY)}` === 'dismiss';
    }
}
