import * as React from 'react';
import { inject, observer } from 'mobx-react';

import track from 'src/helpers/tracking';
import slugify from 'src/helpers/slugify';
import {
    hasLocalStorageEntry,
    setOfferFiltersToStorage,
    setNormalizedOfferFiltersToStorage
} from 'src/helpers/localStorage';

import { routeSlug } from 'src/helpers/routeSlug';
import HotelonlyRegionPrice from './RegionPrice/HotelonlyRegionPrice';
import PackageRegionPrice from './RegionPrice/PackageRegionPrice';
import Temperatures from './Temperatures';
import { IRegion } from '../';
import { ContentfulStore } from 'src/stores/ContentfulStore';
import { FormDataStore } from 'src/stores/FormDataStore';

const routeableTopics = ['/pauschalreisen', '/fruehbucher', '/last-minute'];

interface IInjectedProps extends IInheritedPropsType {
    contentfulStore: ContentfulStore;
    formDataStore: FormDataStore;
}

interface IInheritedPropsType {
    region: IRegion;
    position: number;
}

const RegionTile = (props: IInheritedPropsType) => {
    const { contentfulStore, formDataStore } = props as IInjectedProps;

    const { slug } = contentfulStore.topic;

    const generateLink = (): string => {
        const {
            region: { name: regionName, uuid: regionUUID }
        } = props;

        if (
            routeableTopics.includes(slug as string) &&
            typeof localStorage !== 'undefined' && !hasLocalStorageEntry(localStorage)
        ) {
            return `/tp/${slugify((slug as string).replace('/', ''))}-${slugify(regionName)}/${regionUUID}`;
        }

        return `${routeSlug('/dh/', regionName)}/${regionUUID}`;
    };

    const trackClick = () => {
        setNormalizedOfferFiltersToStorage(localStorage, formDataStore.formData);
        setOfferFiltersToStorage(localStorage, { travelkind: formDataStore.travelkind });

        const {
            position,
            region: { name: regionName }
        } = props;

        track({
            eventCategory: 'topicDestinationTeaser',
            eventAction: 'listing',
            eventLabel: `${regionName}; ${position}`
        });
    };

    const {
        region: { uuid, name, url }
    } = props;

    const travelkind = formDataStore.formData.travelkind;
    const href = url || generateLink();

    return (
        <a className="region-tile" href={href} onClick={trackClick}>
            <div className="regionName">{name}</div>
            <div className="region-tile-details">
                <Temperatures regionUUID={uuid} />
                <div className="region-price">
                    {travelkind === 'hotel' && <HotelonlyRegionPrice regionUUID={uuid} />}
                    {travelkind === 'package' && <PackageRegionPrice regionUUID={uuid} />}
                </div>
            </div>
        </a>
    );
};

export default inject('contentfulStore', 'formDataStore')(observer(RegionTile));
