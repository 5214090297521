import * as React from 'react';

import './checkbox.scss';

import { T } from 'ramda';

interface IProps {
    label?: string;
    checked: boolean;
    name?: string;
    value: string;
    onClick: (value: string, checked: boolean) => void;
}

export default class Checkbox extends React.Component<IProps> {
    public handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { checked, value, onClick } = this.props;

        onClick(value, !checked);
    };

    public render() {
        const { label, checked, name, value } = this.props;

        return (
            <label className="checkbox-wrapper" onClick={this.handleClick}>
                {label}
                <input className="checkbox" type="checkbox" checked={checked} value={value} name={name} onChange={T} />
                <span className="checkmark" />
            </label>
        );
    }
}
