import gql from 'graphql-tag';

export default gql`
    type FormFieldOptions {
        text: String!
        value: String!
        minDays: Int
        maxDays: Int
        step: Int
    }

    type FormField {
        name: String!
        placeholder: String!
        options: [FormFieldOptions]!
    }

    type FormFields {
        boardType: FormField!
        duration: FormField!
        hotelCategory: FormField!
        transfer: FormField!
        maxP: FormField!
    }
`;
