import * as React from 'react';
import { inject, observer } from 'mobx-react';

import Select from 'src/common/Select';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

import { ISelection } from '../../types';
import categoryOptions from './data.json';
import { IStores } from 'src/helpers/store';

interface IInjectedProps {
    hotelCategory: string;
    setHotelCategory: InteractiveFormStore['setHotelCategory'];
    travelkind: string;
}

@inject(({ interactiveFormStore }: IStores) => {
    return {
        hotelCategory: interactiveFormStore.travelkindForm.hotelCategory,
        setHotelCategory: interactiveFormStore.setHotelCategory,
        travelkind: interactiveFormStore.travelkind
    };
})
@observer
class HotelCategory extends React.Component {
    get injected() {
        return this.props as IInjectedProps;
    }

    public saveHotelCategory = (selection: ISelection) => {
        this.injected.setHotelCategory(selection.value);
    };

    public render() {
        return (
            <Select
                eventCategory={`search_${this.injected.travelkind}_topic`}
                className="hotelCategory-select"
                onChange={this.saveHotelCategory}
                value={this.injected.hotelCategory}
                options={categoryOptions}
                name="hotelCategory"
            />
        );
    }
}

export default HotelCategory;
