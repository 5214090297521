import * as React from 'react';
import { inject, observer } from 'mobx-react';

import SectionHeadline from 'src/common/SectionHeadline';
import TravelkindTabs from 'src/sections/TravelSearchForm/formFields/TravelkindTabs';
import { ContentfulStore } from 'src/stores/ContentfulStore';

import Package from './Forms/Package';
import Hotel from './Forms/Hotel';

import './TravelSearchForm.scss';

import { FormDataStore } from 'src/stores/FormDataStore';

export type availableTravelkind = 'Package' | 'Hotel';
export type availableTravelkinds = availableTravelkind[];

interface IProps {
    contentfulStore: ContentfulStore;
    formDataStore: FormDataStore;
}

const TravelSearchForm = ({ contentfulStore, formDataStore }: IProps) => {
    const travelkind = formDataStore.travelkind;
    const contentfulTopic = contentfulStore.topic;
    const isPackageSelected = travelkind === 'package';

    return (
        <div className="travel-search-form-section">
            <SectionHeadline headline={contentfulTopic.headlines.travelSearchForm} cssClasses="sectionheadline" />
            <TravelkindTabs />
            {isPackageSelected ? <Package /> : <Hotel />}
        </div>
    );
};

export default inject('contentfulStore', 'formDataStore')(observer(TravelSearchForm));
