import queryString from 'query-string';
import { evolve } from 'ramda';
import dateFormat from 'dateformat';

const transformDate = (date: Date | string) => dateFormat(new Date(date), 'yyyy-mm-dd');

const transformQueryParams = evolve({
    departureDate: transformDate,
    returnDate: transformDate,
    rooms: () => undefined,
    travellers: () => undefined,
    suggestion: () => undefined
});

export default (formData: IPackage | IHotel, travelkind: ITravelkind) => {
    const query = queryString.stringify({
        ...transformQueryParams(formData),
        travelkind
    });

    return query.split('&').sort().join('&')
}