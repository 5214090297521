import React from 'react';
import { clone } from 'ramda';
import { inject, observer } from 'mobx-react';

import Dialog from 'src/common/Dialog';
import Input from 'src/common/Input';
import Button from 'src/common/Button';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

import AdultSelector from './AdultSelector';
import ChildrenSelector from './ChildrenSelector';
import RoomSelectorHint from './RoomSelectorHint';
import RoomSelector from './RoomSelector';
import Children, { Offset } from './Children';
import FooterMessages from './FooterMessages';
import validateTravellerSelection from './validateTravellerSelection';

import './styles/PaxDialog.scss';
import './styles/PaxControls.scss';
import { IStores } from 'src/helpers/store';
import track from 'src/helpers/tracking';

interface IInjectedProps {
    adults: number | '6+';
    children: number[];
    numberOfRooms: number;
    travelkind: string;
    setPaxData: InteractiveFormStore['setPaxData'];
}

interface IState {
    isOpen: boolean;
    isValidSelection: boolean;
    isRoomSelectionActive: boolean;
    moreThanSixAdults: boolean;
    adults: number | '6+';
    children: number[];
    numberOfRooms: number;
    travelkind: string;
}

@inject((stores: IStores) => {
    const { adults, children, numberOfRooms } = stores.interactiveFormStore.travelkindForm;

    return {
        adults,
        children,
        numberOfRooms,
        travelkind: stores.interactiveFormStore.travelkind,
        setPaxData: stores.interactiveFormStore.setPaxData
    };
})
@observer
class PaxDialog extends React.Component<{}, IState> {
    get injected() {
        return this.props as IInjectedProps;
    }

    constructor(props: IInjectedProps) {
        super(props);

        this.state = {
            adults: props.adults,
            children: props.children,
            numberOfRooms: props.numberOfRooms,
            travelkind: props.travelkind,
            isOpen: false,
            isValidSelection: true,
            moreThanSixAdults: false,
            isRoomSelectionActive: props.numberOfRooms > 1
        };
    }

    public componentWillReceiveProps(nextProps: IInjectedProps, nextState: IState) {
        let differs = false;
        for (const key of ['adults', 'children', 'numberOfRooms']) {
            // @ts-ignore
            if (nextState[key] !== nextProps[key]) differs = true;
        }
        if (differs) {
            this.setState({
                adults: nextProps.adults,
                children: nextProps.children,
                numberOfRooms: nextProps.numberOfRooms,
                travelkind: nextProps.travelkind,
                isRoomSelectionActive: nextProps.numberOfRooms > 1
            });
        }
    }

    public enableRoomSelection = () => {
        this.setState({ isRoomSelectionActive: true });
    };

    public changeAdults = (value: string) => {
        const { numberOfRooms, children } = this.state;
        const adults = parseInt(value) || 0;
        const moreThanSixAdults = value === '6+';
        const isValidSelection = !moreThanSixAdults && validateTravellerSelection(adults, children, numberOfRooms);

        this.setState(
            {
                moreThanSixAdults,
                isValidSelection,
                adults: moreThanSixAdults ? '6+' : adults
            },
            async () => {
                if (isValidSelection) {
                    this.injected.setPaxData(adults, children, numberOfRooms);
                }
            }
        );
    };

    public changeChildren = (value: string) => {
        const { children: previousChildren, numberOfRooms, adults } = this.state;
        const n = parseInt(value) || 0;
        const defaultAge = 1;

        let children: number[] = new Array(n);
        for (let i=0; i<n; ++i) {
            children[i] = typeof previousChildren[i] === 'number' ? previousChildren[i] : defaultAge;
        }

        const isValidSelection = validateTravellerSelection(adults as number, children, numberOfRooms);

        this.setState(
            {
                isValidSelection,
                children
            },
            () => {
                if (isValidSelection) {
                    this.injected.setPaxData(adults as number, children, numberOfRooms);
                }
            }
        );
    };

    public changeChildAge = (index: number, offset: Offset) => {
        const { adults, children, numberOfRooms } = this.state;

        const newChildren = clone(children);

        newChildren[index] = newChildren[index] + offset;

        const isValidSelection = validateTravellerSelection(adults as number, newChildren, numberOfRooms);

        this.setState(
            {
                isValidSelection,
                children: newChildren
            },
            () => {
                if (isValidSelection) {
                    this.injected.setPaxData(adults as number, newChildren, numberOfRooms);
                }
            }
        );
    };

    public changeRooms = (value: string) => {
        const { adults, children } = this.state;

        const numberOfRooms = parseInt(value) || 0;
        const isValidSelection = validateTravellerSelection(adults as number, children, numberOfRooms);

        this.setState(
            {
                isValidSelection,
                numberOfRooms,
                isRoomSelectionActive: numberOfRooms > 1
            },
            () => {
                if (isValidSelection) {
                    this.injected.setPaxData(adults as number, children, numberOfRooms);
                }
            }
        );
    };

    public onCloseHandler = () => {
        if (this.state.isOpen === false) return;
        this.setState({ isOpen: false });
        const { adults, children, numberOfRooms } = this.injected;

        track({
            eventCategory: `search_${this.injected.travelkind}_topic`,
            eventAction: 'traveller_picker_changed',
            eventLabel: `adults:${adults}; children: ${children.length ? `${children.length} [${children.join(',')}]` : '0'}; rooms:${numberOfRooms}`
        });
    };

    public onOpenHandle = () => {
        if (this.state.isOpen) {
            return;
        }
        this.setState({ isOpen: true });
    };

    public render() {
        const { adults, children, numberOfRooms } = this.injected;
        const { moreThanSixAdults, isOpen, isValidSelection } = this.state;

        return (
            <div className="pax-dialog-wrapper">
                <Input
                    readOnly={true}
                    value={
                        `${adults} Erw, ${children.length}` +
                        ` Kind${children.length === 1 ? '' : 'er'}` +
                        (numberOfRooms > 1 ? `, ${numberOfRooms} Zi.` : ``)
                    }
                    onClick={this.onOpenHandle}
                    className="pax-input"
                />
                <Dialog isOpen={isOpen} onCloseModal={this.onCloseHandler} overlayInvisible={true}>
                    <div className="pax-dialog title-cp-only">
                        <i className="arrow" />
                        <section className="dialog-header">
                            Reiseteilnehmer
                            <a className="close" onClick={this.onCloseHandler}>
                                ✕
                            </a>
                        </section>
                        <section className="dialog-main">
                            <div className="switches">
                                <AdultSelector changePaxData={this.changeAdults} adults={this.state.adults} />
                                <ChildrenSelector
                                    changePaxData={this.changeChildren}
                                    moreThanSixAdults={moreThanSixAdults}
                                    children={this.state.children}
                                />
                                {!moreThanSixAdults && this.state.children && (
                                    <Children children={this.state.children} changePaxData={this.changeChildAge} />
                                )}
                                <RoomSelector
                                    isRoomSelectionActive={this.state.isRoomSelectionActive}
                                    enableRoomSelection={this.enableRoomSelection}
                                    changePaxData={this.changeRooms}
                                    moreThanSixAdults={moreThanSixAdults}
                                    numberOfRooms={this.state.numberOfRooms}
                                />
                                <RoomSelectorHint
                                    isRoomSelectionActive={this.state.isRoomSelectionActive}
                                    isValidSelection={this.state.isValidSelection}
                                    numberOfRooms={this.state.numberOfRooms}
                                    adults={this.state.adults as number}
                                    children={this.state.children}
                                />
                            </div>
                            <FooterMessages
                                isValidSelection={this.state.isValidSelection}
                                moreThanSixAdults={moreThanSixAdults}
                            />
                        </section>
                        {!moreThanSixAdults && (
                            <section className="dialog-footer">
                                <Button onClick={this.onCloseHandler} disabled={!isValidSelection} type="primary">
                                    Übernehmen
                                </Button>
                            </section>
                        )}
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default PaxDialog;
