import * as React from 'react';
import { inject, observer } from 'mobx-react';

import Select from 'src/common/Select';

import { ISelection } from '../../types';

import options from './data.json';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { IStores } from 'src/helpers/store';

const allInclusiveOnlyOptions = [
    {
        text: 'All Inclusive',
        value: 'GT06-AI'
    }
];

interface IInjectedProps {
    travelkind: string;
    hotelFilter: IHotelFilter;
    boardType: string;
    setBoardType: InteractiveFormStore['setBoardType'];
}

export const BoardType = (props: {}) => {
    const { hotelFilter, boardType, setBoardType, travelkind } = props as IInjectedProps;

    const applyBoardType = (selection: ISelection) => {
        setBoardType(selection.value);
    };

    const getOptions = () => {
        const isAllInclusiveFilter = hotelFilter.includes('ALL_INCLUSIVE');

        return isAllInclusiveFilter ? allInclusiveOnlyOptions : options;
    };

    return (
        <Select
            eventCategory={`search_${travelkind}_topic`}
            className="boardtype-select"
            onChange={applyBoardType}
            value={boardType}
            options={getOptions()}
            name="boardType"
        />
    );
};

export default inject((stores: IStores) => {
    const { contentfulStore, interactiveFormStore } = stores;
    return {
        hotelFilter: contentfulStore.topic.topicConfiguration.hotelFilter || [],
        boardType: (interactiveFormStore.travelkindForm as IPackage).boardType,
        setBoardType: interactiveFormStore.setBoardType,
        travelkind: interactiveFormStore.travelkind
    };
})(observer(BoardType));
