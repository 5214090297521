import * as React from 'react';
import { T } from 'ramda';

import './radio.scss';

interface IProps {
    label: string;
    checked: boolean;
    name?: string;
    value: string;
    onClick: (value: string) => void;
    customCheckMarkClass?: string;
    className?: string;
    disabled?: boolean;
}

export default class Radio extends React.Component<IProps> {
    public handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { checked, value, onClick } = this.props;

        if (!checked) {
            onClick(value);
        }
    };

    public render() {
        const { label, checked, name, value, customCheckMarkClass, disabled } = this.props;

        return (
            <label className="radio-wrapper" onClick={this.handleClick}>
                <span className="label">{label}</span>
                <input
                    className="radio"
                    type="radio"
                    checked={checked}
                    value={value}
                    name={name}
                    onChange={T}
                    disabled={disabled}
                />
                <span className={customCheckMarkClass || 'checkmark'} />
            </label>
        );
    }
}
