import * as React from 'react';

import classNames from 'classnames';

interface IProps {
    type?: string;
    onClick?: () => void;
    className?: string;
    children?: any;
    disabled?: boolean;
    submit?: boolean;
}

export default ({ type = 'default', onClick, className, children, disabled, submit }: IProps) => {
    const iconClasses = classNames('btn', 'btn-block', {
        [`btn-${type}`]: Boolean(type),
        [`${className}`]: Boolean(className)
    });

    return (
        <button type={submit ? 'submit' : 'button'} className={iconClasses} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
