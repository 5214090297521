import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import Root from 'src/Root';
import { createClient } from 'src/graphql/apollo';
import { rehydrateStores } from 'src/helpers/store';

const client = createClient(false);

const history = require('history').createBrowserHistory();

const stores = rehydrateStores((window as any).__STORES__);

ReactDOM.hydrate(
    <ApolloProvider client={client}>
        <Router history={history}>
            <Provider {...stores}>
                <Root />
            </Provider>
        </Router>
    </ApolloProvider>,
    document.getElementById('TOP')
);
