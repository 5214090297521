import React, { Component } from 'react';
import { ChildProps, graphql } from 'react-apollo';

import Teaser from './Teaser';
import query from './query.gql';

import './teaserSection.scss';

export interface ITeaserType {
    url: string;
    slug: string;
    image: {
        title: string;
        description: string;
        url: string;
    };
}

interface ITopTopics {
    topics: ITeaserType[];
}

interface IAsyncData {
    topTopics?: ITopTopics[];
}

interface IPropsType {
    rank: string;
    className?: string;
    headline?: string;
}

interface IOperations {
    contentfulQuery: string;
}

export class TeaserSection extends Component<ChildProps<IPropsType, IAsyncData, IOperations>> {
    public render() {
        const data = this.props.data || null;

        if (!data || data.error || data.loading || !data.topTopics) {
            return null;
        }

        const teaserList = data.topTopics[0].topics;

        return !teaserList ? null : (
            <div className="teaser-section white-bg">
                {this.props.headline && <h2 className="sectionheadline">{this.props.headline}</h2>}
                <ul className="teasers-list">
                    {teaserList.map((teaser: ITeaserType, i: number) => {
                        return <Teaser key={teaser.slug} teaser={teaser} rank={this.props.rank} index={i} />;
                    })}
                </ul>
            </div>
        );
    }
}

const querySettings = {
    options: ({ rank }: IPropsType) => ({
        variables: {
            contentfulQuery: `fields.rank=${rank}`
        }
    })
};

export default graphql(query, querySettings)(TeaserSection);
