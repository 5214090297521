import * as React from 'react';
import classnames from 'classnames';

import './styles/CarouselDots.scss';

interface IProps {
    number: number;
    value: number;
    onChange: (number1: number) => void;
}

export default class CarouselDots extends React.Component<IProps> {
    public onChange = (index: number) => () => {
        const numberOfSlides = this.props.number;
        const moduloItem = this.calculateButtonValue() % numberOfSlides;

        return this.props.onChange(this.props.value - (moduloItem - index));
    };

    public calculateButtonValue = () => {
        const numberOfSlides = this.props.number;

        return this.props.value >= 0
            ? this.props.value
            : this.props.value + numberOfSlides * Math.ceil(Math.abs(this.props.value / numberOfSlides));
    };

    public renderCarouselDots() {
        const dots = [];

        for (let i = 0; i < this.props.number; i++) {
            dots.push(
                <li key={i}>
                    <div
                        className={classnames('Carousel__dot', {
                            'Carousel__dot--selected': i === this.calculateButtonValue() % this.props.number
                        })}
                        onClick={this.onChange(i)}
                    >
                        {i + 1}
                    </div>
                </li>
            );
        }
        return dots;
    }

    public render() {
        return <ul className="Carousel__dots">{this.renderCarouselDots()}</ul>;
    }
}
