export default (s: string) => {
    let hash = 0;
    if (s.length > 0) {
        const n = s.length;
        for (let i = 0; i < n; i++) {
            const chr = s.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
    }
    return hash;
};
