import React from 'react';
import Popover from 'src/common/Popover';
import LazyLoad from 'src/common/LazyLoad';

type TeaserDeviceType = 'mobile' | 'tablet' | 'desktop';

interface ITeaserImageType {
    url: string;
    title: TeaserDeviceType;
    description: string;
}

export interface ITeaserType {
    alt: string;
    url: string;
    code: string;
    type: string;
    title: string;
    image: ITeaserImageType[];
}

const mediaQueries = {
    tablet: '(min-width: 700px)',
    desktop: '(min-width: 1025px)'
};

class Teaser extends React.Component<{ teaser: ITeaserType }> {
    public state = {
        visible: false
    };

    public render() {
        const { image, url, alt, title } = this.props.teaser;
        const getImage = (device: string) => image.find(m => m.title === device) || image[0];
        const body = <div className="teaser-info-body">{title}</div>;

        return (
            <Popover body={body}>
                <a href={url} target="_blank">
                    <LazyLoad>
                        <picture className="picture teaser-image" itemType="http://schema.org/ImageObject">
                            <source srcSet={getImage('desktop').url} media={mediaQueries.desktop} />
                            <source srcSet={getImage('tablet').url} media={mediaQueries.tablet} />
                            <img src={getImage('mobile').url} className="teaser-image" itemProp="contentUrl" alt={alt} />
                        </picture>
                    </LazyLoad>
                </a>
            </Popover>
        );
    }
}

export default Teaser;
