import * as React from 'react';
import { path } from 'ramda';
import debounce from 'lodash.debounce';
import classnames from 'classnames';

import isServerside from 'src/helpers/isServerside';

import CarouselItem from './CarouselItem';
import Dots from './CarouselDots';

import './styles/Carousel.scss';
import './styles/Arrows.scss';

interface IBreakPoint {
    centered?: boolean;
    draggable?: boolean;
    animationSpeed?: number;
    className?: string;
    offset?: number;
    itemWidth?: number;
}

interface IBreakPoints {
    [widht: number]: IBreakPoint;
}

interface ICarouselProps extends IBreakPoint {
    value: number;
    onChange: (p: number | number | any) => void;
    children: any[];
    breakpoints?: IBreakPoints;
    onDotClick: (value: number, slidesPerPage: number) => void;
}

interface IState {
    carouselWidth: number;
    dragOffset: number;
    dragStart: number | null;
    transitionEnabled: boolean;
    clicked: number | null;
}

const config = {
    resizeEventListenerThrottle: 200, // event listener onResize will not be triggered more frequently than once per given number of miliseconds
    clickDragThreshold: 10
};

export const getPropFromBreakPoints = (propName: string, props: ICarouselProps) => {
    let activeBreakpoint: number | null = null;

    if (props.breakpoints && !isServerside()) {
        const resolutions = Object.keys(props.breakpoints);

        resolutions.forEach(resolutionString => {
            const resolution = parseInt(resolutionString, 10);

            if (window.innerWidth <= resolution) {
                if (!activeBreakpoint || activeBreakpoint > resolution) {
                    activeBreakpoint = resolution;
                }
            }
        });
    }

    if (activeBreakpoint) {
        // @ts-ignore
        if (path([propName], props.breakpoints[activeBreakpoint])) {
            // @ts-ignore
            return props.breakpoints[activeBreakpoint][propName];
        }
    }

    // @ts-ignore
    return props[propName];
};

export default class CarouselSlider extends React.Component<ICarouselProps, IState> {
    public static defaultProps = {
        offset: 0,
        slidesPerPage: 1,
        draggable: true
    };

    private readonly node: React.RefObject<any>;
    private readonly trackRef: React.RefObject<any>;

    constructor(props: ICarouselProps) {
        super(props);

        this.node = React.createRef();
        this.trackRef = React.createRef();

        this.onResize = debounce(this.onResize, config.resizeEventListenerThrottle);

        this.state = {
            carouselWidth: 972,
            dragOffset: 0,
            clicked: null,
            dragStart: null,
            transitionEnabled: false
        };
    }

    public onResize = () => {
        if (this.node.current && this.node.current.offsetWidth) {
            this.setState({
                carouselWidth: this.node.current.offsetWidth
            });
        }
    };

    public componentDidMount() {
        if (this.trackRef) {
            this.trackRef.current.addEventListener('transitionend', this.onTransitionEnd);
        }

        if (this.node) {
            this.node.current.addEventListener('touchstart', this.onTouchStart);
            this.node.current.addEventListener('touchmove', this.onTouchMove);
            this.node.current.addEventListener('touchend', this.onTouchEnd);
        }

        window.addEventListener('resize', this.onResize);

        this.onResize();
    }

    public componentWillReceiveProps(nextProps: ICarouselProps) {
        const valueChanged = this.checkIfValueChanged(nextProps);

        // in case we have some skeletons loading we assume it has another length of items then current ones
        if (this.props.children.length !== nextProps.children.length) {
            return this.props.onChange(0);
        }

        if (valueChanged) {
            this.setState({
                transitionEnabled: true
            });
        }
    }

    public componentWillUnmount() {
        if (this.trackRef) {
            this.trackRef.current.removeEventListener('transitionend', this.onTransitionEnd);
        }

        if (this.node) {
            this.node.current.removeEventListener('touchstart', this.onTouchStart);
            this.node.current.removeEventListener('touchmove', this.onTouchMove);
            this.node.current.removeEventListener('touchend', this.onTouchEnd);
        }

        window.removeEventListener('resize', this.onResize);
    }

    public getNeededAdditionalClones = () => Math.ceil(this.props.value / this.props.children.length);

    public getAdditionalClonesLeft = () => {
        const additionalClones = this.getNeededAdditionalClones();
        return additionalClones < 0 ? -additionalClones : 0;
    };

    public getAdditionalClonesOffset = () =>
        -this.props.children.length * this.getCarouselElementWidth() * this.getAdditionalClonesLeft();

    public getProp = (propName: string, p: any = undefined): any => {
        return getPropFromBreakPoints(propName, p || this.props);
    };

    public checkIfValueChanged = (prevProps: ICarouselProps): boolean => {
        const currentValue = this.clamp(this.props.value);
        const prevValue = this.clamp(prevProps.value);

        return currentValue !== prevValue;
    };

    public onTouchStart = (e: React.TouchEvent, index: number) => {
        if (e.type === 'touchstart') {
            this.setState({
                clicked: index,
                dragStart: e.touches[0].clientX
            });
        }
    };

    public onTouchMove = (e: React.TouchEvent) => {
        if (this.state.dragStart && e.type === 'touchmove') {
            this.setState({
                dragOffset: e.touches[0].clientX - this.state.dragStart
            });
        }
    };

    public onTouchEnd = () => {
        if (this.state.dragOffset) {
            if (this.getProp('draggable')) {
                if (Math.abs(this.state.dragOffset) > config.clickDragThreshold) {
                    this.changeSlide(this.getNearestSlideIndex());
                }
            }
            this.setState({
                dragOffset: 0,
                dragStart: null,
                clicked: null
            });
        }
    };

    public onTransitionEnd = () => {
        this.setState({
            transitionEnabled: false
        });
    };

    public clamp = (value: number) => {
        const maxValue = this.props.children.length - 1;

        if (value > maxValue) {
            return maxValue;
        }
        if (value < 0) {
            return 0;
        }
        return value;
    };

    public changeSlide = (value: any) => this.props.onChange(this.clamp(value));

    public nextSlide = () => this.changeSlide(this.props.value + this.getSliderPerPage());

    public prevSlide = () => this.changeSlide(this.props.value - this.getSliderPerPage());

    public getNearestSlideIndex = () => {
        return this.props.value - Math.round(this.state.dragOffset / this.getCarouselElementWidth());
    };

    public getCurrentSlideIndex = () => {
        return this.clamp(this.props.value);
    };

    public getSliderPerPage = (): number => {
        const offset = this.getProp('offset');
        const itemWidth = this.getCarouselElementWidth();

        return Math.floor(this.state.carouselWidth / (itemWidth + offset));
    };

    public getCarouselElementWidth = () => this.props.itemWidth || this.state.carouselWidth / this.getSliderPerPage();

    public onDotClick = (value: number) => this.props.onDotClick(value, this.getSliderPerPage());

    public getTransformOffset = () => {
        const offset = this.getProp('offset');
        const itemWidth = this.getCarouselElementWidth();
        const elementWidthWithOffset = itemWidth + offset;
        const carouselWidth = this.state.carouselWidth;

        const additionalOffset = this.getProp('centered')
            ? carouselWidth / 2 - elementWidthWithOffset / 2
            : itemWidth
            ? (carouselWidth - this.getSliderPerPage() * (this.getCarouselElementWidth() + offset)) / 2
            : 0;

        const dragOffset = this.getProp('draggable') ? this.state.dragOffset : 0;
        const currentValue = this.getCurrentSlideIndex();
        const additionalClonesOffset = this.getAdditionalClonesOffset();

        return dragOffset - currentValue * elementWidthWithOffset + additionalOffset - additionalClonesOffset;
    };

    public isInsideRange = (index: number): boolean => {
        const factor = this.getSliderPerPage();
        const currentSlide = this.getCurrentSlideIndex();

        return index >= currentSlide && currentSlide + factor - 1 >= index;
    };

    public renderCarouselItems = () => {
        const { children, draggable, offset } = this.props;
        const transformOffset = this.getTransformOffset();

        const trackLengthMultiplier = 1;
        const trackWidth = this.state.carouselWidth * children.length * trackLengthMultiplier;
        const animationSpeed = this.getProp('animationSpeed');
        const transitionEnabled = this.state.transitionEnabled;
        const isDraggable = draggable && children && children.length > 1;

        const trackStyles = {
            marginLeft: `${this.getAdditionalClonesOffset()}px`,
            width: `${trackWidth}px`,
            transform: `translateX(${transformOffset}px)`,
            transitionDuration: transitionEnabled ? `${animationSpeed}ms, ${animationSpeed}ms` : undefined
        };

        return (
            <div className="Carousel__trackContainer">
                <ul
                    className={classnames('Carousel__track', {
                        'Carousel__track--transition': transitionEnabled,
                        'Carousel__track--draggable': isDraggable
                    })}
                    style={trackStyles}
                    ref={this.trackRef}
                >
                    {children.map((carouselItem, index) => (
                        <CarouselItem
                            key={index}
                            insideRange={this.isInsideRange(index)}
                            currentSlideIndex={this.getCurrentSlideIndex()}
                            index={index}
                            width={this.getCarouselElementWidth()}
                            offset={index !== children.length ? offset || 0 : 0}
                            onTouchStart={this.onTouchStart}
                        >
                            {carouselItem}
                        </CarouselItem>
                    ))}
                </ul>
            </div>
        );
    };

    public renderArrowLeft = () => {
        return (
            <button className="Carousel__arrows Carousel__arrowLeft" onClick={this.prevSlide}>
                <span className="button-placeholder">prev</span>
            </button>
        );
    };

    public renderArrowRight = () => {
        return (
            <button className="Carousel__arrows Carousel__arrowRight" onClick={this.nextSlide}>
                <span className="button-placeholder">next</span>
            </button>
        );
    };

    public render() {
        const slidesPerPage = this.getSliderPerPage();
        const calcDots = Math.ceil(this.props.children.length / slidesPerPage);

        return (
            <>
                <div className={classnames('Carousel', this.props.className)} ref={this.node}>
                    {this.props.value !== 0 && this.renderArrowLeft()}
                    {this.renderCarouselItems()}
                    {this.props.value < this.props.children.length - 1 && this.renderArrowRight()}
                </div>
                <Dots
                    value={Math.ceil(this.props.value / slidesPerPage)}
                    onChange={this.onDotClick}
                    number={calcDots}
                />
            </>
        );
    }
}
