import { anyPass, both, curry, defaultTo, gt, lt, pipe, prop, toLower, trim } from 'ramda';

const characterMap: IHashMap = {
    ' ': '-',
    '£': 'pfund',
    '¥': 'yen',
    ÿ: 'y',
    '¹': '1',
    '²': '2',
    '³': '3',
    º: '2',
    Þ: 'y',
    ß: 'ss',
    à: 'a',

    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'ae',
    å: 'a',
    æ: 'ae',
    ç: 'c',
    ć: 'c',
    è: 'e',
    é: 'e',

    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ð: 'd',
    ñ: 'n',
    ò: 'o',
    ó: 'o',

    ô: 'o',
    õ: 'o',
    ö: 'oe',
    ø: 'oe',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'ue',
    ý: 'y',
    þ: 'y',

    ĕ: 'e',
    ė: 'e',
    ę: 'e',
    ě: 'e',
    ĝ: 'g',
    ğ: 'g',
    ġ: 'g',
    ģ: 'g',
    ĥ: 'h',
    ħ: 'h',

    ĩ: 'i',
    ī: 'i',
    ĭ: 'i',
    į: 'i',
    ị: 'i',
    ı: 'i',
    ĳ: 'ij',
    ĵ: 'j',
    ķ: 'k',
    ĸ: 'k',
    ĺ: 'l',

    ļ: 'l',
    ľ: 'l',
    ŀ: 'l',
    ł: 'l',
    ń: 'n',
    ņ: 'n',
    ň: 'n',
    ŉ: 'n',
    ŋ: 'n',
    ō: 'o',

    ŏ: 'o',
    ő: 'o',
    œ: 'oe',
    ŕ: 'r',
    ŗ: 'r',
    ř: 'r',
    ś: 's',
    ŝ: 's',
    ş: 's',
    š: 's',

    ţ: 't',
    ť: 't',
    ŧ: 't',
    ũ: 'u',
    ū: 'u',
    ŭ: 'u',
    ů: 'u',
    ű: 'u',
    ų: 'u',
    ŵ: 'w',

    Ŷ: 'y',
    ŷ: 'y',
    ź: 'z',
    ż: 'z',
    ž: 'z',
    ſ: 's',

    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',

    д: 'd',
    е: 'e',
    ё: 'jo',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'j',
    к: 'k',
    л: 'l',
    м: 'm',

    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',

    ч: 'n',
    ш: 'sh',
    щ: 'shch',
    ъ: '',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'ju',
    я: 'ja'
};

const isNotAlphanumericAsciiCharacter = anyPass([
    gt(48),
    both(lt(57), gt(65)),
    both(lt(90), gt(97)),
    both(lt(122), gt(128))
]);

const replaceCharacter = (match: string): string => {
    if (isNotAlphanumericAsciiCharacter(match.charCodeAt(0))) {
        return '-';
    }
    if (match in characterMap) {
        return prop(match, characterMap);
    }
    return match;
};

// Have to copy the ramda function, because the implementation for str.replace (es6) are in ramda wrong.
// @see https://github.com/ramda/ramda/issues/2149
const curryReplace = curry((searchValue: string | RegExp, replacement: any, value: string) => {
    return value.replace(searchValue, replacement);
});

export default pipe(
    // @ts-ignore
    defaultTo(''),
    trim,
    toLower,
    curryReplace(/\n|./g, replaceCharacter),
    curryReplace(/-{2,}/g, '-'),
    curryReplace(/-$/g, '')
);
