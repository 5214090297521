import * as React from 'react';

import DestinationInformationContent from './DestinationInformationContent';

import './destinationInformation.scss';

import { ContentfulStore } from 'src/stores/ContentfulStore';
import { inject } from 'mobx-react';

interface IProps {
    contentfulStore: ContentfulStore;
}

export const DestinationInformation = ({ contentfulStore }: IProps) => {
    const contentfulTopic = contentfulStore.topic;

    const {
        destinationInformationConfiguration,
        headlines: { destinationInformationTitle }
    } = contentfulTopic;

    return destinationInformationConfiguration ? (
        <section className="destination-information-section">
            <h2 className="sectionheadline destination-information-headline">{destinationInformationTitle}</h2>
            <DestinationInformationContent items={destinationInformationConfiguration} />
        </section>
    ) : null;
};

export default inject('contentfulStore')(DestinationInformation);
