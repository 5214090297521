import React from 'react';

import { ISuggestionType } from 'src/helpers/autoSuggestUris';

import { ISuggestItem } from './utils/types';
import SuggestItem from './SuggestItem';

interface IProps {
    isOpen: boolean;
    items: ISuggestItem[];
    handleSelect: (item: ISuggestionType) => void;
}

interface IState {
    highlightedIndex: number;
}

export default class SuggestionMenu extends React.Component<IProps, IState> {
    public state = {
        highlightedIndex: 0
    };

    public componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeys);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeys);
    }

    public renderItem = (item: ISuggestItem, index: number) => {
        return (
            <SuggestItem
                item={item}
                key={JSON.stringify(item)}
                onClick={this.props.handleSelect}
                isHighlighted={index === this.state.highlightedIndex}
            />
        );
    };

    public render() {
        const { isOpen, items } = this.props;

        return (
            <div className="suggestion-search-menu-container">
                <div className="suggestion-search-menu-inner">{isOpen && items.map(this.renderItem)}</div>
            </div>
        );
    }

    private handleKeys = (e: { code: string }) => {
        const { highlightedIndex } = this.state;
        const { isOpen, items } = this.props;

        if (isOpen) {
            switch (e.code) {
                case 'ArrowUp':
                    this.setState({ highlightedIndex: highlightedIndex - 1 });
                    break;
                case 'ArrowDown':
                    this.setState({ highlightedIndex: highlightedIndex + 1 });
                    break;
                case 'Enter':
                    this.props.handleSelect(items[highlightedIndex] as ISuggestionType);
                    break;
            }
        }
    };
}
