import { assocPath, mergeRight, partialRight, pipe, compose } from 'ramda';
import { RouteComponentProps } from 'react-router';
import * as dateMath from 'date-arithmetic';

import overrideDatesOutsideInterval from './overrideDatesOutsideInterval';
import overrideMismatchingDuration from './overrideMismatchingDuration';
import { getCurrentSlug } from 'src/helpers/getCurrentSlug';
import { isDateInRange } from 'src/helpers/dates';

const ensureCorrectTravelkind = (payload: IFormData, travelkind: string[] | string): IFormData => {
    if (travelkind && travelkind.length === 1) {
        return mergeRight(payload, {
            availableTravelkinds: travelkind,
            travelkind: travelkind[0].toLowerCase()
        });
    }
    return mergeRight(payload, { availableTravelkinds: travelkind });
};

const isConfigurationValid = (daysUntilDeparture: number, daysUntilReturn: number): boolean => {
    return (
        Number.isInteger(daysUntilDeparture) &&
        Number.isInteger(daysUntilReturn) &&
        daysUntilDeparture <= daysUntilReturn
    );
};

const isInvalidDate = (departureDate: Date, returnDate: Date) => (d: ITravelkindDate) => {
    return !isDateInRange(new Date(d), departureDate, returnDate);
};

const ensureTopicDate = (
    topicDepartureDate: Date,
    topicReturnDate: Date,
    slug: string,
    router: RouteComponentProps
) => (data: IFormData) => {
    const isInvalidTopicDate = isInvalidDate(topicDepartureDate, topicReturnDate);

    if (
        getCurrentSlug(router) === slug &&
        (isInvalidTopicDate(data.package.departureDate) || isInvalidTopicDate(data.package.returnDate))
    ) {
        return compose(
            assocPath(['package', 'departureDate'], topicDepartureDate),
            assocPath(['package', 'returnDate'], topicReturnDate)
        )(data) as IFormData;
    }
    return data;
};

// todo auto-check if date is smaler than NOW and use next year
const today = new Date();
export const earlybirdDepartureDate = dateMath.add(today, 90, 'day');
export const earlybirdReturnDate = dateMath.add(today, 200, 'day');
export const lastMinuteDepartureDate = dateMath.add(today, 2, 'day');
export const lastMinuteReturnDate = dateMath.add(today, 40, 'day');
export const earlybirdSlug = '/fruehbucher';
export const lastMinutSlug = '/last-minute';

export default (formData: IFormData, topicConfiguration: ITopicConfiguration, router: RouteComponentProps) => {
    const travelkind = topicConfiguration.travelkind || 'package';
    const packageDaysUntilDeparture = topicConfiguration.packageDaysUntilDeparture;
    const packageDaysUntilReturn = topicConfiguration.packageDaysUntilReturn;
    const hotelDaysUntilDeparture = topicConfiguration.hotelDaysUntilDeparture;
    const hotelDaysUntilReturn = topicConfiguration.hotelDaysUntilReturn;
    const hotelFilter = topicConfiguration.hotelFilter;

    const ensureCorrectPackageConfiguration = (data: IFormData) => {
        if (isConfigurationValid(packageDaysUntilDeparture, packageDaysUntilReturn)) {
            return overrideDatesOutsideInterval(packageDaysUntilDeparture, packageDaysUntilReturn, data, 'package');
        }
        return data;
    };

    const ensureCorrectHotelConfiguration = (data: IFormData) => {
        if (isConfigurationValid(hotelDaysUntilDeparture, hotelDaysUntilReturn)) {
            return overrideDatesOutsideInterval(hotelDaysUntilDeparture, hotelDaysUntilReturn, data, 'hotel');
        }
        return data;
    };

    const ensureCorrectBoardType = (data: IFormData, filter: IHotelFilter) => {
        if (filter && filter.includes('ALL_INCLUSIVE')) {
            return assocPath(['package', 'boardType'], 'GT06-AI', data);
        }
        return data;
    };

    const ensureLastMinuteDate = ensureTopicDate(lastMinuteDepartureDate, lastMinuteReturnDate, lastMinutSlug, router);

    const ensureEarlybirdDate = ensureTopicDate(earlybirdDepartureDate, earlybirdReturnDate, earlybirdSlug, router);

    return pipe(
        (data: IFormData) => ensureCorrectTravelkind(data, travelkind),
        ensureCorrectPackageConfiguration,
        ensureCorrectHotelConfiguration,
        partialRight(overrideMismatchingDuration, [topicConfiguration.duration]),
        partialRight(ensureCorrectBoardType, [hotelFilter]),
        ensureEarlybirdDate,
        ensureLastMinuteDate
    )(formData);
};
