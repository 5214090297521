import React, { useState, useEffect } from 'react';

export interface FragmentProps {
    id: string;
    url: string;
    assetsPath?: string;
    ssi?: 'nginx';
    maxHeight?: number;
    minHeight?: number;
    backgroundColor?: string;
}

export const RequestReadyState = {
    ERROR: -1,
    UNSENT: 0,
    OPENED: 1,
    HEADERS_RECEIVED: 2,
    LOADING: 3,
    DONE: 4
};

export const useRequest = (url: string, ssi: string) => {
    const [data, setData] = useState({
        readyState: RequestReadyState.UNSENT,
        error: null,
        responseText: ''
    });

    let cached: any = {};
    const update = (partialData: any) => setData({ ...data, ...partialData });

    useEffect(() => {
        if (ssi === 'nginx') {
            update({
                readyState: RequestReadyState.DONE,
                responseText: `<!--#include virtual="${url}" -->`
            });
            return;
        }

        update({
            readyState: RequestReadyState.LOADING
        });

        if (!cached[url]) {
            fetch(url)
                .then(response => response.text())
                .then(responseText => {
                    const REGEX_DOC_DECLARATION = /<(\?xml|\!doctype)([^>]*)>/gi;
                    const REGEX_HEAD = /<(head)([^>]*)>/gi;
                    const REGEX_CONTAINERS = /<(html|body)([^>]*)>/gi;
                    const REGEX_META = /<\/?(meta|title)([^>]*)>/gi;
                    const REGEX_CLOSING_TAGS = /<\/(html|head|body)([^>]*)>/gi;

                    update({
                        readyState: RequestReadyState.DONE,
                        responseText: cached[url] = responseText
                            .replace(REGEX_DOC_DECLARATION, '')
                            .replace(REGEX_HEAD, '<div data-fragment="$1" style="display: none;"$2>')
                            .replace(REGEX_CONTAINERS, '<div data-fragment="$1"$2>')
                            .replace(REGEX_META, '')
                            .replace(REGEX_CLOSING_TAGS, '</div>')
                    });
                })
                .catch(error => {
                    update({
                        readyState: RequestReadyState.ERROR,
                        error
                    });
                });
        } else {
            update({
                readyState: RequestReadyState.DONE,
                responseText: cached[url]
            });
        }
    }, []);

    return data;
};

const Fragment = (props: FragmentProps) => {
    const assetsPath = props.assetsPath || CFG.WWW;
    const url = props.url.indexOf('://') > -1 ? props.url : `${CFG.WWW}${props.url}`;
    const { id, ssi, maxHeight = 0, minHeight = 0, backgroundColor = '' } = props;
    const { readyState, error = null, responseText = '' } = useRequest(url, String(ssi));

    const style: JSX.IntrinsicElements['div']['style'] | undefined = {};
    if (backgroundColor) style.backgroundColor = backgroundColor;
    if (maxHeight) style.maxHeight = maxHeight;
    if (minHeight) style.minHeight = minHeight;

    let className = '';
    let __html = responseText;
    switch (readyState) {
        case RequestReadyState.ERROR:
            console.warn(id, 'fragment loading error', url, { error });
            className = 'loading-error';
            __html = '';
            break;
        case RequestReadyState.DONE:
            className = 'loading-done';
            break;
        default:
            className = 'loading';
            break;
    }

    useEffect(() => {
        if (!ssi && readyState === RequestReadyState.DONE) {
            const document = global && global.document;
            if (document) {
                const containerElement = document.getElementById(id);
                const shadowScripts = containerElement ? containerElement.getElementsByTagName('script') : null;
                if (shadowScripts && shadowScripts.length) {
                    [...Array.from(shadowScripts)].forEach(({ src, textContent }, index) => {
                        const scriptId = `${id}_script_${index}`;
                        if (!document.getElementById(scriptId)) {
                            const script = document.createElement('script');
                            script.id = scriptId;
                            script.type = 'text/javascript';
                            script.className = 'fragment-script';

                            const url = new URL(src, 'https://www.holidaycheck.de'); //temp
                            const fullSrc = `${assetsPath}${url.pathname}${url.search}`;

                            if (src) {
                                script.src = fullSrc;
                            } else if (textContent) {
                                script.textContent = textContent;
                            }

                            document.body.appendChild(script);
                        }
                    });
                }
            }
        }
    });

    return <div id={id} style={style} className={className} dangerouslySetInnerHTML={{ __html }} />;
};

export default Fragment;
