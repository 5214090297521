import React, { Component } from 'react';
import classnames from 'classnames';

import './Dialog.scss';
import Icon from '../Icon';

interface IProps {
    isOpen: boolean;
    cssClass?: string;
    onCloseModal?: () => void;
    overlayInvisible?: boolean;
}

interface IState {
    isOpen: boolean;
}

class Dialog extends Component<IProps, IState> {
    public state: IState = {
        isOpen: this.props.isOpen
    };

    public handleEscapeKey = (e: KeyboardEvent) => {
        if (e.keyCode === 27 && this.props.onCloseModal) {
            this.props.onCloseModal();
        }
    };

    public componentDidMount() {
        window.addEventListener('keydown', this.handleEscapeKey);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscapeKey);
    }

    public render() {
        const { cssClass, isOpen, onCloseModal, overlayInvisible } = this.props;

        const classes = classnames('Dialog', {
            [cssClass || '']: cssClass,
            hidden: !isOpen
        });

        return (
            <div className={classes}>
                <div className={`${overlayInvisible ? 'overlayInvisible' : 'overlay'}`} onClick={onCloseModal} />
                <div className="dialog-inner">
                    {this.props.children}
                    <Icon icon="arrow" />
                </div>
            </div>
        );
    }
}

export default Dialog;
