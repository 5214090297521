import { assocPath, path } from 'ramda';
import { isDefined } from 'src/helpers/predicates';
import { hasLocalStorageEntry } from 'src/helpers/localStorage';
import isServerside from 'src/helpers/isServerside';

export default (formData: IFormData, topicConfigurationDuration: string) => {
    const departureDate: Date | undefined = path(['package', 'departureDate'], formData);
    const returnDate: Date | undefined = path(['package', 'returnDate'], formData);
    const isDurationDefined = isDefined(topicConfigurationDuration);

    if (!isDurationDefined || !departureDate || !returnDate) {
        return formData;
    }

    if (!isServerside() && !hasLocalStorageEntry(window.localStorage)) {
        return assocPath(['package', 'duration'], topicConfigurationDuration, formData);
    }

    return formData;
};
