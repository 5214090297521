import React from 'react';
import className from 'classnames';

import Radio from 'src/common/Radio';
import Icon from 'src/common/Icon';

interface IProps {
    isRoomSelectionActive: boolean;
    enableRoomSelection: () => void;
    changePaxData: (roomCount: string) => void;
    moreThanSixAdults: boolean;
    numberOfRooms: number;
}

export default ({
    isRoomSelectionActive,
    enableRoomSelection,
    changePaxData,
    moreThanSixAdults,
    numberOfRooms
}: IProps) => {
    if (!isRoomSelectionActive) {
        return (
            <div onClick={enableRoomSelection} className="room-selector-activator">
                <Icon icon="plus" />
                Zimmer hinzufügen
            </div>
        );
    }

    return (
        <div className={className('room-selector', { disabled: moreThanSixAdults })}>
            <label className="top-label">Zimmer</label>
            <div className="selectors">
                <Radio
                    value="1"
                    name="numberOfRooms"
                    checked={numberOfRooms === 1}
                    label="1"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
                <Radio
                    value="2"
                    name="numberOfRooms"
                    checked={numberOfRooms === 2}
                    label="2"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
                <Radio
                    value="3"
                    name="numberOfRooms"
                    checked={numberOfRooms === 3}
                    label="3"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
                <Radio
                    value="4"
                    name="numberOfRooms"
                    checked={numberOfRooms === 4}
                    label="4"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
                <Radio
                    value="5"
                    name="numberOfRooms"
                    checked={numberOfRooms === 5}
                    label="5"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
                <Radio
                    value="6"
                    name="numberOfRooms"
                    checked={numberOfRooms === 6}
                    label="6"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                    disabled={moreThanSixAdults}
                />
            </div>
        </div>
    );
};
