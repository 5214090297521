import * as React from 'react';
import { inject, observer } from 'mobx-react';

import Select from 'src/common/Select';
import { findFormattedDuration, getValueFromFormatted } from 'src/helpers/duration';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

import { ISelection } from '../../types';

export interface IDuration {
    text: string;
    value: string;
    minDays: number;
    maxDays: number;
    valueFormattedForOfferService: string;
}

interface IInjectedProps {
    interactiveFormStore: InteractiveFormStore;
}

@inject('interactiveFormStore')
@observer
class Duration extends React.Component {
    get injected() {
        return this.props as IInjectedProps;
    }

    public saveDuration = (selection: ISelection) => {  
        this.injected.interactiveFormStore.setDuration(findFormattedDuration(selection.value));
    }

    public render() {
        const { interactiveFormStore } = this.injected;

        return (
            <Select
                eventCategory={`search_${interactiveFormStore.travelkind}_topic`}
                className="duration-select"
                onChange={this.saveDuration}
                value={getValueFromFormatted((interactiveFormStore.travelkindForm as IPackage).duration)}
                options={interactiveFormStore.durationOptions}
                name="duration"
            />
        );
    }
}

export default Duration;
