import React from 'react';
import Button from 'src/common/Button';

interface IProps {
    closeModal: (event: any) => void;
    to?: Date;
    calendarLegend?: string;
}

export default ({ closeModal, to, calendarLegend }: IProps) => {
    if (!to) return null;

    const close = (event?: any) => {
        closeModal(event);
    };

    return (
        <div className="footer">
            <Button type="primary" onClick={close}>
                Übernehmen
            </Button>
            {calendarLegend && <span className="datepicker-legend">{calendarLegend}</span>}
        </div>
    );
};
