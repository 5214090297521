export interface IGeneralTextType {
    key: string;
    value: string;
}

const getValueFromGeneralTexts = (key: string, generalTexts: IGeneralTextType[]): string | undefined => {
    const filteredGeneralTexts = generalTexts.filter(generalText => generalText.key === key);

    if (filteredGeneralTexts[0]) {
        return filteredGeneralTexts[0].value;
    }
    return undefined;
};

export default getValueFromGeneralTexts;
