import React from 'react';
import { cond } from 'ramda';

import { isNilOrEmpty } from 'src/helpers/predicates';
import Icon from 'src/common/Icon';

type ITemperature = number | null;

interface IAirTemperatureProps {
    minTemperature: ITemperature;
    maxTemperature: ITemperature;
    iconType: string;
    className?: string;
}

const hasEqualsTemperatures = (minT: ITemperature, maxT: ITemperature) => minT === maxT;
const hasDifferentTemperatures = (minT: ITemperature, maxT: ITemperature) => !hasEqualsTemperatures(minT, maxT);

const renderSingleTemperature = (_minT: any, maxT: ITemperature) => `${maxT}°C`;
const renderTemperatureRange = (minT: ITemperature, maxT: ITemperature) => `${minT}°C - ${maxT}°C`;

const renderTermperatures = cond([
    [hasEqualsTemperatures, renderSingleTemperature],
    [hasDifferentTemperatures, renderTemperatureRange]
]);

const Temperature = (props: IAirTemperatureProps) => {
    const { minTemperature, maxTemperature, iconType, className } = props;

    if (isNilOrEmpty(minTemperature) && isNilOrEmpty(maxTemperature)) {
        return <div className="temperature" />;
    }
    return (
        <div className="temperature">
            <Icon icon={iconType} className={className || iconType} />
            {renderTermperatures(minTemperature, maxTemperature)}
        </div>
    );
};

export default Temperature;
