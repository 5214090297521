import * as React from 'react';
import { path } from 'ramda';
import { inject } from 'mobx-react';

import { IBrandedTeaser } from 'src/sections/BrandedTeaser/types';
import { ContentfulStore } from 'src/stores/ContentfulStore';

import BrandedTeaserTile from './BrandedTeaserTile';

import './BrandedTeaser.scss';
import { FormDataStore } from 'src/stores/FormDataStore';

interface IProps {
    formDataStore: FormDataStore;
    contentfulStore: ContentfulStore;
}

export function BrandedTeaser(props: IProps) {
    const brandedTeasers: IBrandedTeaser[] | undefined = path(['brandedTeasers'], props.contentfulStore.topic);
    const travelkind = props.formDataStore.formData.travelkind;

    if (!brandedTeasers || !brandedTeasers.length || !travelkind) {
        return null;
    }

    const activeBrandedTeasers = brandedTeasers.filter((bt: IBrandedTeaser) => bt.travelkind === travelkind);

    return (
        <section className="branded-teasers-section">
            {activeBrandedTeasers.length ? <div className="smallHeader">Anzeige</div> : null}
            <div className="branded-teasers">
                {activeBrandedTeasers.map((bt: IBrandedTeaser, key: number) => (
                    <BrandedTeaserTile position={key} key={bt.tourOperator + bt.destination} brandedTeaser={bt} />
                ))}
            </div>
        </section>
    );
}

export default inject('contentfulStore', 'formDataStore')(BrandedTeaser);
