import React, { Component } from 'react';
import { ITourOperator } from './';
import fetch from 'isomorphic-unfetch';

import LoadingSpinner from 'src/common/LoadingSpinner';
import Modal from 'src/common/Modal';
import slugify from 'src/helpers/slugify';
import bodyScroll from 'src/helpers/bodyScroll';
import SeasonSelector from 'src/sections/TourOperatorABGSection/SeasonSelector';

import './tourOperatorAGBList.scss';

interface IPropTypes {
    tourOperatorsList: ITourOperator[];
}

interface IStateType {
    isLightBoxShowed: boolean;
    content: string;
    tourOperatorId: string;
    season: string;
    loading: boolean;
}

interface IFetchContentType {
    offerterms: string;
}

class TourOperatorAGBList extends Component<IPropTypes, IStateType> {
    constructor(props: IPropTypes) {
        super(props);

        this.state = {
            isLightBoxShowed: false,
            content: '',
            tourOperatorId: '',
            season: 'sommer',
            loading: false
        };

        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.onSeasonChange = this.onSeasonChange.bind(this);
    }
    public getSeasonForService(): string {
        return this.state.season === 'sommer' ? 'summer' : 'winter';
    }

    public onTermsClick(tourOperatorId: string): void {
        bodyScroll.lockBodyScroll();

        this.setState({ isLightBoxShowed: true, tourOperatorId, loading: true }, async () => {
            await this.fetchTermsAndUpdateContent();
        });
    }

    public generateCorrectFetchUrl(): string {
        return `${CFG.SVC_HOST}/svc/organizer-terms/${
            this.state.tourOperatorId
        }?travelseason=${this.getSeasonForService()}&locale=de-${CFG.TLD.toUpperCase()}`;
    }

    public async fetchTermsAndUpdateContent(): Promise<void> {
        const fetchUrl: string = this.generateCorrectFetchUrl();
        const response = await fetch(fetchUrl, {
            headers: {
                'x-frontend-id': 'topicpage'
            }
        });

        if (!response.ok) {
            this.setState({ content: 'Fehler beim laden der AGB\'s', loading: false });
        } else {
            const responseJSON: IFetchContentType = await response.json();

            this.setState({ content: responseJSON.offerterms, loading: false });
        }
    }

    public async onSeasonChange(season: string): Promise<void> {
        await this.setState({ season, loading: true }, async () => {
            await this.fetchTermsAndUpdateContent();
        });
    }

    public onOverlayClick(): void {
        bodyScroll.unlockBodyScroll();
        this.setState({ isLightBoxShowed: false, content: '', tourOperatorId: '' });
    }

    public generateAlphabet(): string[] {
        return this.props.tourOperatorsList
            .reduce((total: string[], tourOperator: ITourOperator) => {
                const tourOperatorFirstLetter: string =
                    tourOperator.name && !/^\d/.test(tourOperator.name)
                        ? tourOperator.name.charAt(0).toUpperCase()
                        : '0-9';

                if (!total.includes(tourOperatorFirstLetter)) {
                    total.push(tourOperatorFirstLetter);
                }

                return total;
            }, [])
            .sort();
    }

    public renderSingleTourOperator(tourOperator: ITourOperator) {
        const { id, name } = tourOperator;

        return (
            <div key={id} className="tour-operator-group">
                <div>
                    <div className="tour-operator-name">
                        <a href={`${CFG.WWW}/od/${slugify(name)}/${id}`}>{name}</a>
                    </div>
                    <div className="tour-operator-conditions">
                        <a onClick={this.onTermsClick.bind(this, id)}>Reisebedingungen</a>
                    </div>
                </div>
            </div>
        );
    }

    public renderAlphabetSection() {
        const alphabet: string[] = this.generateAlphabet();

        return (
            <React.Fragment>
                <hr className="divider" />

                {alphabet.map(char => {
                    return (
                        <React.Fragment key={char}>
                            <div className="alphabet-section">
                                <div className="touroperator-index">
                                    <h3>{char}</h3>
                                </div>
                                <div className="touroperator-listing">
                                    {this.props.tourOperatorsList.map(tourOperator => {
                                        return (
                                            ((tourOperator.name &&
                                                tourOperator.name.charAt(0).toUpperCase() === char) ||
                                                (char === '0-9' && /^\d/.test(tourOperator.name))) &&
                                            this.renderSingleTourOperator(tourOperator)
                                        );
                                    })}
                                </div>
                            </div>
                            <hr className="divider" />
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <div className="tourOperatorAGBList">{this.renderAlphabetSection()}</div>
                <Modal
                    isOpen={this.state.isLightBoxShowed}
                    title="AGB des Reiseveranstalters / Anbieters"
                    closeModal={this.onOverlayClick}
                >
                    {this.state.loading ? (
                        <div className="loading-agb">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <div className="info-terms">
                            <SeasonSelector season={this.state.season} onSeasonChange={this.onSeasonChange} />
                            <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                        </div>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

export default TourOperatorAGBList;
