const offsetTop = (element: HTMLElement) => {
    let posY = 0;
    let el = element;

    for (posY; el; el = el.offsetParent as HTMLElement) {
        posY += el.offsetTop;
    }

    return posY;
};

function logNotFound(selector: string) {
    console.log(selector + ' could not be found');
}

export const scrollTop = (windowObject: Window, additionalOffsetTop: number = 0) => {
    const selector = '#TOP';
    const element = window.document.querySelector<HTMLElement>(selector);

    if (!element) {
        return logNotFound(selector);
    }

    const elementOffsetTop = offsetTop(element) - additionalOffsetTop;

    windowObject.scrollTo(0, elementOffsetTop);
};

const scrollToElement = (windowObject: Window, element: HTMLElement, additionalOffsetTop: number) => {
    const elementOffsetTop = offsetTop(element) - additionalOffsetTop;

    windowObject.scrollTo(0, elementOffsetTop);
};

const easeInOutCubic = (t: number) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

const getPosition = (start: number, end: number, elapsed: number, duration: number) => {
    if (elapsed > duration) {
        return end;
    }
    return start + (end - start) * easeInOutCubic(elapsed / duration);
};

function getOffset(element: HTMLElement, position: string) {
    const scroll = ['top', 'bottom'].includes(position) ? 'scrollY' : 'scrollX';

    // @ts-ignore
    return element.getBoundingClientRect()[position] + window[scroll];
}

export function scrollIntoView(selector: string, position: string, container: string) {
    const scrollDirection = ['top', 'bottom'].includes(position) ? 'top' : 'left';
    const element = document.querySelector<HTMLElement>(selector);

    if (!element) {
        return logNotFound(selector);
    }

    const scrollContainer = container ? document.querySelector<HTMLElement>(container) : window;

    if (!scrollContainer) {
        return logNotFound(container);
    }

    if (element && scrollContainer) {
        const offset = getOffset(element, position);

        if (scrollContainer.scrollTo) {
            scrollContainer.scrollTo({ [scrollDirection]: offset, behavior: 'smooth' });
        } else {
            // @ts-ignore
            scrollContainer.scrollLeft = offset;
        }
    }
}

export default function scrollTo(windowObj: Window, querySelector: string, duration: number, offset = 0) {
    const animationDuration = duration || 500;
    const element = window.document.querySelector<HTMLElement>(querySelector);

    if (!element) {
        return logNotFound(querySelector);
    }

    if (!windowObj.requestAnimationFrame) {
        return scrollToElement(windowObj, element, offset);
    }

    const start = windowObj.pageYOffset;
    const end = element.getBoundingClientRect().top + start - offset;
    const clock = Date.now();

    const step = () => {
        const elapsed = Date.now() - clock;

        windowObj.scrollTo(0, getPosition(start, end, elapsed, animationDuration));

        if (elapsed < animationDuration) {
            windowObj.requestAnimationFrame(step);
        }
    };

    step();
}
