import React from 'react';
import { ChildProps, graphql } from 'react-apollo';

import Teaser, { ITeaserType } from './Teaser';
import query from './query.gql';

import './CampaignTeaser.scss';

interface ICampaignTeaserType {
    teaser?: ITeaserType;
}

export const CampaignTeaser = ({ data }: ChildProps<{}, ICampaignTeaserType>) => {
    if (!data || data.error || !data.teaser) {
        return null;
    }

    return (
        <div className="CampaignTeaser wrap wrap-plain">
            <Teaser teaser={data.teaser} />
        </div>
    );
};

export default graphql(query)(CampaignTeaser);
