import { action, observable } from 'mobx';

export class DefaultDatesStore {
    // @ts-ignore
    @observable public defaultDates: IDefaultTravelDatesType = {};

    @action public setDefaultDates(defaultDates: IDefaultTravelDatesType) {
        this.defaultDates = defaultDates;
    }
}
