import { action, autorun, computed, observable, set, toJS } from 'mobx';
import { find, propEq, filter, where, gte } from 'ramda';
import { IDuration } from 'src/sections/TravelSearchForm/formFields/Duration';
import { getDateDiff } from 'src/helpers/dates';
const durationData = require('src/sections/TravelSearchForm/formFields/Duration/data.json');

interface IOption {
    text: string;
    value: string;
    minDays: number;
    maxDays: number;
    valueFormattedForOfferService: string;
}

const getOptionsForDays = (options: IDuration[], days: number): IDuration[] =>
    filter(where({ maxDays: gte(days) }), options);

export function getCurrentOptions(fromDate: ITravelkindDate, toDate: ITravelkindDate) {
    const maxTravelDays = getDateDiff(new Date(fromDate), new Date(toDate));
    const optionsForDays = getOptionsForDays(durationData, maxTravelDays);

    return optionsForDays || durationData;
}

export class FormDataStore {
    @computed get durationOptions() {
        return toJS(this.options);
    }

    // @ts-ignore
    @observable public formData: IFormData = {};

    @observable public options: IOption[] = durationData;

    @computed get travelkind() {
        return this.formData.travelkind;
    }

    @computed get travelkindForm(): IPackage | IHotel {
        return toJS(this.getTravelkindForm());
    }

    @computed get packageForm() {
        return toJS(this.formData.package);
    }

    @computed get hotelForm() {
        return toJS(this.formData.hotel);
    }
    // @ts-ignore
    @observable public formData: IFormData = {};

    constructor() {
        autorun(() => {
            this.validateDurationOptions();
        });
    }

    @action public setTravelkind = (travelkind: ITravelkind) => {
        set(this.formData, 'travelkind', travelkind);
    };

    @action public setFormData = (formData: IFormData) => {
        this.formData = toJS(formData);
    };

    public getTravelkindForm = (): IPackage | IHotel => {
        return this.formData[this.formData.travelkind];
    };

    public validateDurationOptions = () => {
        const currentForm = this.getTravelkindForm();

        if (currentForm && currentForm.departureDate && currentForm.returnDate) {
            const options = getCurrentOptions(currentForm.departureDate, currentForm.returnDate);

            if (options.length) {
                set(this, 'options', options);

                const hasValueInCurrentOptions = find(propEq('valueFormattedForOfferService', currentForm.duration))(
                    options
                );

                if (!hasValueInCurrentOptions) {
                    set(currentForm, 'duration', '0');
                }
            }
        }
    };
}
