import { evolve, mergeRight, pick } from 'ramda';

import { ensureNumbersArray, ensureNumber, ensureDateString, ensureStringArray, ensureString } from 'src/helpers/ensureType';
import { possibleKeys } from 'src/sections/TravelSearchForm/action/generateReducersInitialState';
import { ensureDatesOrder } from 'src/helpers/validateFormData';

export default (defaultFormData: IFormData, rawQueryParams: object) => {
    const queryParams = evolve(
        {
            numberOfRooms: ensureNumber,
            adults: ensureNumber,
            children: ensureNumbersArray,
            departureDate: ensureDateString,
            returnDate: ensureDateString,
            transfer: ensureString,
            airport: ensureStringArray,
            travelkind: ensureString,
            duration: ensureString,
            boardType: ensureString,
            maxP: ensureString,
            hotelCategory: ensureString,
        },
        pick(possibleKeys, rawQueryParams)
    );

    const travelkind = queryParams.travelkind;
    const overrideParams = mergeRight(defaultFormData[travelkind], queryParams);

    return { ...defaultFormData, ...{ [travelkind]: ensureDatesOrder(overrideParams) } };
};
