import { action, computed, observable } from 'mobx';

import getValueFromGeneralTexts from 'src/helpers/getValueFromGeneralTexts';

export class GenericTextStore {
    @observable public texts: IGeneralTextType[] = [];

    @action public setTexts(texts: IGeneralTextType[]) {
        this.texts = texts;
    }

    @computed get phoneNumber() {
        return getValueFromGeneralTexts('phoneNumber', this.texts);
    }

    @computed get mobilePhoneNumber() {
        return getValueFromGeneralTexts('mobilePhoneNumber', this.texts);
    }

    @computed get openingHourHeader() {
        return getValueFromGeneralTexts('openingHourHeader', this.texts);
    }

    @computed get openingHourPaxDialog() {
        return getValueFromGeneralTexts('openingHourPaxDialog', this.texts);
    }
}
