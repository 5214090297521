import React from 'react';
import { pathSatisfies } from 'ramda';
import { IHotelOfferType } from '../types';

interface IHotelOfferResponseType {
    items: IHotelOfferType[];
}

interface IRegionPriceType {
    hotelOfferSearch: IHotelOfferResponseType;
}

const renderTotalPrice = (regionData: IHotelOfferType) => {
    const { currency, price } = regionData.offer.availabilityInformation.totalPrice;
    const currencySymbol = currency === 'EUR' ? ' €' : ` ${currency}`;
    const priceFormated = `${Math.ceil(price)} ${currencySymbol}`;

    return (
        <div className="total-price">
            ab <span className="price">{priceFormated}</span>
        </div>
    );
};

const renderPricePerPerson = (regionData: IHotelOfferType) => {
    const { currency, price } = regionData.offer.offerPrice;
    const currencySymbol = currency === 'EUR' ? ' €' : ` ${currency}`;
    const priceFormated = `${Math.ceil(price)} ${currencySymbol}`;
    return (
        <div className="person-price">
            ab <span className="price">{priceFormated}</span> p.P
        </div>
    );
};

const RegionPrice = (props: IRegionPriceType) => {
    const { hotelOfferSearch } = props;

    const hasPersonPrice = pathSatisfies(
        (checkPrice: number) => checkPrice > 0,
        ['items', '0', 'offer', 'offerPrice', 'price'],
        hotelOfferSearch
    );

    const hasTotalPrice = pathSatisfies(
        (checkPrice: number) => checkPrice > 0,
        ['items', '0', 'offer', 'availabilityInformation', 'totalPrice', 'price'],
        hotelOfferSearch
    );

    const hasPrice = hasPersonPrice && hasTotalPrice;

    return (
        <div className="wrapper">
            {!hasPrice && <div className="no-region-price">Angebote ansehen</div>}
            {hasPrice && renderTotalPrice(hotelOfferSearch.items[0])}
            {hasPrice && renderPricePerPerson(hotelOfferSearch.items[0])}
        </div>
    );
};

export default RegionPrice;
