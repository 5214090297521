import React from 'react';
import { inject, observer } from 'mobx-react';

import options from './data.json';

import './PriceSlider.scss';
import './rangeSlider.scss';

import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import track from 'src/helpers/tracking';

interface IOption {
    text: string;
    value: string;
    step: number;
}

type IEntity = 'value' | 'text' | 'step';

interface IInjectedProps {
    interactiveFormStore: InteractiveFormStore;
}

const getCurrentPriceFieldByEntity = (entity: IEntity, value: any) => {
    return options.find((price: IOption) => price[entity] === value);
};

const formatPriceByStep = (step: string) => {
    const price = getCurrentPriceFieldByEntity('step', step).value;

    if (price) {
        const currency = CFG.CURRENCY === 'EUR' ? '€' : CFG.CURRENCY;

        return 'bis ' + price.replace('1000', '1.000') + currency + ' p.P.';
    }

    return 'Beliebig';
};

@inject('interactiveFormStore')
@observer
export class PriceSlider extends React.Component<{}> {
    get injected() {
        return this.props as IInjectedProps;
    }

    public getStepFromProps = () => {
        const maxP = this.injected.interactiveFormStore.travelkindForm.maxP;

        return getCurrentPriceFieldByEntity('value', maxP || '').step;
    };

    public updateFieldValue = (event: any) => {
        event.persist();
        const step = event.target.value;

        const value = getCurrentPriceFieldByEntity('step', step).value;
        this.injected.interactiveFormStore.setMaxP(value);

        track({
            eventCategory: `search_${this.injected.interactiveFormStore.travelkind}_topic`,
            eventAction: 'priceslider_changed',
            eventLabel: `priceLimit; ${value}`
        });
    };

    public render() {
        const currentValue = this.getStepFromProps();
        return (
            <div className="price-slider-container fill-slider">
                <div className="price-wording visible-at-sm">Preis</div>
                <div className="price-wording hidden-at-sm">Reisepreis</div>
                <input
                    // @ts-ignore
                    style={{ '--min': 0, '--max': 4, '--val': currentValue }}
                    type="range"
                    min="0"
                    max="4"
                    value={currentValue}
                    onChange={this.updateFieldValue}
                />
                <div className="current-price">{formatPriceByStep(currentValue)}</div>
            </div>
        );
    }
}

export default PriceSlider;
