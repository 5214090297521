import React from 'react';

const Header = () => {
    const headerURL = '/fragment/hc-header/header?maxWidth=988&version=full&tenant=ce2';
    const failSafe = `<script>
    if (document.querySelector('#unified-header').children.length === 1) {
        var http = new XMLHttpRequest();
            http.onreadystatechange = function() {
                if (this.readyState !== 4 || this.status !== 200) return;
                document.querySelector('#unified-header').innerHTML = this.responseText;
                };
            http.open('GET', '${CFG.WWW + headerURL}');
            http.send();
        }
        </script>`;

    return (
        <header
            id="unified-header"
            dangerouslySetInnerHTML={{ __html: `<!--#include virtual="${headerURL}" -->${failSafe}` }}
        />
    );
};

export default Header;
