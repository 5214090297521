import React from 'react';
import { ChildProps, graphql } from 'react-apollo';

import Image from 'src/common/Image';

import query from '../../queries/imageQuery.gql';

interface ICountryImage {
    cmsQueryService: {
        galleryImages: [
            {
                location: string;
                url: string;
            }
        ];
    };
}

interface IInheritedProps {
    uuid: string;
    name: string;
    imageURL?: string;
}

interface IOperationals {
    uuid: string;
}

export const CountryImage = ({
    data,
    uuid,
    name,
    imageURL
}: ChildProps<IInheritedProps, ICountryImage, IOperationals>) => {
    if (!data || data.error) {
        return null;
    }

    if (data.loading) {
        return <div className="mainImage skeleton" />;
    }

    let imagePath = 'https://media-cdn.holidaycheck.com/w_612,h_460,c_fill,q_80/tp/' + uuid;

    if (data.cmsQueryService && data.cmsQueryService.galleryImages && data.cmsQueryService.galleryImages.length) {
        const imageData = data.cmsQueryService.galleryImages[0];
        imagePath = imageData.url + '?w=400&h=300&fit=fill&fm=jpg&fl=progressive';
    }

    return <Image useFadeIn={true} offset={100} imgClassName="mainImage" alt={name} src={imageURL || imagePath} />;
};

const querySettings = {
    options: ({ uuid }: IInheritedProps) => ({ variables: { uuid } })
};

export default graphql<IInheritedProps, ICountryImage, IOperationals>(query, querySettings)(CountryImage);
