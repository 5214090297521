import { assocPath, dissocPath, evolve, mergeDeepRight, path, pipe } from 'ramda';

export const OFFER_FILTER_KEY = 'hc_storage_offers';
export const HOTEL_FILTER_KEY = 'hotelFilters';

const hasSpecialJourneyAttribute = path(['package', 'specialJourneyAttribute']);
const hasStarsAttribute = path(['stars']);

const getItem = (localStorage: Storage, key: string) => JSON.parse(localStorage.getItem(key) || '{}');

export const getOfferFiltersFromStorage = (localStorage: Storage) => getItem(localStorage, OFFER_FILTER_KEY);
export const getHotelFiltersFromStorage = (localStorage: Storage) => getItem(localStorage, HOTEL_FILTER_KEY);

export const hasLocalStorageEntry = (localStorage: Storage): boolean => {
    return Boolean(localStorage.getItem(OFFER_FILTER_KEY));
};

export function setOfferFiltersToStorage(localStorage: Storage, payload: any) {
    const currentStorage = JSON.parse(localStorage.getItem(OFFER_FILTER_KEY) || '{}');
    const storageToSave = mergeDeepRight(currentStorage, payload);

    localStorage.setItem(OFFER_FILTER_KEY, JSON.stringify(storageToSave));
}

export function setHotelFiltersToStorage(localStorage: Storage, payload: any) {
    localStorage.setItem(HOTEL_FILTER_KEY, JSON.stringify(payload));
}

const assignTransfer = (data: IFormData, specialJourneyAttribute: string) =>
    assocPath(['package', 'transfer'], specialJourneyAttribute, data);

const deleteSpecialJourneyAttribute = dissocPath(['package', 'specialJourneyAttribute']);

export function getNormalizedOfferFiltersFromStorage(localStorage: Storage): any {
    let storageOfferFilters = getOfferFiltersFromStorage(localStorage);
    const hotelFilters = getHotelFiltersFromStorage(localStorage);

    if (storageOfferFilters && hasSpecialJourneyAttribute(storageOfferFilters)) {
        storageOfferFilters = pipe(
            (data: IFormData) => assignTransfer(data, storageOfferFilters.package.specialJourneyAttribute),
            deleteSpecialJourneyAttribute
        )(storageOfferFilters);
    }

    if (hotelFilters && hasStarsAttribute(hotelFilters)) {
        storageOfferFilters = assocPath(
            ['package', 'hotelCategory'],
            hotelFilters.stars.toString(),
            storageOfferFilters
        );
    }

    if (storageOfferFilters.travelkind === 'hotelonly') {
        storageOfferFilters.travelkind = 'hotel';
    }

    return storageOfferFilters;
}

const ensureCorrectTravelkind = evolve({
    maxP: value => (value === '' ? undefined : value),
    specialJourneyAttribute: value => (value === '' ? undefined : value),
    hotelCategory: () => undefined,
    suggestion: () => undefined
});

const ensureCorrectDataToSave = (formData: IFormData) => {
    return {
        travelkind: formData.travelkind,
        package: ensureCorrectTravelkind(formData.package),
        hotel: ensureCorrectTravelkind(formData.hotel)
    };
};

const switchTransfer = (data: IFormData): IFormData =>
    pipe(
        (formData: IFormData) => assocPath(['package', 'specialJourneyAttribute'], formData.package.transfer, formData),
        (formData: IFormData) => dissocPath(['package', 'transfer'], formData)
    )(data) as IFormData;

export function setNormalizedOfferFiltersToStorage(localStorage: Storage, formData: IFormData) {
    const dataToSave = pipe(
        switchTransfer,
        ensureCorrectDataToSave
    )(formData);

    setOfferFiltersToStorage(localStorage, dataToSave);

    const data = formData[formData.travelkind];

    setHotelFiltersToStorage(localStorage, {
        ...getHotelFiltersFromStorage(localStorage),
        availability: [true],
        stars: data.hotelCategory ? parseInt(data.hotelCategory, 10) : undefined
    });
}
