import gql from 'graphql-tag';

export default gql`
    type TopicConfiguration {
        travelkind: String
        packageDaysUntilDeparture: Int
        packageDaysUntilReturn: Int
        hotelDaysUntilDeparture: Int
        hotelDaysUntilReturn: Int
        duration: String
        hotelFilter: [String]
    }

    type Picture {
        url: String
    }

    type Media {
        url: String
        title: String
    }

    type Image {
        description: String
        url: String
        title: String
    }

    type BrandedTeasers {
        name: String
        destination: String
        url: String
        urlTarget: String
        price: Int
        tourOperator: String
        travelkind: String
        image: Image
    }

    type Section {
        name: String
        sectionId: String
        sectionProps: String
        media: Media
    }

    type ISeoConfiguration {
        title: String
        description: String
        meta: String
        robotsMeta: [String]
        breadcrumbName: String
        customContentOne: String
        customContentTwo: String
        customContentThree: String
    }

    type Headlines {
        name: String
        headline: String
        subHeadline: String
        subHeadlineInfo: String
        heroImage: String
        hotelTile: String
        travelSearchForm: String
        campaignTeaser: String
        tourOperator: String
        offeringList: String
        voucherDescription: String
        destinationInformationTitle: String
    }

    type GeneralText {
        topDateCaption: String
        calendarLegendText: String
        subHeadlineInfo: String
    }

    type DestinationInformationConfiguration {
        name: String
        url: String
        description: String
        location: String
        picture: Picture
    }

    type ContentfulTopic {
        name: String
        slug: String
        topRegions: String
        sections: [Section]
        brandedTeasers: BrandedTeasers
        heroImageMedia: Image
        topicConfiguration: TopicConfiguration
        seoConfiguration: ISeoConfiguration
        headlines: Headlines
        generalText: GeneralText
        destinationInformationConfiguration: DestinationInformationConfiguration
        enableAdvertisement: Boolean
    }
`;
