import { pathOr } from 'ramda';
import { RouteComponentProps } from 'react-router';

export default (props: RouteComponentProps) => {
    const urlParams = pathOr('', ['location', 'search'], props);

    if (urlParams.includes('ENABLE_TRACKING=0')) {
        return false;
    }

    if (urlParams.includes('ENABLE_TRACKING=1')) {
        return true;
    }

    return CFG.LIVE;
};
