import { mergeDeepRight, path } from 'ramda';
// @ts-ignore
import * as dateMath from 'date-arithmetic';

import { getDate, isDateInRange } from 'src/helpers/dates';

export default (
    daysUntilDeparture: number,
    daysUntilReturn: number,
    payload: IFormData,
    travelkind: ITravelkind
): IFormData => {
    const configuredDepartureDate: Date = dateMath.add(getDate(), daysUntilDeparture, 'day');
    const configuredReturnDate: Date = dateMath.add(getDate(), daysUntilReturn, 'day');
    const departureDate: Date | undefined = path([travelkind, 'departureDate'], payload);
    const returnDate: Date | undefined = path([travelkind, 'returnDate'], payload);

    if (
        !departureDate ||
        !returnDate ||
        !isDateInRange(departureDate, configuredDepartureDate, configuredReturnDate) ||
        !isDateInRange(returnDate, configuredDepartureDate, configuredReturnDate)
    ) {
        return mergeDeepRight(payload, {
            [travelkind]: {
                departureDate: configuredDepartureDate.toISOString(),
                returnDate: configuredReturnDate.toISOString()
            }
        });
    }

    return payload;
};
