import React from 'react';
import dateformat from 'dateformat';

interface IProps {
    travelkind: string;
    from?: Date;
    to?: Date;
    dateFormat: string;
}

export default ({ travelkind, from, to, dateFormat }: IProps) => {
    let headerText = '';
    if (!to) {
        headerText =
            travelkind === 'package'
                ? 'Bitte wählen Sie Ihren spätesten Rückflug aus.'
                : 'Bitte wählen Sie Ihr Abreisedatum aus.';
    } else {
        headerText = `${dateformat(from, dateFormat)} - ${dateformat(to, dateFormat)}`;
    }

    return <div className="header">{headerText}</div>;
};
