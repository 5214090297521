import React from 'react';
import Button from 'src/common/Button';

import { Offset } from './Children';

interface IProps {
    keyValue: number;
    age: number;
    no: number;
    onChange: (childIndex: number, offset: Offset) => void;
}

class ChildAge extends React.Component<IProps> {
    public static MIN = 1;
    public static MAX = 17;

    public render() {
        const props = this.props;
        const age = props.age || 0;
        const isMinAge = age <= ChildAge.MIN;
        const isMaxAge = age >= ChildAge.MAX;

        return (
            <div className="child-ages">
                <label className="top-label">Kind {props.no} </label>

                <div className="child-age-selector">
                    <div className="age-indicator">
                        {(age === ChildAge.MIN ? `< ${ChildAge.MIN + 1}` : props.age) + ' Jahre'}
                    </div>
                    <Button className="age-tweak" disabled={isMinAge} onClick={this.incAge}>
                        –
                    </Button>
                    <Button className="age-tweak" disabled={isMaxAge} onClick={this.decAge}>
                        +
                    </Button>
                </div>
            </div>
        );
    }

    private incAge = () => this.props.onChange(this.props.keyValue, -1);
    private decAge = () => this.props.onChange(this.props.keyValue, 1);
}

export default ChildAge;
