import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { IStores } from 'src/helpers/store';
import Modal from 'src/common/Modal';
import track from 'src/helpers/tracking';
import Input from 'src/common/Input';
import Button from 'src/common/Button';
import Checkbox from 'src/common/Checkbox';
import bodyScroll from 'src/helpers/bodyScroll';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

import './AirportDialog.scss';

import airportList from './airports.json';

interface IInjectedProps {
    selectedAirports: string[];
    toggleAirport: InteractiveFormStore['toggleAirport'];
    resetAirport: InteractiveFormStore['resetAirport'];
}

interface IState {
    isOpen: boolean;
}

interface IMapType {
    [key: string]: string;
}

interface IAirportType {
    name: string;
    iata: string;
}

const countryLookup: IMapType = {
    DE: 'Deutschland',
    CH: 'Schweiz',
    AT: 'Österreich',
    NL: 'Niederlande',
    OTHER: 'Sonstige Flughäfen'
};

const listOfCountryCodes = ['DE', 'CH', 'AT', 'NL', 'OTHER'];

@inject((stores: IStores) => {
    const formStore = stores.interactiveFormStore;

    return {
        selectedAirports: formStore.travelkindForm.airport,
        toggleAirport: formStore.toggleAirport,
        resetAirport: formStore.resetAirport
    };
})
@observer
class AirportDialog extends Component<{}, IState> {
    public static defaultProps = {
        selectedAirports: []
    };

    public state = {
        isOpen: false
    };

    get injected() {
        return this.props as IInjectedProps;
    }

    public selectAll = () => {
        this.injected.resetAirport();
    };

    public toggleAirport = (name: string) => {
        this.injected.toggleAirport(name);
    };

    public airportsByCountry = (key: string) => {
        return (
            <React.Fragment key={key}>
                <h3 className="country-title">{countryLookup[key]}</h3>
                {airportList[key].map(({ name, iata }: IAirportType) => (
                    <Checkbox
                        label={name}
                        name={iata}
                        onClick={this.toggleAirport}
                        value={iata}
                        checked={this.injected.selectedAirports.includes(iata)}
                        key={iata}
                    />
                ))}
            </React.Fragment>
        );
    };

    public formatAirportText = (selectedAirports: string[]) => {
        const amount = selectedAirports.length;
        if (amount === 0) {
            return 'Alle Flughäfen';
        } else if (amount === 1) {
            return `1 Flughafen (${selectedAirports[0]})`;
        } else if (amount < 3) {
            return `${amount} Flughäfen (${selectedAirports.join(', ')})`;
        } else {
            return `${amount} Flughäfen`;
        }
    };

    public closeModal = () => {
        track({
            eventCategory: 'search_package_topic',
            eventAction: 'airport_picker_changed',
            eventLabel: `airport:${this.injected.selectedAirports.slice()}`
        });

        bodyScroll.unlockBodyScroll();

        this.setState({ isOpen: false });
    };

    public openModal = () => {
        bodyScroll.lockBodyScroll();
        this.setState({ isOpen: true });
    };

    public render() {
        const selectedAirports = this.injected.selectedAirports;
        const { isOpen } = this.state;

        return (
            <div className="airport-select-wrapper">
                <Input
                    className="airport-input"
                    value={this.formatAirportText(selectedAirports)}
                    icon="outgoing-flight"
                    appendIcon={true}
                    onClick={this.openModal}
                    readOnly={true}
                />

                <Modal
                    footer={
                        <section className="modal-footer">
                            <hr className="footer-separator" />
                            <a className="dismiss-link" onClick={this.selectAll}>
                                Auswahl aufheben
                            </a>
                            <Button type="primary" onClick={this.closeModal} className="apply-btn">
                                Übernehmen
                            </Button>
                        </section>
                    }
                    title="Abflughafen"
                    isOpen={isOpen}
                    closeModal={this.closeModal}
                >
                    <Checkbox
                        label="Alle Flughäfen"
                        value="all_airports"
                        onClick={this.selectAll}
                        checked={selectedAirports.length === 0}
                    />

                    <div className="airports">{listOfCountryCodes.map(this.airportsByCountry)}</div>
                </Modal>
            </div>
        );
    }
}

export default AirportDialog;
