import * as React from 'react';
import { inject } from 'mobx-react';

import { ContentfulStore } from 'src/stores/ContentfulStore';

import BreadCrumb, { IRoute } from './Breadcrumb';

import './BreadCrumbBar.scss';

function buildRoutes(contentfulTopic: IContentfulTopic): IRoute[] {
    const { seoConfiguration, slug, name } = contentfulTopic;
    const { breadcrumbName } = seoConfiguration;
    const defaultRoute = CFG.PUBLIC_PATH || '/';

    const routes = [
        {
            breadcrumbName: CFG.TLD === 'ch' ? 'Ferien' : 'Urlaub',
            path: defaultRoute,
            isLast: slug === '/'
        }
    ];

    if (slug !== '/') {
        routes.push({
            breadcrumbName: breadcrumbName || name,
            path: slug,
            isLast: true
        });
    }
    return routes;
}

interface IProps {
    contentfulStore: ContentfulStore;
}

export const BreadCrumbBar = ({ contentfulStore }: IProps) => {
    const contentfulTopic: IContentfulTopic = contentfulStore.topic;

    const routes = buildRoutes(contentfulTopic);

    if (routes.length <= 1) {
        return null;
    }

    return (
        <nav className="breadcrumb">
            {routes.map(route => (
                <BreadCrumb route={route} key={route.path} />
            ))}
        </nav>
    );
};

export default inject('contentfulStore')(BreadCrumbBar);
