import getElementPosition from './getElementPosition';

const isHidden = (element: Element) => element.offsetParent === null;

export default function inViewport(element: Element, offset: number) {
    if (isHidden(element)) {
        return false;
    }

    let top;
    let bottom;
    let left;
    let right;

    const container = document.getElementById('main');
    const containerPosition = getElementPosition(container);

    left = containerPosition.left;
    right = left + container.clientWidth;

    top = window.pageYOffset;
    bottom = top + window.innerHeight;

    const elementPosition = getElementPosition(element);

    return (
        top <= elementPosition.top + element.offsetHeight + offset &&
        bottom >= elementPosition.top - offset &&
        left <= elementPosition.left + element.offsetWidth + offset &&
        right >= elementPosition.left - offset
    );
}
