import React, { Component } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
// @ts-ignore
import * as dateMath from 'date-arithmetic';

import track from 'src/helpers/tracking';
import Dialog from 'src/common/Dialog';
import Input from 'src/common/Input';

import { DAYS, DAYSLONG, DEFAULT_LOCALE, MONTHS } from './reactDayPickerLocaleUtils';
import CalendarHeader from './CalendarHeader';
import CalendarFooter from './CalendarFooter';

import {
    getCaption,
    getDisabledDays,
    getFormat,
    getInputValue,
    getModifiers,
    isMobileOrTablet,
    isSelectingFirstDay
} from './helpers';
import './DatePicker.scss';
import { getDate, isSameDay } from 'src/helpers/dates';
import { IStores } from 'src/helpers/store';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';

interface IInjectedProps {
    topic: IContentfulTopic;
    travelkind: ITravelkind;
    departureDate: Date;
    returnDate: Date;
    setCalendarDates: InteractiveFormStore['setCalendarDates'];
}

export interface ICalendarState {
    numberOfMonths: number;
    isOpen: boolean;
    from?: any | Date;
    to?: any | Date;
    enteredTo?: Date;
    initialMonth: Date;
}

const configuredDaysToDate = (days: number) => dateMath.add(getDate(), days, 'day');

@inject(({ contentfulStore, interactiveFormStore: { travelkindForm, travelkind, setCalendarDates } }: IStores) => {
    return {
        travelkind,
        setCalendarDates,
        topic: contentfulStore.topic,
        departureDate: new Date(travelkindForm.departureDate),
        returnDate: new Date(travelkindForm.returnDate)
    };
})
@observer
export class DatePicker extends Component<{}, ICalendarState> {
    public state = {
        numberOfMonths: 2,
        isOpen: false,
        from: undefined,
        to: undefined,
        enteredTo: undefined, // Keep track of the last day for mouseEnter.
        initialMonth: new Date(),
        isContentfulDates: false
    };

    get injected() {
        return this.props as IInjectedProps;
    }

    public componentDidMount() {
        const { departureDate, returnDate } = this.injected;

        this.setState({
            initialMonth: departureDate,
            numberOfMonths: isMobileOrTablet() ? 1 : 2,
            from: departureDate,
            to: returnDate,
            enteredTo: returnDate
        });
    }

    public componentWillReceiveProps(nextProps: Readonly<IInjectedProps>): void {
        if (!this.state.isOpen && (this.props !== nextProps)) {
            this.setState({
                initialMonth: nextProps.departureDate,
                numberOfMonths: isMobileOrTablet() ? 1 : 2,
                from: nextProps.departureDate,
                to: nextProps.returnDate,
                enteredTo: nextProps.returnDate
            });
        }
    }

    public onChange = async (day: Date, modifiers: DayModifiers) => {
        // in case the day is disabled
        if (modifiers.disabled) {
            return;
        }

        const { from, to } = this.state;
        const { setCalendarDates } = this.injected;

        if (isSelectingFirstDay(from, to)) {
            this.setState({
                from: day,
                to: undefined,
                enteredTo: undefined
            });

            setCalendarDates({ departureDate: day });
        } else {
            // @ts-ignore
            const isBeforeFirstDay = Object(from) instanceof Date ? DayPicker.DateUtils.isDayBefore(day,  from) : false;

            if (isBeforeFirstDay) {
                this.setState({
                    from: day,
                    to: from,
                    enteredTo: from
                });
                setCalendarDates({ departureDate: day, returnDate: from });
            } else {
                this.setState({
                    to: day,
                    enteredTo: day
                });
                setCalendarDates({ returnDate: day });
            }
        }
    };
    public handleDayMouseEnter = (day: Date) => {
        const { from, to } = this.state;

        if (!isSelectingFirstDay(from, to)) {
            this.setState({
                enteredTo: day
            });
        }
    };

    public closeModal = () => {
        if (this.state.isOpen === false) return;
        const { departureDate, returnDate } = this.injected;

        // todo: this seems to be triggered for PAX modal ??!!
        track({
            eventCategory: `search_${this.injected.travelkind}_topic`,
            eventAction: 'date_picker_changed',
            eventLabel: `departureDate:${departureDate.toISOString()};returnDate:${returnDate.toISOString()}`
        });

        this.setState({ isOpen: false });
    };

    public openModal = () => this.setState({ isOpen: true });

    public render() {
        const { travelkind, departureDate, returnDate, topic } = this.injected;

        const { isOpen, from, to, enteredTo, numberOfMonths, initialMonth } = this.state;
        const disabledDays = getDisabledDays(travelkind);
        const selectedDays: any = [from, { from, to: enteredTo }];
        const topDateCaption = getCaption(topic);

        return (
            <div className="date-select">
                <Input
                    inputLegend={
                        this.isContentfulDates() &&
                        topDateCaption && (
                            <span className="calendar-extra-text" onClick={this.openModal}>
                                {topDateCaption}
                            </span>
                        )
                    }
                    readOnly={true}
                    value={getInputValue(departureDate, returnDate)}
                    icon="calendar"
                    appendIcon={true}
                    onClick={this.openModal}
                    className={topDateCaption ? 'calendar-input' : ''}
                />
                <Dialog
                    isOpen={isOpen}
                    cssClass={'calendar-modal'}
                    onCloseModal={this.closeModal}
                    overlayInvisible={true}
                >
                    <div className={classNames('title-cp-only', { selected: this.state.to })}>
                        <i className="arrow" />
                        <section className="dialog-header">
                            Reisezeitraum
                            <a role="button" className="close" onClick={this.closeModal}>
                                ✕
                            </a>
                        </section>
                        <section className="dialog-main">
                            <CalendarHeader travelkind={travelkind} to={to} from={from} dateFormat={getFormat()} />
                            <DayPicker
                                className="Range Selectable"
                                numberOfMonths={numberOfMonths}
                                month={initialMonth}
                                modifiers={getModifiers(topic, travelkind, this.state)}
                                selectedDays={selectedDays}
                                disabledDays={disabledDays}
                                onDayClick={this.onChange}
                                toMonth={disabledDays.after}
                                fromMonth={disabledDays.before}
                                onDayMouseEnter={this.handleDayMouseEnter}
                                locale="de"
                                months={MONTHS[DEFAULT_LOCALE]}
                                weekdaysShort={DAYS[DEFAULT_LOCALE]}
                                weekdaysLong={DAYSLONG[DEFAULT_LOCALE]}
                                firstDayOfWeek={1}
                            />
                            <CalendarFooter closeModal={this.closeModal} to={to} calendarLegend={topDateCaption} />
                        </section>
                    </div>
                </Dialog>
            </div>
        );
    }

    private isContentfulDates = () => {
        const topicConfiguration = this.injected.topic.topicConfiguration;
        const {
            packageDaysUntilDeparture,
            packageDaysUntilReturn,
            hotelDaysUntilDeparture,
            hotelDaysUntilReturn
        } = topicConfiguration;
        const travelkind = this.injected.travelkind;

        const { from, to } = this.state;

        const configuredDepartureDate = configuredDaysToDate(
            travelkind === 'package' ? packageDaysUntilDeparture : hotelDaysUntilDeparture
        );
        const configuredReturnDate = configuredDaysToDate(
            travelkind === 'package' ? packageDaysUntilReturn : hotelDaysUntilReturn
        );

        // @ts-ignore
        return from && to && isSameDay(configuredDepartureDate, from) && isSameDay(configuredReturnDate, to);
    };
}

export default DatePicker;
