import React from 'react';

import './voucher.scss';

interface IProps {
    voucherUrl?: string;
    children: React.ReactNode;
}

const VoucherWrapper = ({ children, voucherUrl }: IProps) => {
    return voucherUrl ? (
        <a target="_blank" href={`${CFG.WWW}${voucherUrl}`} className="voucher">
            {children}
        </a>
    ) : (
        <div className="voucher">{children}</div>
    );
};

export default VoucherWrapper;
