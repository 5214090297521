import * as React from 'react';

import classNames from 'classnames';

interface IProps {
    icon: string;
    onClick?: () => any;
    className?: string;
}

export default ({ icon, onClick, className }: IProps) => {
    const iconClasses = classNames('icon', {
        [`icon-${icon}`]: true,
        [className || '']: Boolean(className)
    });

    return <i className={iconClasses} onClick={onClick} />;
};
