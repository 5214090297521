import * as React from 'react';

import './kayak.scss';
import isServerside from 'src/helpers/isServerside';

let isNavigatedFromKayak: boolean = false;

class Kayak extends React.Component {
    public componentDidMount() {
        window.addEventListener('popstate', () => {
            if(isNavigatedFromKayak) {
                isNavigatedFromKayak = false;
                this.locationReload();
            }
        });
    }

    public componentWillUnmount() {
        isNavigatedFromKayak = true;
        window.removeEventListener('popstate', () => {
            this.locationReload();
        });
    }

    public render() {
        const script = `!function(w,d,t,p,v,i,m,k,s){
            k='kayak-i-frame-loader';
            (w[k]=w[k]||[]).push(arguments);
            w[k].d=1* new Date();w[k].c=(w[k].d+'').substring(0,7);
            s=d.createElement(t),m=d.getElementsByTagName(t)[0];
            s.async=1;
            s.src='//'+p+'/kayak-i-frame-loader.js?_='+w[k].c;
            m.parentNode.insertBefore(s,m)
        } (window,document,'script','holidaycheckwl-${CFG.TLD === 'ch' ? CFG.TLD : 'de'}.affiliate.kayak.com','flights')`;

        return (
            <div id="kayak-i-frame">
                {isServerside() && <script dangerouslySetInnerHTML={{ __html: script }} />}
            </div>
        );
    };

    private locationReload() {
        window.location.reload();
    }
};

export default Kayak;
