import { inject } from 'mobx-react';
import React from 'react';

import Icon from 'src/common/Icon';
import { GenericTextStore } from 'src/stores/GenericTextStore';
import { getCurrentPhoneNumber } from 'src/common/PhoneNumber';

interface IProps {
    isValidSelection: boolean;
    moreThanSixAdults: boolean;
}

interface IInjectedProps extends IProps {
    genericTextStore: GenericTextStore;
}

export default inject('genericTextStore')((props: IProps) => {
    const { isValidSelection, moreThanSixAdults } = props;
    const phoneNumber = getCurrentPhoneNumber((props as IInjectedProps).genericTextStore);

    return (
        <div className="info">
            {!isValidSelection && (
                <p className="attention">Für diese Auswahl finden wir auf unserer Seite leider keine Angebote.</p>
            )}

            {!isValidSelection && !moreThanSixAdults && (
                <p className="info-text">Telefonisch können wir aber weiterhelfen. Rufen Sie uns an!</p>
            )}

            {isValidSelection && (
                <p className="info-text">Sie wünschen eine andere Zimmeraufteilung? Dann rufen Sie uns an!</p>
            )}

            {moreThanSixAdults && (
                <p className="info-text">
                    Bei Anfragen für mehr als 6 Erwachsene wenden Sie sich bitte an unseren Kundenservice.
                </p>
            )}
            <a href={`tel:${phoneNumber}`} className="phone">
                <Icon icon="phone" />
                <span aria-label={`Kostenlos anrufen unter ${phoneNumber}`}>{phoneNumber}</span>
            </a>
            <p className="phone-hint">{(props as IInjectedProps).genericTextStore.openingHourPaxDialog}</p>
        </div>
    );
});
