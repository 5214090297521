const facetConstant = 'facilitySummaries:';

const createFacetsURL = (hotelFilters: string[]) => {
    let facetUrl: string = '';

    hotelFilters.forEach(hotelFilter => {
        facetUrl = `${facetUrl}${facetConstant}${hotelFilter},`;
    });

    return facetUrl.replace(/^facilitySummaries:/, '');
};

export default createFacetsURL;
