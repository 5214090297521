import gql from 'graphql-tag';

export default gql`
    type TravelkindTabPanels {
        package: String
        hotel: String
    }

    type TravelkindData {
        tabPanes: TravelkindTabPanels
        availableTravelkinds: [String]!
    }
`;
