import { action, computed, observable } from 'mobx';

export interface ITopicLink {
    slug: string;
    url: string;
}

export class AllTopicStore {
    // @ts-ignore
    @observable public slugs: string[] = [];

    // @ts-ignore
    @action public setSlugs(topicLinks: ITopicLink[]) {
        this.slugs = topicLinks
            .filter(
                topicLink =>
                    topicLink.url && (topicLink.url.startsWith('urlaub') || topicLink.url.startsWith('/urlaub'))
            )
            .map(topicLink => topicLink.slug);
    }

    // @ts-ignore
    @computed get getSlugs() {
        return this.slugs;
    }
}
