import React from 'react';

interface IPictureParam {
    mediaQuery: string;
    srcParams: string;
}

interface IProps {
    pictureParams: IPictureParam[];
    baseURL: string;
    className?: string;
    altText: string;
    commonPictureParams?: string;
}

const renderSources = (
    pictureParams: IPictureParam[],
    baseURL: string,
    commonPictureParams: string = '',
    className: string = ''
) =>
    pictureParams.map(pictureParam => {
        const imgURL = baseURL + pictureParam.srcParams + commonPictureParams;

        return (
            <source
                media={pictureParam.mediaQuery}
                key={pictureParam.mediaQuery}
                className={className}
                srcSet={imgURL}
            />
        );
    });

const SourcePictureRenderer = ({ pictureParams, baseURL, className, commonPictureParams, altText }: IProps) => (
    <picture>
        {renderSources(pictureParams, baseURL, commonPictureParams, className)}
        <img src={baseURL} alt={altText} className={className} />
    </picture>
);

export default SourcePictureRenderer;
