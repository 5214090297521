import { action, computed, observable } from 'mobx';

export class ContentfulStore {
    // @ts-ignore
    @observable public topic: IContentfulTopic = {};

    @computed get isEmpty() {
        return !Boolean(this.topic.slug);
    }

    @computed get slug() {
        return this.topic.slug;
    }

    @action public setTopic(topic: IContentfulTopic) {
        this.topic = topic;
    }
}
