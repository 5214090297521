import React from 'react';

interface IProps {
    Component: any;
    componentPayload?: object;
}

export default class Section extends React.Component<IProps> {
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('[ESECT1]', error.name, error.message, ' in: ', errorInfo.componentStack);
    }

    public render() {
        const Component = this.props.Component;

        return <Component {...this.props.componentPayload} />;
    }
}
