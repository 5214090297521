import * as React from 'react';
import classname from 'classnames';

import './styles/CarouselItem.scss';

interface IProps {
    onTouchStart: (event: any, index: number) => void;
    children: any;
    width: number;
    offset: number;
    index: number;
    currentSlideIndex: number;
    insideRange: boolean;
}

export default class CarouselItem extends React.Component<IProps> {
    public onTouchStart = (event: any) => {
        this.props.onTouchStart(event, this.props.index);
    };

    public render() {
        const { width, offset, currentSlideIndex, index, insideRange } = this.props;
        const withPx = `${width}px`;

        return (
            <li
                className={classname('CarouselItem', {
                    'CarouselItem--active': index === currentSlideIndex,
                    'CarouselItem--inside-range': insideRange,
                    'no-pointer-events': !insideRange
                })}
                style={{
                    paddingRight: `${offset / 2}px`,
                    paddingLeft: `${offset / 2}px`,
                    width: withPx,
                    maxWidth: withPx,
                    minWidth: withPx
                }}
                onTouchStart={this.onTouchStart}
            >
                {this.props.children}
            </li>
        );
    }
}
