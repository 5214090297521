import * as React from 'react';

import Carousel from './carousel';

interface IProps {
    children: any;
    carouselSettings?: object;
}

const ANIMATION_SPEED = 500;

export function triggerEvent(el: Element, type: string) {
    if ('createEvent' in document) {
        const e = document.createEvent('HTMLEvents');
        e.initEvent(type, true, true);
        el.dispatchEvent(e);
    }
}

export default class CarouselSlider extends React.Component<IProps, { value: number }> {
    private readonly ref: React.RefObject<any>;

    constructor(props: Readonly<IProps>) {
        super(props);

        this.state = {
            value: 0
        };

        this.ref = React.createRef();
    }

    public triggerScroll = () => {
        let start: number = 0;

        const step = (timestamp: number) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;

            triggerEvent(this.ref.current, 'scroll');

            if (progress < ANIMATION_SPEED) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    };

    public onChange = (value: number) => {
        this.setState({ value }, this.triggerScroll);
    };

    public onDotClick = (value: number, factor: number) => {
        this.setState({ value: value * factor }, this.triggerScroll);
    };

    public render() {
        return (
            <div className="carousel-container" ref={this.ref}>
                <Carousel
                    animationSpeed={ANIMATION_SPEED}
                    onDotClick={this.onDotClick}
                    value={this.state.value}
                    onChange={this.onChange}
                    {...(this.props.carouselSettings || {})}
                >
                    {this.props.children}
                </Carousel>
            </div>
        );
    }
}
