import React from 'react';
import { ChildProps, graphql } from 'react-apollo';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { pathOr } from 'ramda';
import { inject } from 'mobx-react';

import GTM from 'src/common/GTM';
import Layout from 'src/common/Layout';
import Redirect from 'src/helpers/Redirect';
import { mergeFormSettings } from 'src/sections/TravelSearchForm/action/generateReducersInitialState';
import LoadingIndicator from 'src/common/LoadingIndicator';
import { getCurrentSlug } from 'src/helpers/getCurrentSlug';
import { getDate } from 'src/helpers/dates';

import SectionResolver from './SectionResolver';
import query from './query.gql';
import { ContentfulStore } from 'src/stores/ContentfulStore';
import { GenericTextStore } from 'src/stores/GenericTextStore';
import { DefaultDatesStore } from 'src/stores/DefaultDatesStore';
import { TravelkindDataStore } from 'src/stores/TravelkindDataStore';
import { InteractiveFormStore } from 'src/stores/InteractiveFormStore';
import { FormDataStore } from 'src/stores/FormDataStore';
import { AllTopicStore, ITopicLink } from 'src/stores/AllTopicStore';
import { scrollTop } from 'src/helpers/scrollTo';

interface IInjectedProps extends InheritedProps {
    contentfulStore: ContentfulStore;
    genericTextStore: GenericTextStore;
    defaultDatesStore: DefaultDatesStore;
    travelkindDataStore: TravelkindDataStore;
    interactiveFormStore: InteractiveFormStore;
    formDataStore: FormDataStore;
    allTopicStore: AllTopicStore;
}

type InheritedProps = ChildProps<RouteComponentProps, IResult>;

interface IResult {
    topics: [IContentfulTopic];
    defaultTravelDates: IDefaultTravelDatesType;
    genericTexts: [IGeneralTextType];
    allTopics: ITopicLink[];
}

const getTravelDatesFallback = () => {
    const helper = getDate();
    const plus01Days = new Date(helper.setDate(helper.getDate() + 1));
    const plus03Days = new Date(helper.setDate(helper.getDate() + 3));
    const plus90Days = new Date(helper.setDate(helper.getDate() + 93));

    return {
        package: {
            departureDate: plus03Days.toISOString(),
            returnDate: plus90Days.toISOString()
        },
        hotel: {
            departureDate: plus01Days.toISOString(),
            returnDate: plus03Days.toISOString()
        }
    };
};

const getTopic: (data: any) => IContentfulTopic | any = pathOr({}, ['topics', '0']);

@inject(
    'contentfulStore',
    'genericTextStore',
    'defaultDatesStore',
    'travelkindDataStore',
    'interactiveFormStore',
    'formDataStore',
    'allTopicStore'
)
class MainQuery extends React.Component<InheritedProps> {
    constructor(props: IInjectedProps) {
        super(props);
        const topic = getTopic(props.data);

        if (props.contentfulStore.isEmpty && topic.slug) {
            this.saveSetting(this.props as IInjectedProps);
        }
    }

    get injected() {
        return this.props as IInjectedProps;
    }

    public render() {
        const data: any = this.props.data || {};

        if (!data || data.error) {
            console.error(`ERROR [TPE003]`, JSON.stringify(data.error));
            return <Redirect status={500} />;
        }

        if (!data.topics || !data.topics.length) {
            return <Redirect status={404} />;
        }

        const topic = getTopic(data);

        if (topic.slug.indexOf('iframe-') > -1) {
            return <Route path="*" render={this.renderRoute} />;
        }

        return (
            <Layout slug={topic.slug}>
                <GTM />
                <Route path="*" render={this.renderRoute} />
            </Layout>
        );
    }

    public componentDidMount(): void {
        this.saveSetting(this.props as IInjectedProps);
    }

    public componentWillReceiveProps(nextProps: Readonly<IInjectedProps>): void {
        const { contentfulStore, data } = nextProps;
        const topic = getTopic(data);

        if (topic.slug !== contentfulStore.slug) {
            this.saveSetting(nextProps);
            scrollTop(window);
        }
    }

    public saveSetting = (props: IInjectedProps) => {
        const {
            travelkindDataStore,
            interactiveFormStore,
            formDataStore,
            contentfulStore,
            genericTextStore,
            defaultDatesStore,
            allTopicStore
        } = props;
        const data: any = props.data || {};
        const topic = getTopic(data);

        contentfulStore.setTopic(topic);
        genericTextStore.setTexts(data.genericTexts || []);
        allTopicStore.setSlugs(data.allTopics || []);
        defaultDatesStore.setDefaultDates(data.defaultTravelDates || getTravelDatesFallback());

        mergeFormSettings(
            topic.topicConfiguration,
            data.defaultTravelDates,
            {
                travelkindDataStore,
                interactiveFormStore,
                formDataStore
            },
            props
        );
    };

    public renderRoute = () => {
        const data: any = this.props.data || {};
        const topic = getTopic(data);

        return data.loading ? <LoadingIndicator /> : <SectionResolver topic={topic} error={data.error} />;
    };
}

export default withRouter(
    graphql<RouteComponentProps, IResult, { slug: string }>(query, {
        options: (props: RouteComponentProps) => {
            return {
                variables: {
                    slug: `fields.slug=${getCurrentSlug(props)}`
                }
            };
        }
    })(MainQuery)
);
