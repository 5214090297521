import isServerside from 'src/helpers/isServerside';

export default class Device {
    // breakpoints in alignment with breakpoint.less
    public static BreakPoint = {
        PHONE: 749,
        TABLET: 999
    };

    public static getUserAgent() {
        // @ts-ignore
        return navigator.userAgent || navigator.vendor || window.opera;
    }

    public static getWidthClass(): string {
        if (isServerside() || innerWidth > Device.BreakPoint.TABLET) {
            return 'desktop';
        }

        if (innerWidth >= Device.BreakPoint.PHONE) {
            return 'tablet';
        }

        return 'smartphone';
    }

    public static isTablet() {
        return Device.getWidthClass() === 'tablet';
    }

    public static isIOS() {
        // @ts-ignore
        return /iPad|iPhone|iPod/.test(Device.getUserAgent()) && !window.MSStream;
    }

    public static isAndroid() {
        return /android/i.test(Device.getUserAgent());
    }
}
