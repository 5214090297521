import { normalizeDate } from 'src/helpers/dates';

export const ensureStringArray = (t: any): string[] => ensureArray(t).map(ensureString);

export const ensureNumbersArray = (t: any): number[] => ensureArray(t).map(ensureNumber);

export const ensureArray = (v?: any) => {
    if (!v) {
        return [];
    }

    return Array.isArray(v) ? v : [v];
};

export const ensureNumber = (t: string | number | undefined): number => {
    if (t) {
        if (typeof t === 'string') {
            return parseInt(t, 10);
        }

        if (Number.isInteger(t)) {
            return t;
        }
    }

    return 0;
};
export const ensureString = (t: any): string => (
    typeof t === 'string' ? t : t.toString()
).replace(/(<([^>]+)>)/gi, '');

export const ensureDateString = (d: Date | string): string | null => {
    try {
        return normalizeDate(d).toISOString();
    } catch (e) {
        return null;
    }
};
