import React from 'react';

import Radio from 'src/common/Radio';

interface IProps {
    adults: number | string;
    changePaxData: (adultsCount: string) => void;
}

export default ({ adults, changePaxData }: IProps) => {
    return (
        <div className="adults-selector">
            <label className="top-label">Erwachsene</label>
            <div className="selectors">
                <Radio
                    value="1"
                    name="adults"
                    checked={adults === 1}
                    label="1"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="2"
                    name="adults"
                    checked={adults === 2}
                    label="2"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="3"
                    name="adults"
                    checked={adults === 3}
                    label="3"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="4"
                    name="adults"
                    checked={adults === 4}
                    label="4"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="5"
                    name="adults"
                    checked={adults === 5}
                    label="5"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="6"
                    name="adults"
                    checked={adults === 6}
                    label="6"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
                <Radio
                    value="6+"
                    name="adults"
                    checked={adults === '6+'}
                    label="6+"
                    onClick={changePaxData}
                    customCheckMarkClass="pax-checkmark"
                />
            </div>
        </div>
    );
};
