import React from 'react';
import classNames from 'classnames';

import LazyLoad, { ILazyLoadProps } from 'src/common/LazyLoad';
import './animation.scss';

interface IProps extends React.ImgHTMLAttributes<React.ReactNode> {
    useFadeIn: boolean;
    isLocalImage: boolean;
    useLazyLoading: boolean;
    src: string;
    alt: string;
    debounce?: boolean;
    offset?: number;
    imgClassName?: string;
}
interface ILoaded {
    [src:string]: string | boolean
}
const _loaded:ILoaded = {};

export default class Image extends React.Component<IProps & ILazyLoadProps> {
    public static defaultProps = {
        useFadeIn: false,
        isLocalImage: false,
        useLazyLoading: true
    };

    public state = {
        loaded: _loaded[this.props.src]
    };

    public onLoad = () => {
        _loaded[this.props.src] = true;
        this.setState(() => ({ loaded: true }));
    };

    public render() {
        const {
            isLocalImage,
            alt,
            src,
            debounce,
            offset,
            throttle,
            elementType,
            height,
            className,
            imgClassName,
            width,
            onContentVisible,
            useLazyLoading,
            useFadeIn,
            ...otherImgProps
        } = this.props;

        const imagePath = isLocalImage ? this.getPublicPrefix() : src;

        const imageClassNames = classNames(imgClassName, {
            'img-loaded': useFadeIn && this.state.loaded,
            'img-loading': useFadeIn && !this.state.loaded
        });

        const onLoad = useFadeIn ? this.onLoad : () => null;

        const image = <img src={imagePath} alt={alt} className={imageClassNames} onLoad={onLoad} {...otherImgProps} />;

        if (useLazyLoading) {
            return (
                <LazyLoad
                    width={width}
                    onContentVisible={onContentVisible}
                    className={className}
                    height={height}
                    throttle={throttle}
                    elementType={elementType}
                    offset={offset}
                    debounce={debounce}
                >
                    {image}
                </LazyLoad>
            );
        }

        return image;
    }

    private getPublicPrefix = () => {
        return CFG.PUBLIC_PATH + this.props.src.startsWith('/') ? this.props.src : `/${this.props.src}`;
    };
}
