import React from 'react';

import './loadingSpinner.scss';
import LoadingSpinner from '../LoadingSpinner';

interface IProps {
    text?: string;
}

const LoadingIndicator = ({ text }: IProps) => {
    const displayText = text || 'wird geladen ...';

    return (
        <div className="loadingSpinnerContainer">
            <LoadingSpinner />
            {displayText}
        </div>
    );
};

export default LoadingIndicator;
