import { both, complement, either, equals, is, isEmpty, isNil, pathSatisfies } from 'ramda';

export const isNotEmpty = complement(isEmpty);
export const isNonEmptyOfType = (t: any) => both(is(t), isNotEmpty);
export const isNonEmptyArray = isNonEmptyOfType(Array);
export const isNonEmptyString = isNonEmptyOfType(String);
export const isDefined = complement(isNil);
export const isEmptyString = both(is(String), isEmpty);
export const differs = complement(equals);
export const pathExists = pathSatisfies(isDefined);
export const isNilOrEmpty = either(isNil, isEmpty);

export const matchInArray = (string: string, expressions: string[]) => {
    for (const value of expressions) {
        if (string.match(value)) {
            return true;
        }
    }

    return false;
};
