import React from 'react';
import Helmet from 'react-helmet';

const DEFAULT_TITLE = 'HolidayCheck • Hotels & Reisen mit Hotelbewertungen günstig buchen';

interface ISeoConfiguration {
    title: string;
    description: string;
    meta: string;
    robotsMeta: string[];
    breadcrumbName: string;
    customContentOne: string;
    customContentTwo: string;
    customContentThree: string;
}

interface IPageConfig {
    seoConfiguration: ISeoConfiguration;
    slug: string;
    error?: any;
}

const getRobotsMeta = (robotsMeta: string[]) => {
    let robotsMetaStr = 'noindex, nofollow';

    if (robotsMeta && robotsMeta.length) {
        robotsMetaStr = robotsMeta.join(', ');
    }

    return robotsMetaStr;
};

class PageConfig extends React.Component<IPageConfig> {
    public shouldComponentUpdate(nextProps: Readonly<IPageConfig>): boolean {
        return this.props.slug !== nextProps.slug;
    }

    public render(): React.ReactNode {
        const {
            seoConfiguration: { title, description, robotsMeta, meta: jsonmeta }
        } = this.props;
        let { slug } = this.props;

        const isInfoPage: boolean = slug.startsWith('/info-');
        slug = slug.replace('/info-', '/');
        if(slug === '/') {
            slug = '';
        }

        const url = `https://www.holidaycheck.${CFG.TLD}${isInfoPage ? '' : (CFG.TLD === 'ch' ? '/ferien' : '/urlaub')}${slug}`;
        const robotsMetaStr = getRobotsMeta(robotsMeta);

        const meta = [
            { name: 'description', content: description },
            { property: 'og:url', content: url },
            { property: 'og:title', content: title || DEFAULT_TITLE },
            { name: 'robots', content: robotsMetaStr },
            { name: 'x-robots-tag', content: robotsMetaStr }
        ];

        if (description) {
            meta.push({ property: 'og:description', content: description });
        }

        if (this.props.error) {
            meta.unshift(
                { name: 'robots', content: 'noindex, nofollow' },
                { name: 'x-robots-tag', content: 'noindex, nofollow' }
            );
        }

        return (
            <Helmet defer={false}>
                {meta.map(m => (
                    <meta {...m} key={m.content} />
                ))}
                <title>{title || DEFAULT_TITLE}</title>
                <link href={url} rel="canonical" />
                <link href={`https://www.holidaycheck.de${isInfoPage ? '' : '/urlaub'}${slug}`} rel="alternate" hrefLang="de-DE" />
                <link href={`https://www.holidaycheck.at${isInfoPage ? '' : '/urlaub'}${slug}`} rel="alternate" hrefLang="de-AT" />
                <link href={`https://www.holidaycheck.ch${isInfoPage ? '' : '/ferien'}${slug}`} rel="alternate" hrefLang="de-CH" />
                <link href={`https://www.holidaycheck.de${isInfoPage ? '' : '/urlaub'}${slug}`} rel="alternate" hrefLang="x-default" />
                {jsonmeta && <script type="application/ld+json">{jsonmeta}</script>}
            </Helmet>
        );
    }
}

export default PageConfig;
