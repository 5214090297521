import slugify from './slugify';

function startsWithPrefix(keyword: string, prefix: string): boolean {
    return keyword.startsWith(prefix);
}

export function routeSlug(path: string, slugText?: any): string {
    if (!slugText) {
        return path.replace(/\/$/, '');
    }

    const seoPrefix: IHashMap = {
        '/dc/': 'wetter',
        '/dh/': 'hotels',
        '/dm/': 'bilder-videos',
        '/di/': 'hotelspecials',
        '/dp/': 'ausflugsziele',
        '/hc/': 'beschreibung',
        '/hr/': 'bewertungen',
        '/hm/': 'bilder-videos',
        '/ho/': 'angebote'
    };

    const slug = slugify(slugText);

    if (seoPrefix[path] && !startsWithPrefix(slug, seoPrefix[path])) {
        return `${path}${seoPrefix[path]}-${slug}`;
    }

    return `${path}${slug}`;
}
