// export default `
import gql from 'graphql-tag';

export default gql`
    type Suggestion {
        id: String!
        name: String!
        title: String!
        description: String!
        type: String!
    }

    type Hotel {
        departureDate: String
        returnDate: String
        adults: Int
        children: [Int]
        numberOfRooms: Int
        suggestion: Suggestion
    }

    type Package {
        departureDate: String
        returnDate: String
        adults: Int
        children: [Int]
        numberOfRooms: Int
        duration: String
        boardType: String
        transfer: String
        airport: [String]
        maxP: String
        suggestion: Suggestion
    }

    type FormData {
        travelkind: String!
        package: Package
        hotel: Hotel
    }
`;
