import * as React from 'react';
import { partition, pathOr } from 'ramda';

import Popover from 'src/common/Popover';
import SectionHeadline from 'src/common/SectionHeadline';

import BackgroundImageRenderer from './backgroundImageRenderer';
import Voucher from './Voucher';

import './heroImage.scss';
import { inject } from 'mobx-react';

export interface IIMediaType {
    title: string;
    url: string;
}

export const HeroImage = ({ contentfulStore }) => {
    const contentfulTopic: IContentfulTopic = contentfulStore.topic;

    const {
        heroImageMedia,
        headlines: { subHeadline, voucherDescription, heroImage },
        generalText
    } = contentfulTopic;

    const subHeadlineInfo = pathOr(null, ['subHeadlineInfo'], generalText);
    const [badgeImages, backgroundImages] = partition((m: IIMediaType) => m.title.includes('Badge'), heroImageMedia);
    const headlineInfoRaw = <p dangerouslySetInnerHTML={{ __html: subHeadlineInfo }} />;

    return (
        <div className="heroImage wrap">
            <Voucher badgeImages={badgeImages} customClass="badge" description={voucherDescription} />
            <BackgroundImageRenderer images={backgroundImages} customClass="hero-image" />
            <div className="text-wrapper">
                <div className="title">{heroImage && <SectionHeadline headline={heroImage} />}</div>
                <div>
                    <h2 className="sub-title">{subHeadline}</h2>
                    {subHeadlineInfo && <Popover body={headlineInfoRaw} />}
                </div>
            </div>
        </div>
    );
};

export default inject('contentfulStore')(HeroImage);
